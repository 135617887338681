import {IoMdRefresh} from 'react-icons/io'
import DashboardLayout from '../../../components/layouts/DashboardLayout'
import React from 'react'

const handleRefresh = () => {
    console.log('Refresh Clicked!')
}

const Rejecteds = () => {
    return (
        <DashboardLayout>
            <div className="card mb-3">
                <div className="card-header">
                    <div className="row flex-between-end">
                        <div className="col-auto align-self-center">
                            <h5 className="mb-0" data-anchor="data-anchor">Reddedilenler Listesi</h5>
                        </div>
                        <div className="col-auto ms-auto">
                            <button className="btn btn-falcon-default btn-sm" type="button" role="button" onClick={handleRefresh}>
                                <IoMdRefresh size={20} color='blue' />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default Rejecteds