import http from '../../utils/http'
import { createAsyncThunk } from '@reduxjs/toolkit'
import secureLocalStorage from 'react-secure-storage'
import { clearLocalStorage } from '../../utils/misc'

export const getParentSections = createAsyncThunk(
    'editor/section/parents',
    async ({token}, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.get('/api/editor/section/parents')
            return data.response
        } catch (error) {
            return rejectWithValue(error.response.data.errors)
        }
})

export const getSections = createAsyncThunk(
    'editor/section/all',
    async ({ token, parents }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.post('/api/editor/section/all', { parents })
            return data.response
        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
            // return rejectWithValue(error.response.data.errors)
        }
})

export const getSubSections = createAsyncThunk(
    'editor/section/children',
    async ({ token, parents }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.post(`/api/editor/section/children`, { parents })
            return data.response
        } catch (error) {
            return rejectWithValue(error.response.data.errors)
        }
    })

export const getCategories = createAsyncThunk(
    'editor/categories',
    async ({ token, parents }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.post(`/api/editor/section/categories`, { parents })
            return data.response
        } catch (error) {
            return rejectWithValue(error.response.data.errors)
        }
    })

export const getLessons = createAsyncThunk(
    'editor/lessons',
    async ({ token, sections }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.post(`/api/editor/section/lessons`, { sections })
            return data.response
        } catch (error) {
            return rejectWithValue(error.response.data.errors)
        }
    })

export const saveSection = createAsyncThunk(
    'editor/sections/save',
    async ({ token, section }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.post('/api/editor/sections', section)
            return data.response
        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
})

export const updateSection = createAsyncThunk(
    'editor/sections/update',
    async ({ token, section, id }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.put('/api/editor/sections/' + id, section)
            return data.response
        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
    })

export const deleteSection = createAsyncThunk(
    'editor/sections/delete',
    async ({ token, id }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.delete('/api/editor/sections/' + id)
            return data.response
        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
    })