import {createSlice} from '@reduxjs/toolkit'
import {getUnits, saveUnit, deleteUnit, updateUnit} from './unitActions'


const initialState = {
    loading: false,
    units: [],
    unit: null,
    error: null,
    errorCode: null,
    success: false,
}

export const unitSlice = createSlice({
    name: 'unit',
    initialState,
    reducers: {
        resetState(state) {
            state.loading = false
            state.error = null
            state.errorCode = null
            state.success = false
        }
    },
    extraReducers: {
        [getUnits.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getUnits.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.units = payload
        },
        [getUnits.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [saveUnit.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [saveUnit.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            state.units.unshift(payload)
            state.unit = payload
        },
        [saveUnit.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload.error
            state.errorCode = payload.code
        },
        [updateUnit.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [updateUnit.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            const index = state.units.findIndex(item => item.id === payload.id)
            state.units[index] = payload
            state.unit = payload
        },
        [updateUnit.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload.error
            state.errorCode = payload.code
        },
        [deleteUnit.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [deleteUnit.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            state.units = state.units.filter(unit => unit.id !== payload.id)
        },
        [deleteUnit.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload.error
            state.errorCode = payload.code
        },
    }
})

export const { resetState } = unitSlice.actions

export default unitSlice.reducer