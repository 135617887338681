import React from 'react'
import {useLocation, Navigate, Outlet} from 'react-router-dom'
import {useSelector} from 'react-redux'

const RequireAuth = ({ allowedRoles }) => {
    const location = useLocation()
    const { user } = useSelector((state) => state.auth)

    return (
        (user?.roles[0] == allowedRoles)
            ? <Outlet />
            : user
                ? <Navigate to="/yetki-yok" state={{ from: location }} replace />
                : <Navigate to="/" state={{ from: location }} replace />
    )
}

export default RequireAuth