import React, {useEffect, useRef, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import {Form, Button, InputGroup, Spinner} from 'react-bootstrap'
import {useForm, Controller} from 'react-hook-form'
import {FaEye, FaEyeSlash} from 'react-icons/fa'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import InputMask from 'react-input-mask'
import {getParentSections, getSections, getSubSections} from '../../slices/sections/sectionActions'
import {saveWriter} from '../../slices/writer/writerActions'
import {useNavigate} from 'react-router-dom'
import {getLessons} from '../../slices/lessons/lessonActions'
import {getPools} from '../../slices/pools/poolActions'

const CreateWriterModal = ({ show, handleClose }) => {
    const [passwordVisibility, setPasswordVisibility] = useState(false)
    const {token, user} = useSelector((state) => state.auth)
    const {lessons} = useSelector((state) => state.lesson)
    const {pools} = useSelector((state) => state.pool)
    const {loading: sectionLoading , error: sectionError, parents, sections, subSections} = useSelector((state) => state.section)
    const {loading: writerSaveLoading, error: writerSaveError, writer, success: writerSaveSuccess, errorCode} = useSelector((state) => state.writer)
    const sectionRef = useRef(null)
    const subSectionRef = useRef(null)
    const lessonsRef = useRef(null)


    const writerSchema = yup.object({
        name: yup
            .string()
            .matches(/^[a-zA-ZışçğüöİŞÇĞÜÖ\s\.]{2,}$/, 'Geçerli bir isim giriniz!')
            .required('İsim gerekli!'),
        lastName: yup
            .string()
            .matches(/^[a-zA-ZışçğüöİŞÇĞÜÖ\s\.]{2,}$/, 'Geçerli bir soyadı giriniz!')
            .required('Soyadı gerekli!'),
        phone: yup
            .string()
            // .matches(/^([0|\+[0-9]{1,5})?([0-9]{10})$/, 'Geçerli bir telefon giriniz!')
            .required('Telefon numarası gerekli!'),
        email: yup
            .string()
            .email('Geçerli bir e-posta adresi giriniz!')
            .required('E-posta adresi gerekli!'),
        password: yup
            .string()
            .min(8, ({ min }) => `Şifre en az ${min} karakter olmalıdır!`)
            .required('Şifre gerekli!'),
        questionPools: yup
            .array()
            .min(1, 'En az 1 seçenek seçmelisiniz!'),
        parentSections: yup
            .array()
            .min(1, 'En az 1 seçenek seçmelisiniz!'),
        sections: yup
            .array()
            .min(1, 'En az 1 seçenek seçmelisiniz!'),
        lessons: yup
            .array()
            .min(1, 'En az 1 seçenek seçmelisiniz!'),
    })

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { clearErrors, reset, register, handleSubmit, control, setValue, getValues, formState: { isValid, isSubmitted, errors }} = useForm({
        resolver: yupResolver(writerSchema),
        defaultValues: {
            parentSections: [],
            sections: [],
            subSections: [],
            lessons: [],
            questionPools: [],
        }
    })

    useEffect(() => {
        const clearForm = () => {
            clearErrors()
            reset()
        }
        const getData = async () => {
            await dispatch(getParentSections({token}))
            await dispatch(getLessons({token}))
            await dispatch(getPools({token}))
        }
        if(!show) {
            clearForm()
        } else {
            getData().catch(console.error)
        }
    }, [show])

    useEffect(() => {
        if(writerSaveError) {
            if(Array.isArray(writerSaveError.error) || typeof writerSaveError.error === 'object') {
                Object.keys(writerSaveError.error).map((err, index) => (
                    toast(writerSaveError.error[err][0], { type: 'error' })
                ))
            } else {
                toast(writerSaveError.error, { type: 'error' })
            }

        }
    }, [writerSaveError])

    useEffect(() => {
        if(writerSaveSuccess) {
            handleClose()
        }
    }, [writerSaveSuccess])

    useEffect(() => {
        if(errorCode === 401) {
            navigate('/')
        }
    }, [errorCode])

    const submitForm = async (data) => {
        data = {...data, 'editor': user?.id}
        console.log(data)
        await dispatch(saveWriter({
            token,
            writer: data,
        }))
    }

    const handlePoolChange = (selected) => {
        setValue('questionPools', Array.isArray(selected) ? selected.map(x => x.value) : [])
    }

    const poolsList = pools?.map(pool => (
        { value: pool.id, label: pool.title  }
    ))


    const handleParentSectionChange = (selected) => {
        setValue('parentSections', Array.isArray(selected) ? selected.map(x => x.value) : [])
        dispatch(getSections({ token, parents: getValues().parentSections }))
        if(selected.length === 0) {
            setValue('sections', [])
            setValue('subSections', [])
            sectionRef.current.clearValue()
            subSectionRef.current.clearValue()
        }
    }

    const parentSectionList = parents?.map(parent => (
        {value: parent.id, label: parent.title}
    ))

    let sectionList = sections?.map(section => (
        { value: section.id, label: section.fullSectionTitle }
    ))

    const handleSectionChange = (selected) => {
        setValue('sections', Array.isArray(selected) ? selected.map(x => x.value) : [])
        dispatch(getSubSections({ token, parents: getValues().sections }))
    }

    let subSectionList = subSections?.map(sub => (
        { value: sub.id, label: sub.fullSectionTitle }
    ))

    const handleSubSectionChange = (selected) => {
        setValue('subSections', Array.isArray(selected) ? selected.map(x => x.value) : [])
        // dispatch(getCategories({ token, parents: getValues().subSections }))
    }

    let lessonList = lessons?.map(lesson => (
        { value: lesson.id, label: lesson.title  }
    ))

    const handleLessonChange = (selected) => {
        setValue('lessons', Array.isArray(selected) ? selected.map(x => x.value) : [])
    }

    return (
        <>
            <div className={ 'modal fade' + (show ? 'show' : '')} id="error-modal" tabIndex="-1" role="dialog" aria-hidden="true" style={{ display: show ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content position-relative">
                        <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                            <button
                                onClick={handleClose}
                                className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-0">
                            <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                                <h4 className="mb-1" id="modalExampleDemoLabel">Yazar Ekle </h4>
                            </div>
                            <div className="p-4 pb-0">
                                <Form noValidate onSubmit={handleSubmit(submitForm)} className="row g-3">
                                    <div className="col-md-6 mb-1">
                                        <label className="col-form-label" htmlFor="writer-name">Ad:</label>
                                        <Form.Control
                                            className="form-control"
                                            id="writer-name"
                                            type="text"
                                            isValid={isSubmitted && !errors.name}
                                            isInvalid={isSubmitted && !!errors.name}
                                            {...register('name')}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.name ? errors.name.message : ''}
                                        </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-6 mb-1">
                                        <label className="col-form-label" htmlFor="write-surname">Soyad:</label>
                                        <Form.Control
                                            className="form-control"
                                            id="writer-surname"
                                            type="text"
                                            isValid={isSubmitted && !errors.lastName}
                                            isInvalid={isSubmitted && !!errors.lastName}
                                            {...register('lastName')}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.lastName ? errors.lastName.message : ''}
                                        </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-6 mb-1">
                                        <label className="col-form-label" htmlFor="writer-email">E-Posta:</label>
                                        <Form.Control
                                            autoComplete="new-email"
                                            className="form-control"
                                            id="writer-email"
                                            type="email"
                                            isValid={isSubmitted && !errors.email}
                                            isInvalid={isSubmitted && !!errors.email}
                                            {...register('email')}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.email ? errors.email.message : ''}
                                        </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-6 mb-1">
                                        <label className="col-form-label" htmlFor="write-password">Parola:</label>
                                        <InputGroup>
                                            <Form.Control
                                                autoComplete="new-password"
                                                className="form-control"
                                                id="writer-password"
                                                type={passwordVisibility ? 'text' : 'password'}
                                                isValid={isSubmitted && !errors.password}
                                                isInvalid={isSubmitted && !!errors.password}
                                                {...register('password')}
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                {errors.password ? errors.password.message : ''}
                                            </Form.Control.Feedback>
                                            {
                                                !errors.password && (
                                                    <Button
                                                        onClick={() => setPasswordVisibility(!passwordVisibility)}
                                                        variant='outline-secondary'>
                                                        {
                                                            passwordVisibility && (
                                                                <FaEyeSlash size={18} color='black' />
                                                            )
                                                        }
                                                        {
                                                            !passwordVisibility && (
                                                                <FaEye size={18} color='black' />
                                                            )
                                                        }
                                                    </Button>
                                                )
                                            }
                                        </InputGroup>
                                    </div>
                                    <div className="col-md-6 mb-1">
                                        <label className="col-form-label" htmlFor="writer-phone">Telefon:</label>
                                        <InputMask
                                            className={'form-control' + (errors.phone ? ' is-invalid' : isSubmitted ? ' is-valid' : '')}
                                            mask="+99 (999) 999 99 99"
                                            {...register('phone')}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.phone ? errors.phone.message : ''}
                                        </Form.Control.Feedback>

                                    </div>
                                    <div className="col-md-6 mb-1">
                                        <label className="col-form-label" htmlFor="writer-zoom-secret">Zoom Gizli Anahtar:</label>
                                        <Form.Control
                                            className="form-control"
                                            id="writer-zoom-secret"
                                            type="text"
                                            {...register('zoom-secret-key')}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-1">
                                        <label className="col-form-label" htmlFor="writer-zoom-api">Zoom Api Anahtar:</label>
                                        <Form.Control
                                            className="form-control"
                                            id="writer-zoom-api"
                                            type="text"
                                            {...register('zoom-api-key')}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-1">
                                        <label className="col-form-label" htmlFor="writer-zoom-host">Zoom Host:</label>
                                        <input
                                            className="form-control"
                                            id="writer-zoom-host"
                                            type="text"
                                            {...register('zoom-host')}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-1">
                                        <label className="col-form-label" htmlFor="writer-vimeo-client-id">Vimeo Client ID:</label>
                                        <input
                                            className="form-control"
                                            id="writer-vimeo-client-id"
                                            type="text"
                                            {...register('vimeo-client-id')}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-1">
                                        <label className="col-form-label" htmlFor="writer-vimeo-client-secret">Vimeo Client Secret:</label>
                                        <input
                                            className="form-control"
                                            id="writer-vimeo-client-secret"
                                            type="text"
                                            {...register('vimeo-client-secret')}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-1">
                                        <label className="col-form-label" htmlFor="writer-vimeo-access-token">Vimeo Access Token:</label>
                                        <input
                                            className="form-control"
                                            id="writer-vimeo-access-token"
                                            type="text"
                                            {...register('vimeo-access-token')}
                                        />
                                    </div>
                                    <div className="col-md-12 mb-1">
                                        <label className="col-form-label" htmlFor="writer-lessons">Soru Seçebileceği Dersler:</label>
                                        <Controller
                                            name='lessons'
                                            control={control}
                                            render={({ onChange, value, ref }) => (
                                                <>
                                                    <Select
                                                        ref={lessonsRef}
                                                        id='writer-lessons'
                                                        className={'form-control' + (errors.lessons ? ' is-invalid' : isSubmitted ? ' is-valid' : '')}
                                                        isMulti
                                                        isSearchable
                                                        placeholder='Ders Listesi...'
                                                        options={lessonList}
                                                        onChange={handleLessonChange}
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.lessons ? errors.lessons.message : ''}
                                                    </Form.Control.Feedback>
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="col-md-12 mb-1">
                                        <label className="col-form-label" htmlFor="writer-question-pool-style">Seçebileceği Soru Havuzları:</label>
                                        <Controller
                                            name='questionPools'
                                            control={control}
                                            render={({ onChange, value, ref }) => (
                                                <>
                                                    <Select
                                                        id='writer-question-pool-style'
                                                        className={'form-control' + (errors.questionPools ? ' is-invalid' : isSubmitted ? ' is-valid' : '')}
                                                        isMulti
                                                        isSearchable
                                                        placeholder='Soru Havuzu Listesi....'
                                                        options={poolsList}
                                                        onChange={handlePoolChange}
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.questionPools ? errors.questionPools.message : ''}
                                                    </Form.Control.Feedback>
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="col-md-12 mb-1">
                                        <label className="col-form-label" htmlFor="writer-parent-sections">Seçebileceği Ana Bölümler:</label>
                                        <Controller
                                            name='parentSections'
                                            control={control}
                                            render={({ onChange, value, ref }) => (
                                                <>
                                                    <Select
                                                        id='writer-parent-sections'
                                                        className={'form-control' + (errors.parentSections ? ' is-invalid' : isSubmitted ? ' is-valid' : '')}
                                                        isMulti
                                                        isSearchable
                                                        noOptionsMessage={() => 'Ana Bölüm Bulunamadı!'}
                                                        placeholder='Ana Bölüm Listesi...'
                                                        options={parentSectionList}
                                                        onChange={handleParentSectionChange}
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.parentSections ? errors.parentSections.message : ''}
                                                    </Form.Control.Feedback>
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="col-md-12 mb-1">
                                        <label className="col-form-label" htmlFor="writer-sections">Seçebileceği Bölümler:</label>
                                        <Controller
                                            name='sections'
                                            control={control}
                                            render={({ onChange, value, ref }) => (
                                                <>
                                                    <Select
                                                        ref={sectionRef}
                                                        id='writer-sections'
                                                        className={'form-control' + (errors.sections ? ' is-invalid' : isSubmitted ? ' is-valid' : '')}
                                                        isMulti
                                                        isSearchable
                                                        noOptionsMessage={() => 'Ana Bölüm Seçin...'}
                                                        placeholder='Bölüm Listesi...'
                                                        options={sectionList}
                                                        onChange={handleSectionChange}
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.sections ? errors.sections.message : ''}
                                                    </Form.Control.Feedback>
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="col-md-12 mb-1">
                                        <label className="col-form-label" htmlFor="writer-sub-sections">Seçebileceği Alt Bölümler:</label>
                                        <Controller
                                            name='subSections'
                                            control={control}
                                            render={({ onChange, value, ref }) => (
                                                <>
                                                    <Select
                                                        ref={subSectionRef}
                                                        id='writer-sections'
                                                        className={'form-control' + (errors.subSections ? ' is-invalid' : isSubmitted ? ' is-valid' : '')}
                                                        isMulti
                                                        isSearchable
                                                        noOptionsMessage={() => 'Bölüm Seçin...'}
                                                        placeholder='Alt Bölüm Listesi...'
                                                        options={subSectionList}
                                                        onChange={handleSubSectionChange}
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.subSections ? errors.subSections.message : ''}
                                                    </Form.Control.Feedback>
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="col-md-12 mb-2">
                                        <Button
                                            className="btn btn-success"
                                            type="submit"
                                            name='submit'
                                            disabled={writerSaveLoading}>
                                            {writerSaveLoading ?
                                                <>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    /> Bekleyin...
                                                </>
                                                : 'Kaydet'
                                            }
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button
                                disabled={sectionLoading}
                                onClick={handleClose}
                                className="btn btn-secondary" type="button" data-bs-dismiss="modal">Kapat</Button>
                        </div>
                    </div>
                </div>
            </div>
            {
                show && (
                    <div className="modal-backdrop fade show"></div>
                )
            }
            <ToastContainer/>
        </>
    )
}

export default CreateWriterModal