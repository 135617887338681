import {createSlice} from '@reduxjs/toolkit'
import {uploadSolutionVideo} from './uploaderActions'

const initialState = {
    loading: false,
    error: null,
    video: null,
    errorCode: null,
    success: false
}

export const uploaderSlice = createSlice({
    name: 'uploader',
    initialState,
    reducers: {
        resetUploader(state) {
            state.loading = false
            state.error = null
            state.errorCode = null
            state.success = false
            state.video = null
        }
    },
    extraReducers: {
        [uploadSolutionVideo.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [uploadSolutionVideo.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.video = payload
        },
        [uploadSolutionVideo.rejected]: (state, {payload}) => {
            state.loading = false
            state.success = false
            state.error = payload
        }
    }
})

export const {resetUploader} = uploaderSlice.actions

export default uploaderSlice.reducer