import React from 'react'

const ShowCropped = ({ imgUrl, setAnswer, selected }) => {

    const selectAnswer = (answer) => {
        setAnswer(answer)
    }

    return (
        <>
            <div className="pt-3 mt-2 d-flex flex-column justify-content-center align-items-center align-content-center" id="imgDiv">
                <img className='mb-2 w-75' src={imgUrl} />
            </div>
            <div className='cropper-button-group d-flex justify-content-center align-content-center mb-4'>
                <button onClick={() => selectAnswer('A')} className={`btn btn-sm btn-primary cropper-select-answer me-1 ${selected === 'A' ? 'selected' : ''}`}>A</button>
                <button onClick={() => selectAnswer('B')} className={`btn btn-sm btn-primary cropper-select-answer me-1 ${selected === 'B' ? 'selected' : ''}`}>B</button>
                <button onClick={() => selectAnswer('C')} className={`btn btn-sm btn-primary cropper-select-answer me-1 ${selected === 'C' ? 'selected' : ''}`}>C</button>
                <button onClick={() => selectAnswer('D')} className={`btn btn-sm btn-primary cropper-select-answer me-1 ${selected === 'D' ? 'selected' : ''}`}>D</button>
                <button onClick={() => selectAnswer('E')} className={`btn btn-sm btn-primary cropper-select-answer me-1 ${selected === 'E' ? 'selected' : ''}`}>E</button>
            </div>
        </>
    )
}

export default ShowCropped