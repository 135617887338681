import http from '../../utils/http'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {clearLocalStorage} from '../../utils/misc'

export const getByStatus = createAsyncThunk(
'question/byStatus',
async ({ token, status, pageNumber }, { rejectWithValue}) => {
    try {
        await http.get('/sanctum/csrf-cookie')
        if(token) {
            http.defaults.headers.common = {
                'Authorization': `Bearer ${token}`
            }
        }
        /*if(!writer) {
            const { data } = await http.post('/api/editor/question/get-by-status', {
                status
            })
            return data.response
        } else {*/
            const { data } = await http.post('/api/questions/get-by-status', {
                pageNumber,
                status
            })
            return {
                status,
                response: data.response
            }
        // }

    } catch (error) {
        if(error?.response?.status === 401) {
            clearLocalStorage()
            return rejectWithValue('Tekrar giriş yapınız!')
        }
        return rejectWithValue({error: error.response.data.errors, code: error?.response?.status })
    }
})

export const deleteQuestion = createAsyncThunk(
'question/delete',
async ({ token, id }, { rejectWithValue}) => {
    try {
        await http.get('/sanctum/csrf-cookie')
        if(token) {
            http.defaults.headers.common = {
                'Authorization': `Bearer ${token}`
            }
        }
        const { data } = await http.delete('/api/editor/questions/' + id)
        return data.response
    } catch (error) {
        if(error.response.status === 401) {
            clearLocalStorage()
            return rejectWithValue('Tekrar giriş yapınız!')
        }
        return rejectWithValue({error: error.response.data.errors, code: error.response.status })
    }
})

export const updateAnswer = createAsyncThunk(
'question/update-answer',
async ({ token, id, answer }, { rejectWithValue}) => {
    try {
        await http.get('/sanctum/csrf-cookie')
        if(token) {
            http.defaults.headers.common = {
                'Authorization': `Bearer ${token}`
            }
        }
        const { data } = await http.post('/api/editor/questions/update-answer', { id, answer })
        return data.response
    } catch (error) {
        if(error.response.status === 401) {
            clearLocalStorage()
            return rejectWithValue('Tekrar giriş yapınız!')
        }
        return rejectWithValue({error: error.response.data.errors, code: error.response.status })
    }
})

export const saveQuestion = createAsyncThunk(
    'question/save',
    async ({ token, question, id }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.post('/api/editor/questions', question)
            return {
                data: data.response,
                id
            }
        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
    })

export const updateQuestion = createAsyncThunk(
    'question/update',
    async ({ token, question, id }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.put(`/api/editor/questions/${id}`, question)
            return data.response

        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
    })
export const updateStatus = createAsyncThunk(
    'question/updateStatus',
    async ({ token, question, id }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.post(`/api/editor/question/update-status`, question)
            return data.response

        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
    })
export const saveWriter = createAsyncThunk(
    'writer/save',
    async ({ token, writer }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.post('/api/editor/writers', writer)
            return data.response
        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
})

export const updateWriter = createAsyncThunk(
    'writer/update',
    async ({ token, writer, id }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.put('/api/editor/writers/' + id, writer)
            return data.response
        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
})




