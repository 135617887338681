import React, {useEffect, useMemo, useState} from 'react'
import DashboardLayout from '../../../components/layouts/DashboardLayout'
import {useSelector, useDispatch} from 'react-redux'
import {deleteQuestion, getByStatus, updateAnswer} from '../../../slices/questions/questionActions'
import {getWriters} from '../../../slices/editor/editorActions'
import {IoMdRefresh } from 'react-icons/io'
import {Spinner, OverlayTrigger, Tooltip, Form, InputGroup, Button} from 'react-bootstrap'
import ConfirmationModal from '../../../components/common/ConfirmationModal'
import {toast, ToastContainer} from 'react-toastify'
import {resetState, resetEarnings} from '../../../slices/questions/questionSlice'
import EditQuestionModal from '../../../components/editor/EditQuestionModal'
import {pendingQuestionsCount} from '../../../slices/editor/editorActions'
import Pagination from 'react-js-pagination'


const FilterComponent = ({ searchText, onSearch, onClear, writerList, onFilter }) => (
    <>
        <div className='d-flex align-content-center align-items-center'>
            <div className='w-50 p-3'>
                <Form.Select
                    className='form-control'
                    onChange={onFilter}>
                    <option value="0">Yazarlar...</option>
                    {writerList?.map(writer => (
                        <option key={writer.id} value={writer.id}>{writer.name + ' ' + writer.lastName}</option>
                    ))
                    }
                </Form.Select>
            </div>
            <div className='w-50 p-3'>
                <InputGroup className="">
                    <Form.Control
                        placeholder="Ara..."
                        aria-label="Ara..."
                        aria-describedby="basic-addon2"
                        value={searchText}
                        onChange={onSearch}/>
                    <Button variant="outline-secondary" id="button-addon2" onClick={onClear}>
                        X
                    </Button>
                </InputGroup>
            </div>
        </div>
    </>
)

const Pendings = () => {
    const {writers} = useSelector((state) => state.writer)
    const {loading, pendings, error, success} = useSelector((state) => state.question)
    const {pendings: questionCount} = useSelector((state) => state.editor)
    const {token, user} = useSelector((state) => state.auth)
    const dispatch = useDispatch()

    const [showConfirm, setShowConfirm] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [activeQuestion, setActiveQuestion] = useState(null)
    const [activeIndex, setActiveIndex] = useState(null)

    const [filterText, setFilterText] = useState(0)
    const [searchText, setSearchText] = useState('')
    const [filteredData, setFilteredData] = useState([])
    const [orgData, setOrgData] = useState([])

    const [activePage, setActivePage] = useState(1)

    useEffect(() => {
        // dispatch(getByStatus({ token, status: 'waiting_approval' }))
        fetchData()
        dispatch(getWriters({corporate: user.corporateId, token }))
        dispatch(pendingQuestionsCount({token}))
    }, [])

    useEffect(() => {
        if(success) {
            toast('İşlem başarılı!', { type: 'success' })
            handleRefresh()
        }
    }, [success])

    useEffect(() => {
        console.log(error)
        if(error) {
            if(Array.isArray(error.error) || typeof error.error === 'object') {
                Object.keys(error.error).map((err, index) => (
                    // console.log(error.error[err][0])
                    toast(error.error[err][0], { type: 'error' })
                ))
            } else {
                console.log('Error: ', error.error)
                toast(error.error, { type: 'error' })
            }
        }
    }, [error])

    useEffect(() => {
        if(searchText) {
            const filteredPendings = pendings.filter(
                item => (item.writer.name && item.writer.name.toLowerCase().includes(searchText.toLowerCase())) || item.writer.lastName && item.writer.lastName.toLowerCase().includes(searchText.toLowerCase()),
            )
            setFilteredData(filteredPendings)
        } else {
            setFilteredData(orgData)
        }
    }, [searchText])

    useEffect(() => {
        if(filterText > 0) {
            const filteredPendings = pendings.filter(pending => pending.writerId == filterText)
            setFilteredData(filteredPendings)
        } else {
            setFilterText(0)
            setFilteredData(orgData)
        }

    }, [filterText])

    useEffect(() => {
        if(pendings) {
            setFilteredData(pendings)
            setOrgData(pendings)
        }
    }, [pendings])

    const fetchData = async (pageNumber = 1) => {
        try {
            await dispatch(getByStatus({ token, status: 'waiting_approval', pageNumber }))
        } catch (error) {
            console.log('Error:', error)
        } finally {

        }
    }

    const handlePageChange =  async (pageNumber) => {
        await setActivePage(pageNumber)
        await fetchData(pageNumber)
    }

    const handleConfirmModal = (data) => {
        setActiveQuestion(data)
        setShowConfirm(true)
    }

    const handleRefresh = async () => {
        console.log('Active Page:', activePage)
        // dispatch(getByStatus({ token, status: 'waiting_approval', activePage }))
        await fetchData(activePage)
    }

    const handleDelete = async () => {
        await dispatch(deleteQuestion({ token, id: activeQuestion.id }))
    }

    const handleCloseModal = () => {
        setShowConfirm(false)
        setShowEdit(false)
        setActiveQuestion(null)
        dispatch(resetState())
        dispatch(resetEarnings())
    }

    const handleSaveQuestion = () => {
        console.log('handleSaveQuestion')
        dispatch(resetState())
        dispatch(resetEarnings())
        setShowEdit(false)
        setActiveQuestion(null)
        setTimeout(() => {
            setActiveQuestion(pendings[activeIndex])
            setShowEdit(true)
        }, 2000)

    }

    const handleEditModal = (question) => {
        console.log(question)
        const index = pendings.indexOf(question)
        console.log('index', index)
        setActiveIndex(index)
        setShowEdit(true)
        setActiveQuestion(question)
    }

    const handleAnswer = (answer, question) => {
        console.log(answer)
        console.log(question)
        dispatch(updateAnswer({ token, id: question.id, answer }))
    }

    const handleClear = () => {
        if(searchText) {
            setSearchText('')
            setFilteredData(orgData)
        }
    }

    return (
        <DashboardLayout>
            <div className="card mb-3">
                <div className="card-header">
                    <div className="row">
                        <div className="col-auto align-self-center">
                            <h5 className="mb-0" data-anchor="data-anchor">Bekleyenler Listesi</h5>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-10">
                            <FilterComponent
                                onFilter={e => {
                                    console.log('e', e.target.value)
                                    setFilterText(e.target.value)
                                    // setFilteredData(orgData)
                                }}
                                onSearch={e => setSearchText(e.target.value)} onClear={handleClear} searchText={searchText} writerList={writers} />
                        </div>
                        <div className="col-2 d-flex justify-content-end align-content-center align-items-center">
                            <button className="btn btn-falcon-default btn-sm" type="button" role="button" onClick={handleRefresh}>
                                <IoMdRefresh size={25} color='blue' />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-0">
                    {
                        loading && (
                            <div className="d-flex flex-center">
                                <Spinner animation="grow" variant="primary" />
                            </div>
                        )
                    }
                    {
                        !loading && (
                            <React.Fragment>
                                <div className="kanban-items-container border bg-white dark__bg-1000 rounded-2 py-3 mb-3 d-flex flex-start flex-wrap" style={{ maxHeight: 'none', height: '80vh' }}>
                                    {
                                        filteredData.length > 0
                                            ? filteredData.map(pending => (
                                                <div key={pending?.id} className="card mb-3 kanban-item shadow-sm dark__bg-1100 me-2" style={{ width: '300px', height: '235px' }}>
                                                    <div className="card-body">
                                                        <div className="hoverbox rounded-3 text-center">
                                                            <img className="img-fluid" style={{ objectFit: 'contain' }} src={ pending?.question ? (process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PRODUCTION_STORAGE_URL + pending?.question : process.env.REACT_APP_API_DEVELOPMENT_URL + '/' + pending?.question) : '.img/placeholders/question-placeholder.jpeg' } alt=""/>
                                                            <div className="light hoverbox-content bg-dark p-2 flex-center">
                                                                <p className="lead text-white"></p>
                                                                <div className='d-flex justify-content-evenly'>
                                                                    <OverlayTrigger
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id={`tooltip-top`}>
                                                                                Soruyu Sil
                                                                            </Tooltip>
                                                                        }>
                                                                        <a  onClick={() => handleConfirmModal(pending)}
                                                                            className="btn btn-sm btn-falcon-default rounded me-1" role='button'>
                                                                            <span className="fas fa-trash text-danger fs-1"></span>
                                                                        </a>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip-top'>
                                                                                Soruyu Düzenle
                                                                            </Tooltip>
                                                                        }>
                                                                        <a onClick={() => handleEditModal(pending)} className="btn btn-sm btn-falcon-default rounded" role="button">
                                                                            <span className="fas fa-edit text-warning fs-1"></span>
                                                                        </a>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="container d-flex justify-content-center align-content-center align-items-center flex-column">
                                                        <div className="btn-toolbar">
                                                            <button onClick={() => handleAnswer('A', pending)} className={'btn me-1 ' + (pending.answer === 'A' ? 'btn-success' : 'btn-danger')}>A</button>
                                                            <button onClick={() => handleAnswer('B', pending)} className={'btn me-1 ' + (pending.answer === 'B' ? 'btn-success' : 'btn-danger')}>B</button>
                                                            <button onClick={() => handleAnswer('C', pending)} className={'btn me-1 ' + (pending.answer === 'C' ? 'btn-success' : 'btn-danger')}>C</button>
                                                            <button onClick={() => handleAnswer('D', pending)} className={'btn me-1 ' + (pending.answer === 'D' ? 'btn-success' : 'btn-danger')}>D</button>
                                                            <button onClick={() => handleAnswer('E', pending)} className={'btn me-1 ' + (pending.answer === 'E' ? 'btn-success' : 'btn-danger')}>E</button>
                                                        </div>
                                                        <div>
                                                            <span className='fw-bold'>Yazar: </span>{pending?.writer.name + ' ' + pending?.writer.lastName}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <div className='d-flex justify-content-center align-items-center w-100'><h4>Bekleyen Soru Bulunamadı!</h4></div>
                                    }
                                </div>
                                <div className='d-flex justify-content-center mt-3'>
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={100}
                                        totalItemsCount={questionCount}
                                        pageRangeDisplayed={5}
                                        onChange={(pageNumber) => handlePageChange(pageNumber)}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        firstPageText="İlk"
                                        lastPageText="Son"
                                    />
                                </div>
                            </React.Fragment>
                        )
                    }
                </div>
            </div>
            <ConfirmationModal show={showConfirm} handleClose={handleCloseModal} handleConfirm={handleDelete} header='Soru Sil' />
            {activeQuestion && showEdit ? <EditQuestionModal show={showEdit} handleClose={handleCloseModal} handleSave={handleSaveQuestion} activeQuestion={activeQuestion} activeIndex={activeIndex} /> : ''}
            <ToastContainer/>
        </DashboardLayout>
    )
}

export default Pendings