import { createSlice } from '@reduxjs/toolkit'
import {
    getWriters,
    saveWriter,
    deleteWriter,
    updateWriter,
    getSubjectsWithEarnings,
    activeWritersCount,
    allWritersCount,
    pendingQuestionsCount,
    approvedQuestionsCount,
    rejectedQuestionsCount,
    changeWriterStatus,
    getQuestionStatsByWriter
} from './editorActions'


const initialState = {
    loading: false,
    writers: [],
    active: 0,
    all: 0,
    pendings: 0,
    approveds: 0,
    rejecteds: 0,
    subject: [],
    writer: null,
    stats: [],
    error: null,
    errorCode: null,
    success: false,
}

export const editorSlice = createSlice({
    name: 'editor',
    initialState,
    reducers: {
        resetState(state) {
            state.loading = false
            state.error = null
            state.errorCode = null
            state.success = false
        }
    },
    extraReducers: {
        [getWriters.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getWriters.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.writers = payload
        },
        [getWriters.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [saveWriter.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [saveWriter.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            state.writers.unshift(payload)
            state.writer = payload
        },
        [saveWriter.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload.error
            state.errorCode = payload.code
        },
        [updateWriter.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [updateWriter.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            const index = state.writers.findIndex(item => item.id === payload.id)
            state.writers[index] = payload
            state.writer = payload
        },
        [updateWriter.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload.error
            state.errorCode = payload.code
        },
        [deleteWriter.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [deleteWriter.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            state.writers = state.writers.filter(writer => writer.id !== payload.id)
        },
        [deleteWriter.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload.error
            state.errorCode = payload.code
        },
        [getSubjectsWithEarnings.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getSubjectsWithEarnings.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.subjects = payload
        },
        [getSubjectsWithEarnings.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [activeWritersCount.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [activeWritersCount.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.active = payload
        },
        [activeWritersCount.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [allWritersCount.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [allWritersCount.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.all = payload
        },
        [allWritersCount.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [pendingQuestionsCount.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [pendingQuestionsCount.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.pendings = payload
        },
        [pendingQuestionsCount.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [approvedQuestionsCount.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [approvedQuestionsCount.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.approveds = payload
        },
        [approvedQuestionsCount.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [rejectedQuestionsCount.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [rejectedQuestionsCount.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.rejecteds = payload
        },
        [rejectedQuestionsCount.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [changeWriterStatus.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [changeWriterStatus.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
        },
        [changeWriterStatus.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [getQuestionStatsByWriter.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getQuestionStatsByWriter.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.stats = payload
        },
        [getQuestionStatsByWriter.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
    }
})

export const { resetState } = editorSlice.actions

export default editorSlice.reducer