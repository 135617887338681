import React, {useEffect, useState} from 'react'
import AuthLayout from '../../components/layouts/AuthLayout'
import {Link} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {login, getUserDetails} from '../../slices/auth/authActions'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import {Form, Button, Spinner, InputGroup} from 'react-bootstrap'
import {FaEye, FaEyeSlash} from 'react-icons/fa'

const Login = () => {
    const [passwordVisibility, setPasswordVisibility] = useState(false)
    const { token, user, loading, error, success } = useSelector((state) => state.auth)
    const loginSchema = yup.object({
        email: yup
            .string()
            .email('Geçerli bir e-posta adresi gir!')
            .required('E-posta adresi gerekli!'),
        password: yup
            .string()
            .min(8, ({min}) => `Şifre en az ${min} karakter olmalıdır!`)
            .required('Şifre gerekli!'),
    })

    const dispatch = useDispatch()

    const {register, handleSubmit, formState: {isValid, isSubmitted, errors}} = useForm({
        resolver: yupResolver(loginSchema)
    })

    const navigate = useNavigate()

    useEffect(() => {
        if(user) {
            console.log(user.roles[0])
            if(user.roles[0] === 'admin-stuff') {
                navigate('/admin')
            } else if(user.roles[0] === 'editor') {
                navigate('/editor/panel')
            } else if(user.roles[0] === 'writer') {
                navigate('/writer/panel')
            }
        }
    }, [navigate, user])

    useEffect(() => {
        if (token) {
            dispatch(getUserDetails({token}))
        }
    }, [token])

    useEffect(() => {
        if(error) {
            toast(error, { type: 'error' })
        }
    }, [error])

    const submitForm = async (data) => {
        await dispatch(login(data))
    }

    return (
        <AuthLayout>
            <div className="row min-vh-100 flex-center g-0">
                <div className="col-lg-8 col-xxl-5 py-3 position-relative">
                    <img className="bg-auth-circle-shape" src="./img/icons/spot-illustrations/bg-shape.png" alt=""
                         width="250"/>
                    <img className="bg-auth-circle-shape-2" src="./img/icons/spot-illustrations/shape-1.png" alt=""
                         width="150"/>
                    <div className="card overflow-hidden z-index-1">
                        <div className="card-body p-0">
                            <div className="row g-0 h-100">
                                <div className="col-md-5 text-center bg-card-gradient">
                                    <div className="position-relative p-4 pt-md-5 pb-md-7 light">
                                        <div className="bg-holder bg-auth-card-shape"
                                             style={{backgroundImage: 'url(./img/icons/spot-illustrations/half-circle.png)'}}></div>
                                        <div className="z-index-1 position-relative">
                                            <a className="link-light mb-4 font-sans-serif fs-4 d-inline-block fw-bolder"
                                               href="#">
                                                <img src="./img/logos/logo_corporate-colored2x.png" alt=""
                                                     width={'80%'}/>
                                            </a>
                                            <p className="opacity-75 text-white">
                                                Merhaba, VIP Kurum'a hoşgeldiniz.
                                                Yandaki alanlara bilgilerinizi girerek giriş yapabilirsiniz.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-3 mb-4 mt-md-4 mb-md-5 light">
                                        <p className="text-white">Hesabınız yok mu?<br/>
                                            <Link to="/kayit-ol" className="btn btn-outline-light mt-2 px-4">
                                                Kaydol
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-7 d-flex flex-center">
                                    <div className="p-4 p-md-5 flex-grow-1">
                                        <div className="row flex-between-center">
                                            <div className="col-auto">
                                                <h3>Giriş Yap</h3>
                                            </div>
                                        </div>
                                        <Form noValidate onSubmit={handleSubmit(submitForm)}>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="card-email">E-Posta Adresi</label>
                                                <Form.Control
                                                    className="form-control"
                                                    type="email"
                                                    isValid={isSubmitted && !errors.email}
                                                    isInvalid={isSubmitted && !!errors.email}
                                                    {...register('email')}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email ? errors.email.message : ''}
                                                </Form.Control.Feedback>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="card-password">Şifre</label>
                                                <InputGroup>
                                                    <Form.Control
                                                        autoComplete="new-password"
                                                        className="form-control"
                                                        type={passwordVisibility ? 'text' : 'password'}
                                                        isValid={isSubmitted && !errors.password}
                                                        isInvalid={isSubmitted && !!errors.password}
                                                        {...register('password')}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.password ? errors.password.message : ''}
                                                    </Form.Control.Feedback>
                                                    {
                                                        !errors.password && (
                                                            <Button
                                                                onClick={() => setPasswordVisibility(!passwordVisibility)}
                                                                variant='outline-secondary'>
                                                                {
                                                                    passwordVisibility && (
                                                                        <FaEyeSlash size={18} color='black' />
                                                                    )
                                                                }
                                                                {
                                                                    !passwordVisibility && (
                                                                        <FaEye size={18} color='black' />
                                                                    )
                                                                }
                                                            </Button>
                                                        )
                                                    }
                                                </InputGroup>
                                            </div>
                                            <div className="row flex-between-center">
                                                <div className="col-auto">
                                                    <div className="form-check mb-0">
                                                        <input className="form-check-input" type="checkbox"
                                                               id="card-checkbox"/>
                                                        <label className="form-check-label mb-0"
                                                               htmlFor="card-checkbox">Beni Hatırla</label>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <Link to="/sifremi-unuttum" className="fs--1">
                                                        Şifremi Unuttum
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <Button
                                                    className="btn btn-primary d-block w-100 mt-3"
                                                    type="submit"
                                                    name="submit"
                                                    disabled={loading}>
                                                    {loading ?
                                                        <>
                                                            <Spinner
                                                                as="span"
                                                                animation="grow"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            /> Bekleyin...
                                                        </>
                                                        : 'Giriş'
                                                    }
                                                </Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </AuthLayout>
    )
}

export default Login