import http from '../../utils/http'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {clearLocalStorage} from '../../utils/misc'

export const getSubjects = createAsyncThunk(
'subject/list',
async ({token}, { rejectWithValue}) => {
    try {
        await http.get('/sanctum/csrf-cookie')
        if(token) {
            http.defaults.headers.common = {
                'Authorization': `Bearer ${token}`
            }
        }
        const { data } = await http.get('/api/editor/subjects')
        return data.response
    } catch (error) {
        if(error?.response?.status === 401) {
            clearLocalStorage()
            return rejectWithValue('Tekrar giriş yapınız!')
        }
        return rejectWithValue({error: error.response.data.errors, code: error?.response?.status })
    }
})

export const saveSubject = createAsyncThunk(
    'writer/save',
    async ({ token, subject }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.post('/api/editor/subjects', subject)
            return data.response
        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
})

export const updateSubject = createAsyncThunk(
    'unit/update',
    async ({ token, subject, id }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.put('/api/editor/subjects/' + id, subject)
            return data.response
        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
})

export const deleteSubject = createAsyncThunk(
    'unit/delete',
    async ({ token, id }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.delete('/api/editor/subjects/' + id)
            return data.response
        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
    })


