import http from '../../utils/http'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {clearLocalStorage} from '../../utils/misc'

export const getByStatus = createAsyncThunk(
'source-items/byStatus',
async ({ token, status, pageNumber }, { rejectWithValue}) => {
    try {
        await http.get('/sanctum/csrf-cookie')
        if(token) {
            http.defaults.headers.common = {
                'Authorization': `Bearer ${token}`
            }
        }
        /*if(!writer) {
            const { data } = await http.post('/api/editor/question/get-by-status', {
                status
            })
            return data.response
        } else {*/
            const { data } = await http.post('/api/source-items/get-by-status', {
                pageNumber,
                status
            })
            return {
                status,
                response: data.response
            }
        // }

    } catch (error) {
        if(error?.response?.status === 401) {
            clearLocalStorage()
            return rejectWithValue('Tekrar giriş yapınız!')
        }
        return rejectWithValue({error: error.response.data.errors, code: error?.response?.status })
    }
})

export const deleteSource = createAsyncThunk(
'source-item/delete',
async ({ token, id }, { rejectWithValue}) => {
    try {
        await http.get('/sanctum/csrf-cookie')
        if(token) {
            http.defaults.headers.common = {
                'Authorization': `Bearer ${token}`
            }
        }
        const { data } = await http.delete('/api/writer/source-items/' + id)
        return data.response
    } catch (error) {
        if(error.response.status === 401) {
            clearLocalStorage()
            return rejectWithValue('Tekrar giriş yapınız!')
        }
        return rejectWithValue({error: error.response.data.errors, code: error.response.status })
    }
})

export const saveSource = createAsyncThunk(
    'source-item/save',
    async ({ token, item, id }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            console.log('item', item)
            const { data } = await http.post('/api/writer/source-items', item)
            return {
                data: data.response,
                id
            }
        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
    })

export const updateSource = createAsyncThunk(
    'source-item/update',
    async ({ token, item, id }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.put(`/api/writer/source-items/${id}`, item)
            return data.response

        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
    })
export const updateStatus = createAsyncThunk(
    'source-item/updateStatus',
    async ({ token, item, id }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.post(`/api/editor/question/update-status`, question)
            return data.response

        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
    })





