import React, {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'

const NavBar = () => {
    const location = useLocation()
    const [url, setUrl] = useState(null)
    useEffect(() => {
        setUrl(location.pathname)
    }, [location])

    return (
        <nav className="navbar navbar-light navbar-vertical navbar-expand-xl">
            <div className="d-flex align-items-center">
                <div className="toggle-icon-wrapper">
                    <button className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                            data-bs-toggle="tooltip" data-bs-placement="left" title="Toggle Navigation"><span
                        className="navbar-toggle-icon"><span className="toggle-line"></span></span></button>
                </div>
                <a className="navbar-brand" href="#">
                    <div className="d-flex align-items-center py-3">
                        <img src="./img/logos/logo_corporate-colored2x.png" alt="" width={'80%'}/>
                    </div>
                </a>
            </div>
            <div className="collapse navbar-collapse" id="navbarVerticalCollapse">
                <div className="navbar-vertical-content scrollbar">
                    <ul className="navbar-nav flex-column mb-3" id="navbarVerticalNav">
                        <li className="nav-item">
                            <Link className={'nav-link' + (url === '/writer/panel' ? ' active' : '')} to="/writer/panel" role="button" data-bs-toggle="" aria-expanded="false">
                                <div className="d-flex align-items-center">
                                    <span className="nav-link-icon">
                                        <span className="fas fa-home"></span>
                                    </span>
                                    <span className="nav-link-text ps-1">Anasayfa</span>
                                </div>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <div className="row navbar-vertical-label-wrapper mt-3 mb-2">
                                <div className="col-auto navbar-vertical-label">Soru Araçları</div>
                                <div className="col ps-0">
                                    <hr className="mb-0 navbar-vertical-divider"/>
                                </div>
                            </div>
                            <a className="nav-link dropdown-indicator" href="#tools" role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="tools">
                                <div className="d-flex align-items-center">
                                    <span className="nav-link-icon">
                                        <span className="fas fa-toolbox"></span>
                                    </span>
                                    <span className="nav-link-text ps-1">Araçlar</span>
                                </div>
                            </a>
                            <ul className={'nav' + (url === '/writer/soru/olustur' || url === '/writer/kitap/olustur' || url === '/writer/video/olustur' ? ' collapsed' : ' collapse')} id="tools">
                                <li className="nav-item">
                                    <Link className={'nav-link' + (url === '/writer/soru/olustur' ? ' active' : '')} to="/writer/soru/olustur" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-text ps-1">Soru Oluşturma</span>
                                        </div>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={'nav-link' + (url === '/writer/kitap/olustur' ? ' active' : '')} to="/writer/kitap/olustur"  data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-text ps-1">Kitap Kaynak Seçimi</span>
                                        </div>
                                    </Link>
                                </li>
                                {/*<li className="nav-item">
                                    <Link className={'nav-link' + (url === '/writer/video/olustur' ? ' active' : '')} to="/writer/video/olustur" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-text ps-1">Kaynak Videolar</span>
                                        </div>
                                    </Link>
                                </li>*/}
                            </ul>
                            <div className="row navbar-vertical-label-wrapper mt-3 mb-2">
                                <div className="col-auto navbar-vertical-label">Soru Grupları</div>
                                <div className="col ps-0">
                                    <hr className="mb-0 navbar-vertical-divider"/>
                                </div>
                            </div>
                            <a className="nav-link dropdown-indicator" href="#question" role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="question">
                                <div className="d-flex align-items-center">
                                    <span className="nav-link-icon">
                                        <span className="fas fa-question-circle"></span>
                                    </span>
                                    <span className="nav-link-text ps-1">Sorular</span>
                                </div>
                            </a>
                            <ul className={'nav' + (url === '/writer/soru/bekleyen' || url === '/writer/soru/onaylanan' || url === '/writer/soru/reddedilen' ? ' collapsed' : ' collapse')} id="question">
                                <li className="nav-item">
                                    <Link className={'nav-link' + (url === '/writer/soru/bekleyen' ? ' active' : '')} to="/writer/soru/bekleyen" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-text ps-1">Bekleyen</span>
                                        </div>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={'nav-link' + (url === '/writer/soru/onaylanan' ? ' active' : '')} to="/writer/soru/onaylanan" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-text ps-1">Onaylanan</span>
                                        </div>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={'nav-link' + (url === '/writer/soru/reddedilen' ? ' active' : '')} to="/writer/soru/reddedilen" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-text ps-1">Reddedilen</span>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                            <div className="row navbar-vertical-label-wrapper mt-3 mb-2">
                                <div className="col-auto navbar-vertical-label">Kaynak Grupları</div>
                                <div className="col ps-0">
                                    <hr className="mb-0 navbar-vertical-divider"/>
                                </div>
                            </div>
                            <a className="nav-link dropdown-indicator" href="#source" role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="source">
                                <div className="d-flex align-items-center">
                                    <span className="nav-link-icon">
                                        <span className="fas fa-book"></span>
                                    </span>
                                    <span className="nav-link-text ps-1">Kaynaklar - Kitaplar</span>
                                </div>
                            </a>
                            <ul className={'nav' + (url === '/writer/kitap/bekleyen' || url === '/writer/kitap/onaylanan' || url === '/writer/kitap/reddedilen' ? ' collapsed' : ' collapse')} id="source">
                                <li className="nav-item">
                                    <Link className={'nav-link' + (url === '/writer/kitap/bekleyen' ? ' active' : '')} to="/writer/kitap/bekleyen" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-text ps-1">Bekleyen</span>
                                        </div>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={'nav-link' + (url === '/writer/kitap/onaylanan' ? ' active' : '')} to="/writer/kitap/onaylanan" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-text ps-1">Onaylanan</span>
                                        </div>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={'nav-link' + (url === '/writer/kitap/reddedilen' ? ' active' : '')} to="/writer/kitap/reddedilen" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-text ps-1">Reddedilen</span>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default NavBar