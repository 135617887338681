import React from 'react'

const AuthLayout = ({ children }) => {
    return (
        <main className='main' id='top'>
            <div className="container-fluid">
                { children }
            </div>
        </main>
    )
}

export default AuthLayout