import { createSlice } from '@reduxjs/toolkit'
import { login, logout, getUserDetails } from './authActions'
import secureLocalStorage from 'react-secure-storage'

const user = secureLocalStorage.getItem('user')
    ? JSON.parse(secureLocalStorage.getItem('user'))
    : null

const token = secureLocalStorage.getItem('token')
    ? JSON.parse(secureLocalStorage.getItem('token'))
    : null

const initialState = {
    loading: false,
    user,
    token,
    error: null,
    success: false,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: {
        [login.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [login.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            state.token = payload
        },
        [login.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [logout.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [logout.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.user = null
            state.token = null
        },
        [logout.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [getUserDetails.pending]: (state) => {
            state.loading = true
        },
        [getUserDetails.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            state.user = payload
        },
        [getUserDetails.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
    }
})

export default authSlice.reducer