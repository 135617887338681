import http from '../../utils/http'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { clearLocalStorage } from '../../utils/misc'

export const getLessons = createAsyncThunk(
    'editor/lessons',
    async ({ token }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.get('/api/editor/lessons')
            return data.response
        } catch (error) {
            return rejectWithValue(error.response.data.errors)
        }
})

export const saveLesson = createAsyncThunk(
    'editor/lessons/save',
    async ({ token, lesson }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.post('/api/editor/lessons', lesson)
            return data.response
        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
})

export const updateLesson = createAsyncThunk(
    'editor/lessons/update',
    async ({ token, lesson, id }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.put('/api/editor/lessons/' + id, lesson)
            return data.response
        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
    })

export const deleteLesson = createAsyncThunk(
    'editor/lessons/delete',
    async ({ token, id }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.delete('/api/editor/lessons/' + id)
            return data.response
        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
    })