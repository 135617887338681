import {createSlice} from '@reduxjs/toolkit'
import {getDifficultyLevels} from './difficultyLevelActions'

const initialState = {
    loading: false,
    difficultyLevels: [],
    difficultyLevel: null,
    error: null,
    errorCode: null,
    success: false
}

export const difficultyLevelSlicer = createSlice({
    name: 'difficultyLevel',
    initialState,
    reducers: {

    },
    extraReducers: {
        [getDifficultyLevels.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getDifficultyLevels.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.difficultyLevels = payload
        },
        [getDifficultyLevels.rejected]: (state, {payload}) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
    }
})

export default difficultyLevelSlicer.reducer