import React, {useEffect} from 'react'
import DashboardLayout from '../../components/layouts/DashboardLayout'
import {activeWritersCount, allWritersCount, pendingQuestionsCount, approvedQuestionsCount, rejectedQuestionsCount, getQuestionStatsByWriter} from '../../slices/editor/editorActions'
import {useSelector, useDispatch} from 'react-redux'
import {getLessons} from '../../slices/lessons/lessonActions'
import {toast} from 'react-toastify'
import {logout} from '../../slices/auth/authActions'

const Dashboard = () => {
    const dispatch = useDispatch()
    const {loading, error, success, errorCode, active, all, pendings, approveds, rejecteds, stats} = useSelector((state) => state.editor)
    const {token, user} = useSelector((state) => state.auth)

    useEffect(() => {
        dispatch(activeWritersCount({token}))
        dispatch(allWritersCount({token}))
        dispatch(getLessons({token}))
        dispatch(pendingQuestionsCount({token}))
        dispatch(approvedQuestionsCount({token}))
        dispatch(rejectedQuestionsCount({token}))
        dispatch(getQuestionStatsByWriter({token}))
    }, [])

    useEffect(() => {
        console.log('Error: ', error)
        if(error) {
            if(Array.isArray(error.error) || typeof error.error === 'object') {
                Object.keys(error.error).map((err, index) => (
                    console.log(error.error[err][0])
                    // toast(error.error[err][0], { type: 'error' })
                ))
            } else {
                console.log('Error: ', error.error)
                toast(error.error, { type: 'error' })
            }
        }
    }, [error])

    useEffect(() => {
        if(errorCode) {
            console.log('Code', errorCode)
            if(errorCode === 401) {
                dispatch(logout({token}))
            }
        }
    }, [errorCode])

    useEffect(() => {
        if(stats) {
            console.log('Stats', stats)
        }
    }, [stats])

    return (
        <DashboardLayout>
            <div className="row g-3 mb-3">
                <div className="col-md-6 col-xxl-3">
                    <div className="card h-md-100 ecommerce-card-min-width">
                        <div className="card-header pb-0">
                            <h6 className="mb-0 mt-2 d-flex align-items-center">Yazarlar<span
                                className="ms-1 text-400" data-bs-toggle="tooltip" data-bs-placement="top"
                                title="Sistemde kayıtlı tüm yazarlar"><span
                                className="far fa-question-circle" data-fa-transform="shrink-1"></span></span></h6>
                        </div>
                        <div className="card-body d-flex flex-column justify-content-end">
                            <div className="row">
                                <div className="col">
                                    <p className="font-sans-serif lh-1 mb-1 fs-4">{all}</p>
                                </div>
                                <div className="col-auto ps-0">
                                    <div className="echart-bar-weekly-sales h-100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xxl-3">
                    <div className="card h-md-100">
                        <div className="card-header pb-0">
                            <h6 className="mb-0 mt-2">Bekleyen Sorular</h6>
                        </div>
                        <div className="card-body d-flex flex-column justify-content-end">
                            <div className="row justify-content-between">
                                <div className="col-auto align-self-end">
                                    <div className="fs-4 fw-normal font-sans-serif text-700 lh-1 mb-1">{pendings}</div>
                                </div>
                                <div className="col-auto ps-0 mt-n4">
                                    <div className="echart-default-total-order"
                                         data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                                         data-echart-responsive="true"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xxl-3">
                    <div className="card h-md-100">
                        <div className="card-header pb-0">
                            <h6 className="mb-0 mt-2">Onaylanan Sorular</h6>
                        </div>
                        <div className="card-body d-flex flex-column justify-content-end">
                            <div className="row justify-content-between">
                                <div className="col-auto align-self-end">
                                    <div className="fs-4 fw-normal font-sans-serif text-700 lh-1 mb-1">{approveds}</div>
                                </div>
                                <div className="col-auto ps-0 mt-n4">
                                    <div className="echart-default-total-order"
                                         data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                                         data-echart-responsive="true"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xxl-3">
                    <div className="card h-md-100">
                        <div className="card-header pb-0">
                            <h6 className="mb-0 mt-2">Reddedilenler</h6>
                        </div>
                        <div className="card-body d-flex flex-column justify-content-end">
                            <div className="row justify-content-between">
                                <div className="col-auto align-self-end">
                                    <div className="fs-4 fw-normal font-sans-serif text-700 lh-1 mb-1">{rejecteds}</div>
                                </div>
                                <div className="col-auto ps-0 mt-n4">
                                    <div className="echart-default-total-order"
                                         data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                                         data-echart-responsive="true"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row g-3 mb-3">
                {/*<div className="col-xxl-4 col-lg-6 order-xxl-3">
                    <div className="card h-100 members-activity">
                        <div className="card-header bg-light py-2 d-flex flex-between-center">
                            <h6 className="mb-0">Members Activity</h6>
                            <div className="dropdown font-sans-serif btn-reveal-trigger">
                                <button
                                    className="btn btn-link text-600 btn-sm dropdown-toggle dropdown-caret-none btn-reveal"
                                    type="button" id="dropdown-members-activity" data-bs-toggle="dropdown"
                                    data-boundary="viewport" aria-haspopup="true" aria-expanded="false"><span
                                    className="fas fa-ellipsis-h fs--2"></span></button>
                                <div className="dropdown-menu dropdown-menu-end border py-2"
                                     aria-labelledby="dropdown-members-activity"><a className="dropdown-item"
                                                                                    href="#!">View</a><a
                                    className="dropdown-item" href="#!">Export</a>
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item text-danger" href="#!">Remove</a>
                                </div>
                            </div>
                        </div>
                        <div className="card-body scrollbar-overlay">
                            <div className="row g-2 mb-4">
                                <div className="col-12 d-flex align-items-center">
                                    <div className="avatar avatar-xl rounded-circle">
                                        <img className="rounded-circle" src="./img/team/4-thumb.png" alt=""/>
                                    </div>
                                    <h6 className="mb-0 ps-2">Chandler Bing</h6>
                                </div>
                                <div className="col-4 position-relative">
                                    <img className="w-100" src="./img/management/activity-1.png" alt="..."/>
                                    <div
                                        className="badge rounded-pill position-absolute top-100 start-50 translate-middle badge-soft-danger">20%
                                    </div>
                                </div>
                                <div className="col-4 position-relative"><img className="w-100" src="./img/management/activity-2.png" alt="..."/>
                                    <div
                                        className="badge rounded-pill position-absolute top-100 start-50 translate-middle badge-soft-primary">67%
                                    </div>
                                </div>
                                <div className="col-4 position-relative"><img className="w-100" src="./img/management/activity-3.png" alt="..."/>
                                    <div
                                        className="badge rounded-pill position-absolute top-100 start-50 translate-middle badge-soft-success">89%
                                    </div>
                                </div>
                            </div>
                            <div className="row g-2 mb-4">
                                <div className="col-12 d-flex align-items-center">
                                    <div className="avatar avatar-xl rounded-circle">
                                        <img className="rounded-circle" src="./img/team/5-thumb.png" alt=""/>
                                    </div>
                                    <h6 className="mb-0 ps-2">Jared Dunn</h6>
                                </div>
                                <div className="col-4 position-relative"><img className="w-100" src="./img/management/activity-4.png" alt="..."/>
                                    <div
                                        className="badge rounded-pill position-absolute top-100 start-50 translate-middle badge-soft-success">20%
                                    </div>
                                </div>
                                <div className="col-4 position-relative"><img className="w-100" src="./img/management/activity-5.png" alt="..."/>
                                    <div
                                        className="badge rounded-pill position-absolute top-100 start-50 translate-middle badge-soft-danger">67%
                                    </div>
                                </div>
                                <div className="col-4 position-relative"><img className="w-100" src="./img/management/activity-6.png" alt="..."/>
                                    <div
                                        className="badge rounded-pill position-absolute top-100 start-50 translate-middle badge-soft-primary">89%
                                    </div>
                                </div>
                            </div>
                            <div className="row g-2 mb-4">
                                <div className="col-12 d-flex align-items-center">
                                    <div className="avatar avatar-xl rounded-circle">
                                        <img className="rounded-circle" src="./img/team/16.jpg" alt=""/>
                                    </div>
                                    <h6 className="mb-0 ps-2">Monica Geller</h6>
                                </div>
                                <div className="col-4 position-relative"><img className="w-100" src="./img/management/activity-7.png" alt="..."/>
                                    <div
                                        className="badge rounded-pill position-absolute top-100 start-50 translate-middle badge-soft-danger">20%
                                    </div>
                                </div>
                                <div className="col-4 position-relative"><img className="w-100" src="./img/management/activity-8.png" alt="..."/>
                                    <div
                                        className="badge rounded-pill position-absolute top-100 start-50 translate-middle badge-soft-success">67%
                                    </div>
                                </div>
                                <div className="col-4 position-relative"><img className="w-100" src="./img/management/activity-9.png" alt="..."/>
                                    <div
                                        className="badge rounded-pill position-absolute top-100 start-50 translate-middle badge-soft-primary">89%
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer bg-light p-0"><a className="btn btn-sm btn-link d-block py-2" href="#!">See all projects
                            <span className="fas fa-chevron-right ms-1 fs--2"></span></a>
                        </div>
                    </div>
                </div>*/}
                <div className="col-12">
                    <div className="card h-100">
                        <div className="card-body p-0">
                            <div className="scrollbar">
                                <table
                                    className="table table-dashboard mb-0 table-borderless fs--1 border-200 overflow-hidden rounded-3 table-member-info">
                                    <thead className="bg-light">
                                    <tr className="text-900">
                                        <th>Yazar</th>
                                        <th className="text-center">Bekleyen</th>
                                        <th className="text-center">Onaylanan</th>
                                        <th className="text-center">Reddedilen</th>
                                        <th className="text-center">Toplam</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {stats.length > 0 ?
                                        stats.map(stat => (
                                            <tr className="border-bottom border-200">
                                                <td>
                                                    <div className="d-flex align-items-center position-relative">
                                                        <div className={stat.writer.status == 1 ? 'avatar avatar-2xl status-online' : 'avatar avatar-2xl status-not-disturb'}>
                                                            <img className="rounded-circle" src="./img/team/avatar.png" alt=""/>
                                                        </div>
                                                        <div className="flex-1 ms-3">
                                                            <h6 className="mb-0 fw-semi-bold"><a
                                                                className="stretched-link text-900"
                                                                href="#">{stat.writer.fullname}</a></h6>
                                                            {stat.writer.writer_lessons.map(lesson => (
                                                                <p className="text-500 fs--2 mb-0">{lesson.title}</p>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-middle text-center">
                                                    <small className="badge fw-semi-bold rounded-pill badge-soft-warning">{stat.pendings}</small>
                                                </td>
                                                <td className="align-middle text-center">
                                                    <small className="badge fw-semi-bold rounded-pill badge-soft-danger">{stat.approveds}</small>
                                                </td>
                                                <td className="align-middle text-center">
                                                    <small className="badge fw-semi-bold rounded-pill badge-soft-success">{stat.rejecteds}</small>
                                                </td>
                                                <td className="align-middle text-center">
                                                    <small className="badge fw-semi-bold rounded-pill badge-soft-info">{stat.total}</small>
                                                </td>
                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td>
                                                <div className="d-flex align-items-center position-relative">
                                                    <div className="avatar avatar-2xl status-online">
                                                        <img className="rounded-circle" src="./img/team/25.jpg"
                                                             alt=""/>
                                                    </div>
                                                    <div className="flex-1 ms-3">
                                                        <h6 className="mb-0 fw-semi-bold"><a
                                                            className="stretched-link text-900"
                                                            href="../pages/user/profile.html">Fionna Mayer</a></h6>
                                                        <p className="text-500 fs--2 mb-0">SAAS dashboard design</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="align-middle text-center"><small
                                                className="badge fw-semi-bold rounded-pill badge-soft-danger">Absent</small>
                                            </td>
                                            <td className="align-middle text-center"><small
                                                className="badge fw-semi-bold rounded-pill badge-soft-primary">12%</small>
                                                <p className="fs--2 mb-0">1h:52m </p>
                                            </td>
                                            <td className="align-middle">
                                                <div className="row g-2 justify-content-end">
                                                    <div className="col-auto"><small
                                                        className="badge fw-semi-bold rounded-pill badge-soft-success">68%</small>
                                                        <p className="fs--2 mb-0">1h:52m </p>
                                                    </div>
                                                    <div className="col-auto mt-auto">
                                                        <div className="mb-1"
                                                             data-echarts='{"tooltip":{"show":false},"series":[{"data":[30,22,18,30,20,35]}]}'></div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    }


                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-footer bg-light py-2">
                            <div className="row flex-between-center">
                                <div className="col-auto"></div>
                                <div className="col-auto">
                                    <a className="btn btn-sm btn-link px-0 fw-medium" href="#!">Tümü<span className="fas fa-chevron-right ms-1 fs--2"></span></a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default Dashboard