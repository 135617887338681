import axios from 'axios'

const http = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_PRODUCTION_URL : process.env.REACT_APP_API_DEVELOPMENT_URL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json'
    },
    withCredentials: true
})

/*http.interceptors.request.use((req) => {
    if(token) {
        req.headers.Authorization = `Bearer ${token}`
    }
    return req
})*/


export default http