import React, {useEffect, useState} from 'react'
import DashboardLayout from '../../../components/layouts/DashboardLayout'
import {useSelector, useDispatch} from 'react-redux'
import {IoMdRefresh} from 'react-icons/io'
import {Spinner, OverlayTrigger, Tooltip} from 'react-bootstrap'
import ConfirmationModal from '../../../components/common/ConfirmationModal'
import {toast, ToastContainer} from 'react-toastify'
import {resetEarnings, resetState} from '../../../slices/questions/questionSlice'
import {deleteSource, getByStatus} from '../../../slices/sources/sourceActions'
import EditSourceModal from '../../../components/writer/EditSourceModal'
import {sourceItemCountByStatus} from '../../../slices/writer/writerActions'
import Pagination from 'react-js-pagination'
import {deleteQuestion} from '../../../slices/questions/questionActions'
import EditQuestionModal from '../../../components/editor/EditQuestionModal'

const Pendings = () => {
    const {loading, pendings, error, success} = useSelector((state) => state.source)
    const {earnings} = useSelector((state) => state.question)
    const {pendings: sourceItemCount} = useSelector((state) => state.writer)
    const {token, user} = useSelector((state) => state.auth)
    const dispatch = useDispatch()

    const [showConfirm, setShowConfirm] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [activeSourceItem, setActiveSourceItem] = useState(null)
    const [activeIndex, setActiveIndex] = useState(null)

    const [activePage, setActivePage] = useState(1)

    useEffect(() => {
        fetchData()
        dispatch(sourceItemCountByStatus({token, status: 'waiting_approval'}))
        // dispatch(getByStatus({ token, status: 'waiting_approval' }))
    }, [])

    useEffect(() => {
        if(success) {
            toast('İşlem başarılı!', { type: 'success' })
            handleCloseModal()
        }
    }, [success])

    useEffect(() => {
        console.log(error)
        if(error) {
            if(Array.isArray(error.error) || typeof error.error === 'object') {
                Object.keys(error.error).map((err, index) => (
                    // console.log(error.error[err][0])
                    toast(error.error[err][0], { type: 'error' })
                ))
            } else {
                console.log('Error: ', error.error)
                toast(error.error, { type: 'error' })
            }
        }
    }, [error])

    const handleConfirmModal = (data) => {
        setActiveSourceItem(data)
        setShowConfirm(true)
    }

    const handleRefresh = () => {
        console.log('Refresh Clicked!')
    }

    const handleDelete = async () => {
        await dispatch(deleteQuestion({ token, id: activeSourceItem.id }))
    }

    const handleCloseModal = () => {
        setShowConfirm(false)
        setShowEdit(false)
        setActiveSourceItem(null)
        dispatch(resetState())
    }

    const handleSaveSourceItem = () => {
        console.log('handleSaveSourceItem')
        dispatch(resetState())
        dispatch(resetEarnings())
        setShowEdit(false)
        setActiveSourceItem(null)
        setTimeout(() => {
            setActiveSourceItem(pendings[activeIndex])
            setShowEdit(true)
        }, 2000)

    }

    const handleEditModal = (question) => {
        console.log(question)
        const index = pendings.indexOf(question)
        console.log('index', index)
        setActiveIndex(index)
        setShowEdit(true)
        setActiveSourceItem(question)
    }

    const fetchData = async (pageNumber = 1) => {
        try {
            await dispatch(getByStatus({ token, status: 'waiting_approval', pageNumber }))
        } catch (error) {
            console.log('Error:', error)
        } finally {

        }
    }

    const handlePageChange =  async (pageNumber) => {
        await setActivePage(pageNumber)
        await fetchData(pageNumber)
    }

    return (
        <DashboardLayout>
            <div className="card mb-3">
                <div className="card-header">
                    <div className="row flex-between-end">
                        <div className="col-auto align-self-center">
                            <h5 className="mb-0" data-anchor="data-anchor">Bekleyenler Listesi</h5>
                        </div>
                        <div className="col-auto ms-auto">
                            <button className="btn btn-falcon-default btn-sm" type="button" role="button" onClick={handleRefresh}>
                                <IoMdRefresh size={20} color='blue' />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-0">
                    {
                        loading && (
                            <div className="d-flex flex-center">
                                <Spinner animation="grow" variant="primary" />
                            </div>
                        )
                    }
                    {
                        !loading && (
                            <React.Fragment>
                                <div className="kanban-items-container border bg-white dark__bg-1000 rounded-2 py-3 mb-3 d-flex flex-start flex-wrap" style={{ maxHeight: 'none', height: '80vh' }}>
                                    {
                                        pendings.length > 0
                                            ? pendings.map(pending => (
                                                <div key={pending?.id} className="card mb-3 kanban-item shadow-sm dark__bg-1100 me-2" style={{ width: '300px', height: '235px' }}>
                                                    <div className="card-body">
                                                        <div className="hoverbox rounded-3 text-center">
                                                            <img className="img-fluid" style={{ objectFit: 'contain' }} src={ pending?.item ? (process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PRODUCTION_STORAGE_URL + pending?.item : process.env.REACT_APP_API_DEVELOPMENT_URL + '/' + pending?.item) : '.img/placeholders/question-placeholder.jpeg' } alt=""/>
                                                            <div className="light hoverbox-content bg-dark p-2 flex-center">
                                                                <p className="lead text-white"></p>
                                                                <div className='d-flex justify-content-evenly'>
                                                                    <OverlayTrigger
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id={`tooltip-top`}>
                                                                                İçeriği Sil
                                                                            </Tooltip>
                                                                        }>
                                                                        <a  onClick={() => handleConfirmModal(pending)}
                                                                            className="btn btn-sm btn-falcon-default rounded me-1" role='button'>
                                                                            <span className="fas fa-trash text-danger fs-1"></span>
                                                                        </a>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip-top'>
                                                                                İçeriği Düzenle
                                                                            </Tooltip>
                                                                        }>
                                                                        <a onClick={() => handleEditModal(pending)} className="btn btn-sm btn-falcon-default rounded" role="button">
                                                                            <span className="fas fa-edit text-warning fs-1"></span>
                                                                        </a>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            : <div className='d-flex justify-content-center align-items-center w-100'><h4>Bekleyen Kaynak İçeriği Bulunamadı!</h4></div>
                                    }
                                </div>
                                <div className='d-flex justify-content-center mt-3'>
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={100}
                                        totalItemsCount={sourceItemCount}
                                        pageRangeDisplayed={5}
                                        onChange={(pageNumber) => handlePageChange(pageNumber)}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        firstPageText="İlk"
                                        lastPageText="Son"
                                    />
                                </div>
                            </React.Fragment>

                        )
                    }

                </div>
            </div>
            <ConfirmationModal show={showConfirm} handleClose={handleCloseModal} handleConfirm={handleDelete} header='Soru Sil' />
            {activeSourceItem && showEdit ? <EditSourceModal show={showEdit} handleSave={handleSaveSourceItem} handleClose={handleCloseModal} activeSourceItem={activeSourceItem} /> : ''}
            <ToastContainer/>
        </DashboardLayout>
    )
}

export default Pendings