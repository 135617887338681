import http from '../../utils/http'
import {createAsyncThunk} from '@reduxjs/toolkit'
import {clearLocalStorage} from '../../utils/misc'

export const getUnits = createAsyncThunk(
'unit/list',
async ({token}, {rejectWithValue}) => {
    try {
        console.log(token, 'getUnits')
        await http.get('/sanctum/csrf-cookie')
        if(token) {
            http.defaults.headers.common = {
                'Authorization': `Bearer ${token}`
            }
        }
        const {data} = await http.get('/api/editor/units/')
        return data.response
    } catch (error) {
        if(error?.response?.status === 401) {
            clearLocalStorage()
            return rejectWithValue('Tekrar giriş yapınız!')
        }
        return rejectWithValue({error: error.response.data.errors, code: error?.response?.status })
    }
})

export const saveUnit = createAsyncThunk(
    'writer/save',
    async ({ token, unit }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.post('/api/editor/units', unit)
            return data.response
        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
})

export const updateUnit = createAsyncThunk(
    'unit/update',
    async ({ token, unit, id }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.put('/api/editor/units/' + id, unit)
            return data.response
        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
})

export const deleteUnit = createAsyncThunk(
    'unit/delete',
    async ({ token, id }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.delete('/api/editor/units/' + id)
            return data.response
        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
    })


