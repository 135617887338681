import React from 'react'
import Login from './pages/auth/Login'
import Register from './pages/auth/Register'
import ForgotPassword from './pages/auth/ForgotPassword'
import {Route, Routes} from 'react-router-dom'
import Unauthorized from './routing/Unauthorized'
import RequireAuth from './routing/RequireAuth'
import Admin from './pages/admin/Admin'
import PageNotFound from './routing/PageNotFound'
import EditorDashboard from './pages/editor/Dashboard'
import WriterDashboard from './pages/writer/Dashboard'
import Writers from './pages/editor/Writers'
import Sections from './pages/editor/Sections'
import Lessons from './pages/editor/Lessons'
import Units from './pages/editor/Units'
import Subjects from './pages/editor/Subjects'
import EditorPendings from './pages/editor/questions/Pendings'
import EditorApproveds from './pages/editor/questions/Approveds'
import EditorRejecteds from './pages/editor/questions/Rejecteds'
import WriterPendings from './pages/writer/questions/Pendings'
import WriterApproveds from './pages/writer/questions/Approveds'
import WriterRejecteds from './pages/writer/questions/Rejecteds'
import CreateQuestion from './pages/writer/tools/CreateQuestion'

import CreateSource from './pages/writer/tools/CreateSource'
import WriterSourcePendings from './pages/writer/sources/Pendings'
import WriterSourceApproveds from './pages/writer/sources/Approveds'
import WriterSourceRejecteds from './pages/writer/sources/Rejecteds'

const ROLES = {
    'EDITOR': 'editor',
    'WRITER': 'writer',
    'SYSADMIN': 'sys-admin',
    'ADMIN': 'admin-stuff',
    'STAFF': 'staff',
    'TEACHER': 'teacher',
    'STUDENT': 'student',
    'DEMO': 'demo'
}

function App() {
  return (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/kayit-ol" element={<Register />} />
        <Route path="/sifremi-unuttum" element={<ForgotPassword />} />
        <Route path="/yetki-yok" element={<Unauthorized />} />
        <Route element={<RequireAuth allowedRoles={ROLES.ADMIN} />}>
            <Route path="/admin" element={<Admin />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={ROLES.EDITOR} />}>
            <Route path="/editor/panel" element={<EditorDashboard />} />
            <Route path="/editor/yazar" element={<Writers />} />
            <Route path="/editor/bolum" element={<Sections />} />
            <Route path="/editor/ders" element={<Lessons />} />
            <Route path="/editor/unite" element={<Units />} />
            <Route path="/editor/konu" element={<Subjects />} />
            <Route path="/editor/soru/bekleyen" element={<EditorPendings />} />
            <Route path="/editor/soru/onaylanan" element={<EditorApproveds />} />
            <Route path="/editor/soru/reddedilen" element={<EditorRejecteds />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={ROLES.WRITER} />}>
            <Route path="/writer/panel" element={<WriterDashboard />} />
            <Route path="/writer/soru/bekleyen" element={<WriterPendings />} />
            <Route path="/writer/soru/onaylanan" element={<WriterApproveds />} />
            <Route path="/writer/soru/reddedilen" element={<WriterRejecteds />} />
            <Route path="/writer/soru/olustur" element={<CreateQuestion />} />

            <Route path="/writer/kitap/bekleyen" element={<WriterSourcePendings />} />
            <Route path="/writer/kitap/onaylanan" element={<WriterSourceApproveds />} />
            <Route path="/writer/kitap/reddedilen" element={<WriterSourceRejecteds />} />
            <Route path="/writer/kitap/olustur" element={<CreateSource />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
  );
}

export default App
