import React, {useEffect, useMemo, useState} from 'react'
import {Badge, Button, Form, InputGroup, Spinner} from 'react-bootstrap'
import DashboardLayout from '../../components/layouts/DashboardLayout'
import {AiFillEdit} from 'react-icons/ai'
import {BsTrashFill} from 'react-icons/bs'
import {IoMdRefresh} from 'react-icons/io'
import DataTable from 'react-data-table-component'
import { useSelector, useDispatch } from 'react-redux'
import {deleteLesson, getLessons} from '../../slices/lessons/lessonActions'
import {getSections} from '../../slices/sections/sectionActions'
import {toast} from 'react-toastify'
import {resetState} from '../../slices/lessons/lessonSlice'
import CreateLessonModal from '../../components/editor/CreateLessonModal'
import ConfirmationModal from '../../components/common/ConfirmationModal'
import EditLessonModal from '../../components/editor/EditLessonModal'
import {logout} from '../../slices/auth/authActions'

const FilterComponent = ({ searchText, onSearch, onClear, sectionList, onFilter }) => (
    <>
        <div className='d-flex justify-content-center align-content-center w-100'>
            <div className='w-50 p-3'>
                <Form.Select
                    className='form-control'
                    onChange={onFilter}>
                    <option value="0">Filtrele...</option>
                    {sectionList?.map(section => (
                        <option key={section.id} value={section.id}>{section.title}</option>
                    ))
                    }
                </Form.Select>
            </div>
            <div className='w-50 p-3'>
                <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="Ara..."
                        aria-label="Ara..."
                        aria-describedby="basic-addon2"
                        value={searchText}
                        onChange={onSearch}/>
                    <Button variant="outline-secondary" id="button-addon2" onClick={onClear}>
                        X
                    </Button>
                </InputGroup>
            </div>
        </div>
    </>
)

const paginationComponentOptions = {
    rowsPerPageText: 'Sayfa Başına Satır',
    rangeSeparatorText: '-',
}

const Lessons = () => {
    const { sections } = useSelector((state) => state.section)
    const { loading, lessons, error, success, errorCode } = useSelector((state) => state.lesson)
    const { token, user } = useSelector((state) => state.auth)
    const dispatch = useDispatch()

    const [showCreate, setCreateShow] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [activeLesson, setActiveLesson] = useState(null)
    const [filterText, setFilterText] = useState(0)
    const [searchText, setSearchText] = useState('')
    const [filteredData, setFilteredData] = useState([])
    const [orgData, setOrgData] = useState([])
    // const filteredLessons = lessons.filter(item => item.title && item.title.toLowerCase().includes(filterText.toLowerCase()))

    useEffect(() => {
        dispatch(getLessons({token}))
        dispatch(getSections({token}))
    }, [])

    useEffect(() => {
        if(searchText) {
            const filteredLessons = lessons.filter(item => item.title && item.title.toLowerCase().includes(searchText.toLowerCase()))
            setFilteredData(filteredLessons)
        }
    }, [searchText])

    useEffect(() => {
        if(filterText > 0) {
            const filteredSection = sections.filter(section => section.id == filterText)
            setFilteredData(filteredSection)
        } else {
            setFilterText(0)
            setFilteredData(orgData)
        }

    }, [filterText])

    useEffect(() => {
        if(lessons) {
            setFilteredData(lessons)
            setOrgData(lessons)
        }
    }, [lessons])

    useEffect(() => {
        if(success) {
            toast('İşlem başarılı!', { type: 'success' })
        }
    }, [success])

    useEffect(() => {
        if(error) {
            if(Array.isArray(error.error) || typeof error.error === 'object') {
                Object.keys(error.error).map((err, index) => (
                    // console.log(error.error[err][0])
                    toast(error.error[err][0], { type: 'error' })
                ))
            } else {
                console.log('Error: ', error.error)
                toast(error.error, { type: 'error' })
            }
        }
    }, [error])

    useEffect(() => {
        if(errorCode) {
            console.log('Code', errorCode)
            if(errorCode === 401) {
                dispatch(logout({token}))
            }
        }
    }, [errorCode])

    const handleConfirmModal = (data) => {
        setActiveLesson(data)
        setShowConfirm(true)
    }

    const handleDelete = async () => {
        await dispatch(deleteLesson({ token, id: activeLesson.id }))
    }

    const handleEditModal = (data) => {
        setActiveLesson(data)
        setShowEdit(true)
    }

    const handleCreateModal = () => {
        setCreateShow(true)
    }

    const handleCloseModal = () => {
        setCreateShow(false)
        setShowConfirm(false)
        setShowEdit(false)
        setActiveLesson(null)
        dispatch(resetState())
    }

    const handleRefresh = async () => {
        dispatch(getLessons(token))
    }


    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if(searchText) {
                setSearchText('')
                setFilteredData(orgData)
            }
        }
        return (
            <FilterComponent
                onFilter={e => {
                    console.log('e', e.target.value)
                    setFilterText(e.target.value)
                    // setFilteredData(orgData)
                }}
                onSearch={e => setSearchText(e.target.value)} onClear={handleClear} searchText={searchText} sectionList={sections} />
        )
    }, [searchText, sections])

    const columns = [
        {
            name: 'Başlık',
            selector: row =>row.title,
            sortable: true
        },
        {
            name: 'Bölüm',
            selector: row => row?.sections.length > 0 ? (
                <div>
                    {row?.sections.map((section, i) => (
                        <div>
                            <Badge key={section.id} bg='info'>{section.full_section_title}</Badge>
                        </div>
                    ))}
                </div>
            ) : '-',
            sortable: false
        },
        {
            name: 'İşlemler',
            cell: row => (
                <>
                    <button className="btn btn-falcon-danger me-1 mb-1" type="button" onClick={() => handleConfirmModal(row)}>
                        <BsTrashFill size={20} color='red' />
                    </button>
                    <button className="btn btn-falcon-warning me-1 mb-1" type="button" onClick={() => handleEditModal(row)}>
                        <AiFillEdit size={20} color='blue' />
                    </button>
                </>
            )
        }
    ]

    const filteredColumns = [
        {
            name: 'Bölüm',
            selector: row =>row.fullSectionTitle,
            sortable: true
        },
        {
            name: 'Dersler',
            selector: row => row?.lessons.length > 0 ? (
                <div>
                    {row?.lessons.map((lesson, i) => (
                        <div>
                            <Badge key={lesson.id} bg='info'>{ lesson.title }</Badge>
                        </div>
                    ))}
                </div>
            ) : '-',
            sortable: false
        }
    ]

    return (
        <DashboardLayout>
            <div className="card mb-3">
                <div className="card-header">
                    <div className="row flex-between-end">
                        <div className="col-auto align-self-center">
                            <h5 className="mb-0" data-anchor="data-anchor">Ders Listesi</h5>
                        </div>
                        <div className="col-auto ms-auto">
                            <button className="btn btn-falcon-default btn-sm me-2" type="button" role="button" onClick={handleCreateModal}>
                                <span className="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
                                <span className="d-none d-sm-inline-block ms-1">Yeni</span>
                            </button>
                            <button className="btn btn-falcon-default btn-sm" type="button" role="button" onClick={handleRefresh}>
                                <IoMdRefresh size={20} color='blue' />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <div id="tableExample2">
                        <div className="table-responsive scrollbar">
                            <DataTable
                                columns={filterText > 0 ? filteredColumns : columns}
                                data={filteredData}
                                pagination
                                striped
                                subHeader
                                subHeaderComponent={subHeaderComponentMemo}
                                progressPending={loading}
                                paginationComponentOptions={paginationComponentOptions}
                                progressComponent={<Spinner animation="grow" variant="primary" />}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <CreateLessonModal show={showCreate} handleClose={handleCloseModal} />
            {activeLesson ? <EditLessonModal show={showEdit} handleClose={handleCloseModal} activeLesson={activeLesson} /> : '' }
            <ConfirmationModal show={showConfirm} handleClose={handleCloseModal} handleConfirm={handleDelete} header='Ders Sil' />
        </DashboardLayout>
    )
}

export default Lessons