import React, {useEffect, useMemo, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import {Form, Button, Spinner} from 'react-bootstrap'
import {useForm, Controller} from 'react-hook-form'
import Select from 'react-select'
import { updateSection } from '../../slices/sections/sectionActions'
import {useNavigate} from 'react-router-dom'

const EditSectionModal = ({ show, handleClose, activeSection }) => {
    const sectionSchema = yup.object().shape({
        title: yup
            .string()
            .matches(/^[a-zA-ZışçğüöİŞÇĞÜÖ\s\.\-()0-9',:]{2,}$/, 'Geçerli bir bölüm adı giriniz!')
            .required('Bölüm başlığı gerekli!'),
        parentSection: yup
            .string()
            .nullable()
            .notRequired()
            .when('isParent', {
                is: (value) => value === '0',
                then: (rule) => rule.required('Ana bölüm bilgisi seçilmelidir!')
            })
    }, [['parentSection', 'isParent']])

    const { clearErrors, reset, register, handleSubmit, control, setValue, watch, formState: { isValid, isSubmitted, errors }} = useForm({
        resolver: yupResolver(sectionSchema),
        defaultValues: {
            title: activeSection?.title,
            parentSection: activeSection?.parentId,
            isParent: activeSection?.parentId == 0 ? '1' : '0'
        }
    })
    const isParentSection = watch('isParent')
    const { token } = useSelector((state) => state.auth)
    const { loading, error, section, sections, success, errorCode } = useSelector((state) => state.section)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if(isParentSection === '1') {
            setValue('parentSection', '0')
        }
    }, [isParentSection])

    useEffect(() => {
        const clearForm = () => {
            clearErrors()
            reset()
        }
        if(!show) {
            clearForm()
        } else {
        }
    }, [show])

    useEffect(() => {
        if(error) {
            if(Array.isArray(error) || typeof error === 'object') {
                Object.keys(error).map((item, index) => (
                    toast(error[item][0], { type: 'error' })
                ))
            } else {
                toast(error, { type: 'error' })
            }

        }
    }, [error])

    useEffect(() => {
        if(success) {
            handleClose()
        }
    }, [success])

    useEffect(() => {
        if(errorCode === 401) {
            navigate('/')
        }
    }, [errorCode])


    const submitForm = async (data) => {
        await dispatch(updateSection({
            token,
            section: {
                'parent_id': data.parentSection,
                'title': data.title,
                'corporate_id': null
            },
            id: activeSection?.id
        }))
    }

    const handleParentSectionChange = (selected) => {
        setValue('parentSection', selected.value)
    }

    const parentSectionList = sections?.map(parent => (
        {value: parent.id, label: parent.fullSectionTitle}
    ))

    return (
        <>
            <div className={ 'modal fade' + (show ? 'show' : '')} id="error-modal" tabIndex="-1" role="dialog" aria-hidden="true" style={{ display: show ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content position-relative">
                        <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                            <button
                                onClick={handleClose}
                                className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-0">
                            <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                                <h4 className="mb-1" id="modalExampleDemoLabel">Bölüm Düzenle </h4>
                            </div>
                            <div className="p-4 pb-0">
                                <Form noValidate onSubmit={handleSubmit(submitForm)} className="row g-3">
                                    <div className="col-md-12 mb-1">
                                        <label htmlFor="section-type" className="col-form-label">Bölüm Bilgisi Türü:</label>
                                        <div className="alert alert-warning d-flex align-items-center align-content-center" role="alert">
                                            <div className="form-check form-check-inline">
                                                <input
                                                    id='inlineRadio1'
                                                    type="radio"
                                                    className="form-check-input"
                                                    value='1'
                                                    {...register('isParent')}/>
                                                <label htmlFor='inlineRadio1' className="form-check-label">Ana Bölüm</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    id='inlineRadio2'
                                                    type="radio"
                                                    className="form-check-input"
                                                    value='0'
                                                    {...register('isParent')}
                                                />
                                                <label htmlFor="inlineRadio2" className="form-check-label">Alt Bölüm</label>
                                            </div>
                                        </div>
                                    </div>
                                    {isParentSection === '0' && (
                                        <div className="col-md-12 mb-1">
                                            <label className="col-form-label" htmlFor="parent-section">Bölümler:</label>
                                            <Controller
                                                name='parentSection'
                                                control={control}
                                                render={({ onChange, value, ref }) => (
                                                    <>
                                                        <Select
                                                            id='parent-section'
                                                            className={'form-control' + (errors.parentSection ? ' is-invalid' : isSubmitted ? ' is-valid' : '')}
                                                            isSearchable
                                                            defaultValue={{ value: activeSection?.parentId, label: activeSection?.parentFullTitle }}
                                                            options={parentSectionList}
                                                            onChange={handleParentSectionChange}
                                                        />
                                                        <Form.Control.Feedback type='invalid'>
                                                            {errors.parentSection ? errors.parentSection.message : ''}
                                                        </Form.Control.Feedback>
                                                    </>
                                                )}
                                            />
                                        </div>
                                    )}
                                    <div className="col-md-12 mb-1">
                                        <label className="col-form-label" htmlFor="section-title">Bölüm:</label>
                                        <Form.Control
                                            className="form-control"
                                            id="section-title"
                                            type="text"
                                            isValid={isSubmitted && !errors.title}
                                            isInvalid={isSubmitted && !!errors.title}
                                            {...register('title')}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.title ? errors.title.message : ''}
                                        </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                        <Button
                                            className="btn btn-warning"
                                            type="submit"
                                            name='submit'
                                            disabled={loading}>
                                            {loading ?
                                                <>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    /> Bekleyin...
                                                </>
                                                : 'Güncelle'
                                            }
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button
                                onClick={handleClose}
                                className="btn btn-secondary" type="button" data-bs-dismiss="modal">Kapat</Button>
                        </div>
                    </div>
                </div>
            </div>
            {
                show && (
                    <div className="modal-backdrop fade show"></div>
                )
            }
            <ToastContainer/>
        </>
    )
}

export default EditSectionModal