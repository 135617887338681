import secureLocalStorage from 'react-secure-storage'

const flattenArr = (arr) => {
    let newArr = []
    const flatten = (array) => {
        let count = 0
        while (count < array.length) {
            let item = array[count]
            newArr.push(item)
            if(Array.isArray(item.subSections) && item.subSections.length > 0) {
                flatten(item.subSections)
            }
            count++
        }
        return newArr
    }
    return flatten(arr)
}

const clearLocalStorage = (key = null) => {
    if(key) {
        secureLocalStorage.removeItem(key)
    } else {
        secureLocalStorage.clear()
    }
}

export {
    flattenArr,
    clearLocalStorage
}