import React, {useEffect, useState, useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {AiFillFileAdd, AiOutlineDrag, AiOutlineRotateLeft, AiOutlineRotateRight} from 'react-icons/ai'
import {FaCrop, FaTrash, FaChevronLeft, FaChevronRight} from 'react-icons/fa'
import {BsZoomIn, BsZoomOut, BsSquareFill, BsSquareHalf} from 'react-icons/bs'
import {GiHorizontalFlip, GiVerticalFlip} from 'react-icons/gi'
import {FiCrop, FiCheckCircle, FiSave, FiChevronLeft, FiChevronRight} from 'react-icons/fi'
import {MdOutlineCancel} from 'react-icons/md'
import {TbZoomCancel} from 'react-icons/tb'
import {Document, Page, Outline} from 'react-pdf/dist/umd/entry.webpack5'
import ShowCropped from '../../../components/cropper/ShowCropped'
import {changeDpiDataUrl, changeDpiBlob} from 'changedpi'
import {toast, ToastContainer} from 'react-toastify'
import { addToCroppeds } from '../../../slices/sources/sourceSlice'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import '@react-pdf-viewer/toolbar/lib/styles/index.css'
import '@react-pdf-viewer/thumbnail/lib/styles/index.css'

const SourceViewerModal = ({show, handleClose, pdfJson, activePdf, fileName, handleFileUpload}) => {
    let canvasData = null
    let croppedData = null
    let cropBoxData = null
    let croppedCanvas = null
    let croppedFile = null
    let croppedUri = null
    let croppedBlob = null

    const canvas = useRef()
    const dispatch = useDispatch()
    const { croppeds } = useSelector((state) => state.question)

    const [pdfWidth, setPdfWidth] = useState(0)
    const [answer, setAnswer] = useState('')
    const [imgUrl, setImgUrl] = useState('')
    const [showCropped, setShowCropped] = useState(false)
    const [cropper, setCropper] = useState(null)
    const [page, setPage] = useState(1)
    const [numPages, setNumPages] = useState(null)
    const [pages, setPages] = useState([])
    const [isRendered, setIsRendered] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [currentPdf, setCurrentPdf] = useState(null)
    const [cropperData, setCropperData] = useState({
        cropped: false,
        cropping: false,
        loaded: false,
        name: '',
        previousUrl: '',
        type: 'image/png',
        url: '',
        file: null,
        uri: null,
        blob: null,
        blobImage: null
    })
    const [rowType, setRowType] = useState(null)
    const [cropperWidth, setCropperWidth] = useState(0)

    useEffect(() => {
        if (show && activePdf) {
            setCurrentPdf(activePdf)
        }
    }, [show, activePdf])

    useEffect(() => {
        if(isRendered) {
            console.log('rowType', rowType)
            stop()
        }
        handleWidth()
    }, [rowType])

    useEffect(() => {
        if(isRendered && cropperWidth > 0) {
            console.log('cropperWidth', cropperWidth)
            start()
        }
    }, [cropperWidth])


    const start = () => {
        if (croppeds.length === 0 && (cropperData.cropped || cropper)) {
            return
        }
        setCropper(new Cropper(canvas.current, {
            zoomable: false,
            highlight: true,
            autoCrop: false,
            dragMode: 'crop',
            background: false,
            zoomOnWheel: false,
            minCropBoxHeight: 48,
            minCropBoxWidth: 48,
            // maxCropBoxWidth: cropperWidth,
            aspectRatio: NaN,
            ready: (e) => {
                if (croppedData) {
                    cropper
                        .crop()
                        .setData(croppedData)
                        .setCanvasData(canvasData)
                        .setCropBoxData(cropBoxData)
                    croppedData = null
                    canvasData = null
                    cropBoxData = null
                }

            },
            crop: ({detail}) => {
                if (detail.width > 0 && detail.height > 0) {
                    /*update({
                        cropping: true
                    })*/
                    setCropperData({...cropperData, cropping: true})
                }
            }
        }))
    }

    useEffect(() => {
        if(cropperData.url !== '') {
            setImgUrl(cropperData.url)
            // setShowCropped(true)
            dispatch(addToCroppeds({
                id: croppeds?.length === 0 ? 1 : croppeds?.length + 1,
                answer: '',
                blobImage: cropperData?.blobImage,
                file: cropperData?.file,
                blob: cropperData?.blob,
                roots: [],
                tags: [],
                row_type: rowType,
                croppedPng: cropperData?.croppedPng,
                earnings: []
            }))
            // clear()
            resetCropper()
        }
    }, [cropperData])

    const addToolBar = () => {
        let btnGroup = null
        if (!document.getElementsByClassName('cropper-button-group')[0]) {
            btnGroup = React.createElement('div', {className: 'cropper-button-group d-flex justify-content-center align-content-center'}, [
                React.createElement('button', {
                    key: '123',
                    className: 'btn btn-sm btn-primary cropper-select-answer me-1',
                    onClick: () => selectAnswer('A')
                }, 'A'),
                React.createElement('button', {
                    key: '456',
                    className: 'btn btn-sm btn-primary cropper-select-answer me-1',
                    onClick: () => selectAnswer('B')
                }, 'B'),
                React.createElement('button', {
                    key: '789',
                    className: 'btn btn-sm btn-primary cropper-select-answer me-1',
                    onClick: () => selectAnswer('C')
                }, 'C'),
                React.createElement('button', {
                    key: '012',
                    className: 'btn btn-sm btn-primary cropper-select-answer me-1',
                    onClick: () => selectAnswer('D')
                }, 'D'),
                React.createElement('button', {
                    key: '345',
                    className: 'btn btn-sm btn-primary cropper-select-answer me-1',
                    onClick: () => selectAnswer('E')
                }, 'E'),
                React.createElement('button', {
                    key: '678',
                    className: 'btn btn-sm btn-success cropper-select-image me-1',
                    onClick: () => doCrop()
                }, <FaCrop size={14} color="white"/>),
                React.createElement('button', {
                    key: '901',
                    className: 'btn btn-sm btn-danger cropper-select-image',
                    onClick: () => cancelCrop()
                }, <FaTrash size={14} color="white"/>),
            ])

            const container = document.querySelector('.cropper-crop-box')
            const el = document.createElement('div')
            el.innerHTML = renderToString(btnGroup)
            container.append(el.firstChild)
            const root = createRoot(document.getElementById('root'))
            root.render(container)

            /*const container = document.querySelector('.cropper-crop-box')
            console.log(container)
            container.innerHTML += renderToString(btnGroup)
            const root = createRoot(container)
            root.render(btnGroup)*/

        }

    }

    const selectAnswer = (answer) => {
        console.log(answer)
    }

    const startCrop = () => {
        start()
        if(cropper) {
            cropper.setDragMode('crop')
        }
    }

    useEffect(() => {
        if(cropper) {
            console.log('cropper', cropper)
            cropper.setDragMode('crop')

        }
    }, [cropper])

    const cancelCrop = () => {
        stop()
    }

    const crop = () => {
        if (cropperData.cropping) {
            croppedData = cropper.getData()
            canvasData = cropper.getCanvasData()
            cropBoxData = cropper.getCropBoxData()
            croppedCanvas = cropper.getCroppedCanvas({
                fillColor: "#fff",
                imageSmoothingEnabled: true,
                imageSmoothingQuality: "high",
            })
            const croppedUrl = croppedCanvas.toDataURL(cropperData.type, 1)
            const croppedPng = changeDpiDataUrl(croppedUrl, 600)
            croppedCanvas.toBlob((blob) => {
                croppedBlob = blob
                croppedFile = new Blob([blob])
                croppedUri = URL.createObjectURL(croppedFile)

                setCropperData({...cropperData,
                    cropped: true,
                    cropping: false,
                    previousUrl: croppedData.url,
                    url: croppedUrl,
                    croppedPng,
                    file: croppedFile,
                    uri: croppedUri,
                    blobImage: croppedUri,
                    blob: croppedBlob
                })
            })
        }
    }

    const stop = () => {
        if (cropper) {
            cropper.destroy()
            setCropper(null)
        }
    }

    const clear = () => {
        if (cropperData.cropping) {
            cropper.clear()
            setCropperData({...cropperData, cropping: false})
        }
    }

    const reset = () => {
        stop()
        setCropperData({...cropperData,
            cropped: false,
            cropping: false,
            loaded: false,
            name: '',
            previousUrl: '',
            type: 'image/png',
            url: '',
            file: null,
            uri: null,
            blob: null,
            blobImage: null
        })
    }

    const resetCropper = () => {
        setCropperData({...cropperData,
            cropped: false,
            cropping: false,
            loaded: false,
            name: '',
            previousUrl: '',
            type: 'image/png',
            url: '',
            file: null,
            uri: null,
            blob: null,
            blobImage: null
        })
    }

    const handlePageChange = (e) => {
        const diff = +e.target.value - +page
        stop()
        setPage(page + diff)
    }

    const handlePdfChange = (e) => {
        stop()
        setCurrentPdf(e.target.value)
    }

    const handlePrevClick = () => {
        stop()
        setPage(page - 1)
    }

    const handleNextClick = () => {
        stop()
        setPage(page + 1)
    }

    useEffect(() => {
        if (numPages) {
            setPages([])
            for (let i = 1; i <= numPages; i++) {
                setPages(prevState => [...prevState, i])
            }
        }
    }, [numPages])

    useEffect(() => {
        if (pages) {
            // stop()
        }
    }, [pages])

    const onDocumentLoadSuccess = (pdf) => {
        console.log('Loaded')
        handleWidth()
        setNumPages(pdf.numPages)
    }

    const onRenderSuccess = (page) => {
        console.log('Rendered')
        const viewPort = page.getViewport(2)
        viewPort.height = 1250
        viewPort.width = 1615
        viewPort.scale = 2
        setPdfWidth(document.getElementsByClassName('react-pdf__Document')[0].clientWidth)
        setIsRendered(true)
        setRowType('multiple')
        // handleWidth()
        if(!cropper || croppeds.length > 0) {
            start()
        }
        if(cropper) {
            console.log('Cropper Exists', cropper)
        }
    }

    const onPageLoadSuccess = (page) => {
        setIsLoaded(true)
    }

    const handleWidth = () => {
        setCropperWidth(Math.round(Number(document.getElementsByClassName('react-pdf__Document')[0].clientWidth)))
    }

    const handleMove = () => {
        start()
    }

    const handleCrop = () => {
        start()
    }

    const handleConfirm = () => {
        crop()
    }

    const handleClear = () => {
        clear()
    }

    const handleReset = () => {
        reset()
    }

    const handleZoomIn = () => {
        if(cropper) {
            cropper.zoom(0.1)
        }
    }

    const handleZoomOut = () => {
        if(cropper) {
            cropper.zoom(-0.1)
        }
    }

    const handleActualSize = () => {
        if(cropper) {
            cropper.zoom(0.8)
        }
    }

    const handleRotateLeft = () => {
        if(cropper) {
            cropper.rotate(-90)
        }
    }

    const handleRotateRight = () => {
        if(cropper) {
            cropper.rotate(90)
        }
    }

    const handleFlipHorizontal = () => {
        if(cropper) {
            cropper.scaleX(-cropper.getData().scaleX || -1)
        }
    }

    const handleFlipVertical = () => {
        if(cropper) {
            cropper.scaleY(-cropper.getData().scaleY || -1)
        }
    }

    const saveAsFile = () => {
        if(cropper) {
            cropper.getCroppedCanvas({
                fillColor: "#fff",
                imageSmoothingEnabled: true,
                imageSmoothingQuality: "high",
            }).toBlob((blob) => {
                const a = document.createElement('a')
                a.download = 'soru-1.png'
                a.href = URL.createObjectURL(blob)
                a.addEventListener('click', (e) => {
                    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000)
                })
                a.click()
            })
        }
    }

    const handleToolbarClick = ({target}) => {
        const action = target.getAttribute('data-action') || target.parentElement.getAttribute('data-action')
        console.log('toolbar', action)
        switch (action) {
            case 'move':
            case 'crop':
                start()
                /*if(cropper) {
                    cropper.setDragMode(action)
                }*/
                break
            case 'confirm':
                crop()
                break
            case 'clear':
                clear()
                break
            case 'reset':
                reset()
                break
            case 'zoom-in':
                if(cropper) {
                    cropper.zoom(0.1)
                }
                break
            case 'zoom-out':
                if(cropper) {
                    cropper.zoom(-0.1)
                }
                break
            case 'actual-size':
                if(cropper) {
                    cropper.zoom(0.8)
                }
                break
            case 'rotate-left':
                if(cropper) {
                    cropper.rotate(-90)
                }
                break
            case 'rotate-right':
                if(cropper) {
                    cropper.rotate(90)
                }
                break
            case 'flip-horizontal':
                if(cropper) {
                    cropper.scaleX(-cropper.getData().scaleX || -1)
                }
                break
            case 'flip-vertical':
                if(cropper) {
                    cropper.scaleY(-cropper.getData().scaleY || -1)
                }
                break
            default:
        }
    }

    useEffect(() => {
        if (!isRendered || !canvas.current) {
            return
        }
        if(!cropper) {
            start()
        }
        // start()
    }, [isRendered])

    useEffect(() => {
        if (!isLoaded || !canvas.current) {
            return
        }
        console.log(cropper)
        /* start() */
    }, [isLoaded])

    const handleItemClick = ({ pageNumber: itemPageNumber }) => {
        setPage(itemPageNumber)
    }

    const handleCancel = () => {
        setShowCropped(false)
        // clear()
        resetCropper()
    }

    const handleSave = () => {
        /*if (!answer) {
            toast.error('Sorunun cevabını seçmelisiniz!')
        } else {*/
            dispatch(addToCroppeds({
                id: croppeds?.length == 0 ? 1 : croppeds?.length + 1,
                answer,
                blobImage: cropperData?.blobImage,
                file: cropperData?.file,
                blob: cropperData?.blob,
                roots: [],
                tags: [],
                row_type: rowType,
                croppedPng: cropperData?.croppedPng,
                earnings: []
            }))
            // clear()
            resetCropper()
            setShowCropped(false)
        /*}*/

    }

    useEffect(() => {
        if(!showCropped && !cropperData?.cropping) {
            // start()
        }
    }, [showCropped])

    return (
        <>
            <div className={'modal fade' + (show ? 'show' : '')} id="error-modal" tabIndex="-1" role="dialog"
                 aria-hidden="true" style={{display: show ? 'block' : 'none'}}>
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content position-relative">
                        <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                            {!showCropped && (
                                <button
                                    onClick={handleClose}
                                    className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                                    aria-label="Close">
                                </button>
                            )}
                        </div>
                        <div className="modal-body p-0">
                            {!showCropped && (
                                <div className="rounded-top-lg p-2 bg-light d-flex justify-content-center">
                                    <select onChange={handlePdfChange} className="form-control-sm me-2" name="" id="">
                                        {pdfJson?.map(pdf => (
                                            <option key={pdf.name}
                                                    selected={pdf.name === fileName ? 'selected' : ''}
                                                    value={pdf.file}>{pdf.name}</option>
                                        ))}

                                    </select>
                                    <button className="btn btn-circle btn-sm" type="button" role="button" onClick={handleFileUpload}>
                                        <AiFillFileAdd size={25} color="blue"/>
                                    </button>
                                </div>
                            )}

                            {showCropped ?
                                (
                                    <ShowCropped imgUrl={imgUrl} setAnswer={handleAnswer} selected={answer} />
                                )
                                :
                                (   <div className="p-0 pb-0 mt-4 d-flex flex-column justify-content-center align-items-center align-content-center" id="pdfDiv">
                                    <Document
                                        file={currentPdf}
                                        onLoadSuccess={onDocumentLoadSuccess}>
                                        <Page
                                            width='1200'
                                            pageNumber={page}
                                            onRenderSuccess={onRenderSuccess}
                                            onLoadSuccess={onPageLoadSuccess}
                                            canvasRef={canvas}
                                            renderTextLayer={false} />
                                    </Document>

                                    </div>
                                )}
                            {isRendered && !showCropped && (
                                            <div className="cropper-button-group toolbar">
                                                <button
                                                    disabled={cropper == null || page === 1}
                                                    title="Önceki Sayfa"
                                                    className="toolbar__button"
                                                    onClick={handlePrevClick}>
                                                    <FiChevronLeft size={30} color="white"/>
                                                </button>
                                                {
                                                    cropperData?.cropping ?
                                                    (
                                                        <>
                                                            <button
                                                                title="Dosya Olarak Kaydet"
                                                                className="toolbar__button me-1"
                                                                onClick={saveAsFile}>
                                                                <FiSave size={20} color="white"/>
                                                            </button>
                                                            <button
                                                                className="toolbar__button me-1"
                                                                title="Kırp"
                                                                onClick={crop}>
                                                                <FiCheckCircle size={20} color="white"/>
                                                            </button>
                                                            <button
                                                                className="toolbar__button me-1"
                                                                title="İptal"
                                                                onClick={handleClear}>
                                                                <MdOutlineCancel size={20} color="white"/>
                                                            </button>
                                                        </>
                                                    )
                                                    :
                                                    (
                                                        <button
                                                            disabled={cropper != null}
                                                            title="Kırpma Modu"
                                                            className="toolbar__button me-1"
                                                            onClick={handleCrop}>
                                                            <FiCrop size={20} color="white"/>
                                                        </button>
                                                    )
                                                }
                                                <button
                                                    disabled={cropper == null}
                                                    title="Büyüt"
                                                    className="toolbar__button"
                                                    onClick={handleZoomIn}>
                                                    <BsZoomIn size={20} color="white"/>
                                                </button>
                                                <button
                                                    disabled={cropper == null}
                                                    title="Küçült"
                                                    className="toolbar__button"
                                                    onClick={handleZoomOut}>
                                                    <BsZoomOut size={20} color="white"/>
                                                </button>

                                                <button
                                                    disabled={cropper == null}
                                                    title="Gerçek Boyut"
                                                    className="toolbar__button"
                                                    onClick={handleActualSize}>
                                                    <TbZoomCancel size={20} color="white"/>
                                                </button>
                                                <button
                                                    disabled={cropper == null}
                                                    title="Sola Döndür"
                                                    className="toolbar__button"
                                                    onClick={handleRotateLeft}>
                                                    <AiOutlineRotateLeft size={20} color="white"/>
                                                </button>
                                                <button
                                                    disabled={cropper == null}
                                                    title="Sağa Döndür"
                                                    className="toolbar__button"
                                                    onClick={handleRotateRight}>
                                                    <AiOutlineRotateRight size={20} color="white"/>
                                                </button>
                                                <button
                                                    disabled={cropper == null}
                                                    title="Yatay Çevir"
                                                    className="toolbar__button"
                                                    onClick={handleFlipHorizontal}>
                                                    <GiHorizontalFlip size={20} color="white"/>
                                                </button>
                                                <button
                                                    disabled={cropper == null}
                                                    title="Dikey Çevir"
                                                    className="toolbar__button"
                                                    onClick={handleFlipVertical}>
                                                    <GiVerticalFlip size={20} color="white"/>
                                                </button>
                                                <button
                                                    disabled={cropper == null || page === numPages}
                                                    title="Sonraki Sayfa"
                                                    className="toolbar__button"
                                                    onClick={handleNextClick}>
                                                    <FiChevronRight size={30} color="white"/>
                                                </button>
                                            </div>
                                        )}
                        </div>
                        <div className="modal-footer d-flex align-content-center">
                            {showCropped ? (
                                <>
                                    <div className='d-flex justify-content-start flex-1'>
                                        {answer ? (
                                            <p>Seçilen cevap: <span className='fs-1 text-danger'>{answer}</span></p>
                                        )
                                        :
                                        (
                                            <p>Soru cevabı seçilmedi!</p>
                                        )}
                                    </div>
                                    <div className='d-flex justify-content-end flex-1'>
                                        <button onClick={handleCancel} className='btn btn-sm btn-danger me-2'>İptal</button>
                                        <button onClick={handleSave} className='btn btn-sm btn-success'>Kaydet</button>
                                    </div>
                                </>
                            )
                            :
                            (
                                <div className="d-flex content-flex-center align-content-center justify-content-center flex-1">
                                    <button
                                        className="btn btn-falcon-default btn-sm me-2"
                                        disabled={page === 1} onClick={handlePrevClick}>
                                        <FaChevronLeft size={16} color="black"/>
                                    </button>
                                    <div>
                                        <select
                                            onChange={handlePageChange}
                                            className="form-control-sm me-2"
                                            id="pageSelect">
                                            {pages?.map(num => (
                                                <option selected={page === num ? 'selected' : ''} key={num}
                                                        value={num}>{num}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <button
                                        className="btn btn-falcon-default btn-sm"
                                        disabled={page === numPages}
                                        onClick={handleNextClick}>
                                        <FaChevronRight size={16} color="black"/>
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {
                show && (
                    <div className="modal-backdrop fade show"></div>
                )
            }
        </>
    )
}

export default SourceViewerModal