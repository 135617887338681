import React, { useState } from 'react'
import {Badge} from 'react-bootstrap'
import Tree from './Tree'

const CollapsibleItem = ({ lesson = {}, subjects = [] }) => {
    const [showTree, setShowTree] = useState(true)
    return (
        <div key={lesson?.title} className='col-xs-12 col-md-6 col-lg-6 col-xl-6'>
            <Badge
                onClick={(e) => setShowTree((v) => !v)}
                key={lesson?.label} bg="info" className='me-1' role='button'>{lesson?.label}</Badge>
            {
                showTree && (
                    <ul className="treeview treeview-stripe treeview-slect" id="treeviewSelectStriped">
                        {
                            subjects?.filter(subject => subject.lessonId === lesson?.value).map((item) => (
                                <Tree key={item.title} subject={item} />
                            ))
                        }
                    </ul>
                )
            }
        </div>
    )
}

export default CollapsibleItem