import React, {useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { removeSelectedEarning, setSelectedEarning } from '../../slices/questions/questionSlice'

const Tree = ({ subject = {} }) => {
    return (
        <TreeNode node={subject} parentChecked={false} />
    )
}

const TreeNode = (props) => {
    const { earnings } = useSelector((state) => state.question)
    const [childVisible, setChildVisible] = useState(false)
    const hasChild = props.node?.children ? props.node?.children.length > 0 : null
    const hasEarning = props.node?.earnings ? props.node?.earnings.length > 0 : null
    const [node, setNode] = useState(props.node)

    const dispatch = useDispatch()

    const checkBoxClickHandler = (e, node) => {
        setNode({...node, selected: e.target.checked})
        if(node?.earnings) {
            console.log('node', node)
            if(node?.earnings.length > 0) {
                if(e.target.checked) {
                    const earnings = node?.earnings
                    earnings.map(earning => dispatch(setSelectedEarning(earning)))
                } else {
                    const earnings = node?.earnings
                    earnings.map(earning => {
                        const index = earnings.findIndex(earning => earning.id == e.target.value)
                        dispatch(removeSelectedEarning(index))
                    })
                }
            } else {
                if(e.target.checked) {
                    dispatch(setSelectedEarning(node))
                } else {
                    const index = earnings.findIndex(earning => earning.id == e.target.value)
                    dispatch(removeSelectedEarning(index))
                }
            }
        } else {
            console.log('node', node)
            if(e.target.checked) {
                dispatch(setSelectedEarning(node))
            } else {
                const index = earnings.findIndex(earning => earning.id == e.target.value)
                dispatch(removeSelectedEarning(index))
            }
        }


    }

    return (
    <li className="treeview-list-item">
        <div className={ (hasChild || hasEarning) ? 'toggle-container' : 'treeview-item' }>
            <input className="form-check-input" type="checkbox" checked={node.selected || props.parentChecked} value={node?.id} onChange={(e) => checkBoxClickHandler(e, node)} />
            {
                hasChild || hasEarning
                ? <a onClick={(e) => setChildVisible((v) => !v)} data-bs-toggle="collapse" role="button" aria-expanded="false">
                    <p className="treeview-text">
                        {node?.title}
                    </p>
                </a>
                : <a className='flex-1' href='#' onClick={(e) => e.preventDefault()}>
                    <p className="treeview-text">
                        {node?.title}
                    </p>
                </a>
            }
        </div>
        {hasChild && !hasEarning && childVisible &&(
            <ul className={ 'collapse treeview-list ' + childVisible ? 'collapse-show show' : 'collapse-hidden'  }>
                {
                    node?.children.map((child) => (
                        <TreeNode key={child.id} node={child} parentChecked={node.selected} />
                    ))
                }
            </ul>
        )}
        {!hasChild && hasEarning && childVisible &&(
            <ul className={ 'collapse treeview-list ' + childVisible ? 'collapse-show show' : 'collapse-hidden'  }>
                {
                    node?.earnings.map((earning) => (
                        <TreeNode key={earning.id} node={earning} parentChecked={node.selected} />
                    ))
                }
            </ul>
        )}
    </li>
    )
}

export default Tree