import React, {useEffect, useMemo, useState} from 'react'
import {Badge, Button, Form, InputGroup, Spinner} from 'react-bootstrap'
import DashboardLayout from '../../components/layouts/DashboardLayout'
import {AiFillEdit} from 'react-icons/ai'
import {BsTrashFill} from 'react-icons/bs'
import {IoMdRefresh} from 'react-icons/io'
import DataTable from 'react-data-table-component'
import {useSelector, useDispatch} from 'react-redux'
import {deleteUnit, getUnits} from '../../slices/units/unitActions'
import {toast} from 'react-toastify'
import {resetState} from '../../slices/units/unitSlice'
import CreateUnitModal from '../../components/editor/CreateUnitModal'
import ConfirmationModal from '../../components/common/ConfirmationModal'
import EditUnitModal from '../../components/editor/EditUnitModal'
import {getLessons} from '../../slices/lessons/lessonActions'
import {logout} from '../../slices/auth/authActions'

const FilterComponent = ({ searchText, onSearch, onClear, lessonList, onFilter }) => (
    <>
        <div className='d-flex justify-content-center align-content-center w-100'>
            <div className='w-50 p-3'>
                <Form.Select
                    className='form-control'
                    onChange={onFilter}>
                    <option value="0">Filtrele...</option>
                    {lessonList?.map(lesson => (
                        <option key={lesson.id} value={lesson.id}>{lesson.title}</option>
                    ))
                    }
                </Form.Select>
            </div>
            <div className='w-50 p-3'>
                <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="Ara..."
                        aria-label="Ara..."
                        aria-describedby="basic-addon2"
                        value={searchText}
                        onChange={onSearch}/>
                    <Button variant="outline-secondary" id="button-addon2" onClick={onClear}>
                        X
                    </Button>
                </InputGroup>
            </div>
        </div>
    </>
)

const paginationComponentOptions = {
    rowsPerPageText: 'Sayfa Başına Satır',
    rangeSeparatorText: '-',
}

const Lessons = () => {
    const {lessons} = useSelector((state) => state.lesson)
    const {loading, units, error, success, errorCode} = useSelector((state) => state.unit)
    const {token, user} = useSelector((state) => state.auth)
    const dispatch = useDispatch()

    const [showCreate, setCreateShow] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [activeUnit, setActiveUnit] = useState(null)
    const [filterText, setFilterText] = useState(0)
    const [searchText, setSearchText] = useState('')
    const [filteredData, setFilteredData] = useState([])
    const [orgData, setOrgData] = useState([])


    useEffect(() => {
        dispatch(getUnits({token}))
        dispatch(getLessons({token}))
    }, [])

    useEffect(() => {
        if(success) {
            toast('İşlem başarılı!', { type: 'success' })
        }
    }, [success])

    useEffect(() => {
        if(error) {
            if(Array.isArray(error.error) || typeof error.error === 'object') {
                Object.keys(error.error).map((err, index) => (
                    console.log(error.error[err][0])
                    // toast(error.error[err][0], { type: 'error' })
                ))
            } else {
                console.log('Error: ', error.error)
                toast(error.error, { type: 'error' })
            }
        }
    }, [error])

    useEffect(() => {
        if(searchText) {
            const filteredUnits = units.filter(item => item.title && item.title.toLowerCase().includes(searchText.toLowerCase()))
            setFilteredData(filteredUnits)
        }
    }, [searchText])

    useEffect(() => {
        if(filterText > 0) {
            const filteredUnits = units.filter(unit => unit.lesson.id == filterText)
            setFilteredData(filteredUnits)
        } else {
            setFilterText(0)
            setFilteredData(orgData)
        }

    }, [filterText])

    useEffect(() => {
        if(units) {
            setFilteredData(units)
            setOrgData(units)
        }
    }, [units])

    useEffect(() => {
        if(errorCode) {
            console.log('Code', errorCode)
            if(errorCode === 401) {
                dispatch(logout({token}))
            }
        }
    }, [errorCode])

    const handleConfirmModal = (data) => {
        setActiveUnit(data)
        setShowConfirm(true)
    }

    const handleDelete = async () => {
        await dispatch(deleteUnit({ token, id: activeUnit.id }))
    }

    const handleEditModal = (data) => {
        console.log(data)
        setActiveUnit(data)
        setShowEdit(true)
    }

    const handleCreateModal = () => {
        setCreateShow(true)
    }

    const handleCloseModal = () => {
        setCreateShow(false)
        setShowConfirm(false)
        setShowEdit(false)
        setActiveUnit(null)
        dispatch(resetState())
    }

    const handleRefresh = async () => {
        dispatch(getUnits(token))
    }


    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if(searchText) {
                setSearchText('')
                setFilteredData(orgData)
            }
        }
        return (
            <FilterComponent
                onFilter={e => {
                    console.log('e', e.target.value)
                    setFilterText(e.target.value)
                    // setFilteredData(orgData)
                }}
                onSearch={e => setSearchText(e.target.value)} onClear={handleClear} searchText={searchText} lessonList={lessons} />
        )
    }, [searchText, lessons])

    const columns = [
        {
            name: 'Ünite',
            selector: row =>row.title,
            sortable: true
        },
        {
            name: 'Ders',
            selector: row => row.lesson?.title,
            sortable: true
        },
        {
            name: 'Bölüm',
            selector: row => row?.sections.length > 0 ? (
                <div>
                    {row?.sections.map((section, i) => (
                        <div>
                            <Badge key={section.id} bg='info'>{ section.full_section_title }</Badge>
                        </div>
                    ))}
                </div>
            ) : '-',
            sortable: false
        },
        {
            name: 'İşlemler',
            cell: row => (
                <>
                    <button className="btn btn-falcon-danger me-1 mb-1" type="button" onClick={() => handleConfirmModal(row)}>
                        <BsTrashFill size={20} color='red' />
                    </button>
                    <button className="btn btn-falcon-warning me-1 mb-1" type="button" onClick={() => handleEditModal(row)}>
                        <AiFillEdit size={20} color='blue' />
                    </button>
                </>
            )
        }
    ]

    const filteredColumns = [
        {
            name: 'Ünite',
            selector: row =>row.title,
            sortable: true
        },
        {
            name: 'Ders',
            selector: row => row.lesson?.title,
            sortable: true
        },
        {
            name: 'Bölüm',
            selector: row => row?.sections.length > 0 ? (
                <div>
                    {row?.sections.map((section, i) => (
                        <div>
                            <Badge key={section.id} bg='info'>{ section.full_section_title }</Badge>
                        </div>
                    ))}
                </div>
            ) : '-',
            sortable: false
        },
        {
            name: 'İşlemler',
            cell: row => (
                <>
                    <button className="btn btn-falcon-danger me-1 mb-1" type="button" onClick={() => handleConfirmModal(row)}>
                        <BsTrashFill size={20} color='red' />
                    </button>
                    <button className="btn btn-falcon-warning me-1 mb-1" type="button" onClick={() => handleEditModal(row)}>
                        <AiFillEdit size={20} color='blue' />
                    </button>
                </>
            )
        }
    ]

    return (
        <DashboardLayout>
            <div className="card mb-3">
                <div className="card-header">
                    <div className="row flex-between-end">
                        <div className="col-auto align-self-center">
                            <h5 className="mb-0" data-anchor="data-anchor">Ünite Listesi</h5>
                        </div>
                        <div className="col-auto ms-auto">
                            <button className="btn btn-falcon-default btn-sm me-2" type="button" role="button" onClick={handleCreateModal}>
                                <span className="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
                                <span className="d-none d-sm-inline-block ms-1">Yeni</span>
                            </button>
                            <button className="btn btn-falcon-default btn-sm" type="button" role="button" onClick={handleRefresh}>
                                <IoMdRefresh size={20} color='blue' />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <div id="tableExample2">
                        <div className="table-responsive scrollbar">
                            <DataTable
                                columns={filterText > 0 ? filteredColumns : columns}
                                data={filteredData}
                                pagination
                                striped
                                subHeader
                                subHeaderComponent={subHeaderComponentMemo}
                                progressPending={loading}
                                paginationComponentOptions={paginationComponentOptions}
                                progressComponent={<Spinner animation="grow" variant="primary" />}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <CreateUnitModal show={showCreate} handleClose={handleCloseModal} />
            {activeUnit ? <EditUnitModal show={showEdit} handleClose={handleCloseModal} activeUnit={activeUnit} /> : '' }
            <ConfirmationModal show={showConfirm} handleClose={handleCloseModal} handleConfirm={handleDelete} header='Ünite Sil' />
        </DashboardLayout>
    )
}

export default Lessons