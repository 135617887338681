import { createSlice } from '@reduxjs/toolkit'
import { getByStatus, deleteSource, updateSource, saveSource, updateStatus } from './sourceActions'


const initialState = {
    loading: false,
    sources: [],
    pendings: [],
    approveds: [],
    lastApproveds: [],
    rejecteds: [],
    earnings: [],
    croppeds: [],
    source: null,
    error: null,
    errorCode: null,
    success: false,
}

export const sourceSlice = createSlice({
    name: 'source',
    initialState,
    reducers: {
        resetState(state) {
            state.loading = false
            state.error = null
            state.errorCode = null
            state.success = false
        },
        setSelectedEarning(state, { payload }) {
            state.earnings.push(payload)
        },
        removeSelectedEarning(state, { payload }) {
            state.earnings.splice(payload, 1)
        },
        resetEarnings(state) {
            console.log('resetEarnings')
            state.earnings = []
        },
        addToCroppeds(state, { payload }) {
            state.croppeds.push(payload)
        },
        removeFromCroppeds(state, {payload}) {
            const index = state.croppeds.findIndex(cropped => cropped.id === payload.id)
            state.croppeds.splice(index, 1)
        },
        getLastApproveds(state) {
            state.lastApproveds = state.approveds.slice(5)
        }
    },
    extraReducers: {
        [getByStatus.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getByStatus.fulfilled]: (state, { payload }) => {
            state.loading = false
            // state.success = true
            switch (payload.status) {
                case 'waiting_approval':
                    state.pendings = payload.response
                    break
                case 'approval':
                    state.approveds = payload.response
                    break
                case 'rejected':
                    state.rejecteds = payload.response
                    break
            }
        },
        [getByStatus.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [saveSource.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [saveSource.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            state.croppeds = state.croppeds.filter(item => item.id !== payload.id)
        },
        [saveSource.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [updateSource.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [updateSource.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            console.log('payload', payload)
            if(payload.status !== 'waiting_approval') {
                state.pendings = state.pendings.filter(pending => pending.id != payload.id)
            }
        },
        [updateSource.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [updateStatus.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [updateStatus.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            console.log('payload', payload)
            if(payload.status !== 'waiting_approval') {
                state.pendings = state.pendings.filter(pending => pending.id != payload.id)
            }
        },
        [updateStatus.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [deleteSource.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [deleteSource.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            console.log('payload', payload)
            state.pendings = state.pendings.filter(item => item.id !== payload.id)
            state.approveds = state.approveds.filter(item => item.id !== payload.id)
            state.rejecteds = state.rejecteds.filter(item => item.id !== payload.id)
        },
        [deleteSource.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        }
    }
})

export const { resetState, setSelectedEarning, removeSelectedEarning, addToCroppeds, removeFromCroppeds, resetEarnings, getLastApproveds} = sourceSlice.actions

export default sourceSlice.reducer