import React, {useEffect, useMemo, useState} from 'react'
import {Button, Form, InputGroup, Spinner} from 'react-bootstrap'
import DashboardLayout from '../../components/layouts/DashboardLayout'
import {AiFillEdit} from 'react-icons/ai'
import {BsTrashFill} from 'react-icons/bs'
import {IoMdRefresh} from 'react-icons/io'
import DataTable from 'react-data-table-component'
import {useSelector, useDispatch} from 'react-redux'
import {deleteSection, getSections, getParentSections} from '../../slices/sections/sectionActions'
import { logout } from '../../slices/auth/authActions'
import {toast} from 'react-toastify'
import {resetState} from '../../slices/sections/sectionSlice'
import CreateSectionModal from '../../components/editor/CreateSectionModal'
import ConfirmationModal from '../../components/common/ConfirmationModal'
import EditSectionModal from '../../components/editor/EditSectionModal'


const FilterComponent = ({ searchText, onSearch, onClear, sectionList, onFilter }) => (
    <>
        <div className='d-flex justify-content-center align-content-center w-100'>
            <div className='w-50 p-3'>
                <Form.Select
                    className='form-control'
                    onChange={onFilter}>
                    <option value="">Filtrele...</option>
                    {sectionList?.map(section => (
                            <option key={section.id} value={section.id}>{section.title}</option>
                        ))
                    }
                </Form.Select>
            </div>
            <div className='w-50 p-3'>
                <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="Ara..."
                        aria-label="Ara..."
                        aria-describedby="basic-addon2"
                        value={searchText}
                        onChange={onSearch}/>
                    <Button variant="outline-secondary" id="button-addon2" onClick={onClear}>
                        X
                    </Button>
                </InputGroup>
            </div>
        </div>
    </>
)

const paginationComponentOptions = {
    rowsPerPageText: 'Sayfa Başına Satır',
    rangeSeparatorText: '-',
}

const Sections = () => {
    const { loading, sections, error, errorCode, success, parents } = useSelector((state) => state.section)
    const { token, user } = useSelector((state) => state.auth)
    const dispatch = useDispatch()

    const [showCreate, setCreateShow] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [activeSection, setActiveSection] = useState(null)
    const [filterText, setFilterText] = useState('')
    const [searchText, setSearchText] = useState('')
    const [filteredData, setFilteredData] = useState([])
    const [orgData, setOrgData] = useState([])



    useEffect(() => {
        dispatch(getSections({ token }))
    }, [])

    useEffect(() => {
        if(searchText) {
            const filteredSections = sections.filter(item => item.title && (item.title.toLowerCase().includes(searchText.toLowerCase()) || item.parentFullTitle.toLowerCase().includes(searchText.toLowerCase())))
            setFilteredData(filteredSections)
        }
    }, [searchText])

    useEffect(() => {
        console.log('filterText', filterText)
        if(filterText) {
            const filteredSections = filteredData.filter(item => item.parentId == filterText)
            setFilteredData(filteredSections)
        }
    }, [filterText])

    useEffect(() => {
        if(sections) {
            setFilteredData(sections)
            setOrgData(sections)
        }
    }, [sections])

    useEffect(() => {
        if(success) {
            toast('İşlem başarılı!', { type: 'success' })
        }
    }, [success])

    useEffect(() => {
        if(error) {
            if(Array.isArray(error.error) || typeof error.error === 'object') {
                Object.keys(error.error).map((err, index) => (
                    console.log(error.error[err][0])
                    // toast(error.error[err][0], { type: 'error' })
                ))
            } else {
                console.log('Error: ', error.error)
                toast(error.error, { type: 'error' })
            }
        }
    }, [error])

    useEffect(() => {
        if(errorCode) {
            console.log('Code', errorCode)
            if(errorCode === 401) {
                dispatch(logout({token}))
            }
        }
    }, [errorCode])

    const handleConfirmModal = (data) => {
        console.log(data)
        setActiveSection(data)
        setShowConfirm(true)
    }

    const handleDelete = async () => {
        await dispatch(deleteSection({ token, id: activeSection.id }))
    }

    const handleEditModal = (data) => {
        console.log(data)
        setActiveSection(data)
        setShowEdit(true)
    }

    const handleCreateModal = () => {
        setCreateShow(true)
    }

    const handleCloseModal = () => {
        setCreateShow(false)
        setShowConfirm(false)
        setShowEdit(false)
        setActiveSection(null)
        dispatch(resetState())
    }

    const handleRefresh = async () => {
        dispatch(getSections({token}))
    }


    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if(searchText) {
                setSearchText('')
            }
        }
        return (
            <FilterComponent
                onFilter={e => {
                    setFilterText(e.target.value)
                    setFilteredData(orgData)
                }}
                onSearch={e => setSearchText(e.target.value)} onClear={handleClear} searchText={searchText} sectionList={sections} />
        )
    }, [searchText])

    const columns = [
        {
            name: 'Başlık',
            selector: row =>row.title,
            sortable: true
        },
        {
            name: 'Üst Bölüm',
            selector: row =>(
                row.parentId > 0 ? row.parentFullTitle : '-'),
            sortable: true
        },
        {
            name: 'İşlemler',
            cell: row => (
                <>
                    <button className="btn btn-falcon-danger me-1 mb-1" type="button" onClick={() => handleConfirmModal(row)}>
                        <BsTrashFill size={20} color='red' />
                    </button>
                    <button className="btn btn-falcon-warning me-1 mb-1" type="button" onClick={() => handleEditModal(row)}>
                        <AiFillEdit size={20} color='blue' />
                    </button>
                </>
            )
        }
    ]

    return (
        <DashboardLayout>
            <div className="card mb-3">
                <div className="card-header">
                    <div className="row flex-between-end">
                        <div className="col-auto align-self-center">
                            <h5 className="mb-0" data-anchor="data-anchor">Bölüm Listesi</h5>
                        </div>
                        <div className="col-auto ms-auto">
                            <button className="btn btn-falcon-default btn-sm me-2" type="button" role="button" onClick={handleCreateModal}>
                                <span className="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
                                <span className="d-none d-sm-inline-block ms-1">Yeni</span>
                            </button>
                            <button className="btn btn-falcon-default btn-sm" type="button" role="button" onClick={handleRefresh}>
                                <IoMdRefresh size={20} color='blue' />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <div id="tableExample2">
                        <div className="table-responsive scrollbar">
                            <DataTable
                                columns={columns}
                                data={filteredData}
                                pagination
                                striped
                                subHeader
                                subHeaderComponent={subHeaderComponentMemo}
                                progressPending={loading}
                                paginationComponentOptions={paginationComponentOptions}
                                progressComponent={<Spinner animation="grow" variant="primary" />}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <CreateSectionModal show={showCreate} handleClose={handleCloseModal} />
            {activeSection ? <EditSectionModal show={showEdit} handleClose={handleCloseModal} activeSection={activeSection} /> : ''}
            <ConfirmationModal show={showConfirm} handleClose={handleCloseModal} handleConfirm={handleDelete} header='Bölüm Sil' />
        </DashboardLayout>
    )
}

export default Sections