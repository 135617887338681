import { createSlice } from '@reduxjs/toolkit'
import {
    getWriters,
    saveWriter,
    deleteWriter,
    updateWriter,
    getSubjectsWithEarnings,
    activeWritersCount,
    allWritersCount,
    questionCountByStatus,
    questionByLimit,
} from './writerActions'


const initialState = {
    loading: false,
    writers: [],
    active: 0,
    all: 0,
    pendings: 0,
    approveds: 0,
    rejecteds: 0,
    lastApproveds: [],
    lastRejecteds: [],
    subject: [],
    writer: null,
    error: null,
    errorCode: null,
    success: false,
}

export const writerSlice = createSlice({
    name: 'writer',
    initialState,
    reducers: {
        resetState(state) {
            state.loading = false
            state.error = null
            state.errorCode = null
            state.success = false
        }
    },
    extraReducers: {
        [getWriters.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getWriters.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.writers = payload
        },
        [getWriters.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [saveWriter.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [saveWriter.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            state.writers.unshift(payload)
            state.writer = payload
        },
        [saveWriter.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload.error
            state.errorCode = payload.code
        },
        [updateWriter.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [updateWriter.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            const index = state.writers.findIndex(item => item.id === payload.id)
            state.writers[index] = payload
            state.writer = payload
        },
        [updateWriter.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload.error
            state.errorCode = payload.code
        },
        [deleteWriter.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [deleteWriter.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            state.writers = state.writers.filter(writer => writer.id !== payload.id)
        },
        [deleteWriter.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload.error
            state.errorCode = payload.code
        },
        [getSubjectsWithEarnings.pending]: (state) => {
            state.subjects = []
            state.loading = true
            state.error = null
        },
        [getSubjectsWithEarnings.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.subjects = payload
        },
        [getSubjectsWithEarnings.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [activeWritersCount.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [activeWritersCount.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.active = payload
        },
        [activeWritersCount.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [allWritersCount.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [allWritersCount.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.all = payload
        },
        [allWritersCount.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [questionCountByStatus.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [questionCountByStatus.fulfilled]: (state, { payload }) => {
            state.loading = false
            switch (payload.status) {
                case 'waiting_approval':
                    state.pendings = payload.response
                    break
                case 'approval':
                    state.approveds = payload.response
                    break
                case 'rejected':
                    state.rejecteds = payload.response
                    break
            }
        },
        [questionCountByStatus.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload.response
        },
        [questionByLimit.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload.response
        },
        [questionByLimit.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [questionByLimit.fulfilled]: (state, { payload }) => {
            state.loading = false
            switch (payload.status) {
                case 'approval':
                    state.lastApproveds = payload.response
                    break
                case 'rejected':
                    state.lastRejecteds = payload.response
                    break
            }
        },
    }
})

export const { resetState } = writerSlice.actions

export default writerSlice.reducer