import React, {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'

const NavBar = () => {
    const location = useLocation()
    const [url, setUrl] = useState(null)

    useEffect(() => {
        setUrl(location.pathname)
    }, [location])

    return (
        <nav className="navbar navbar-light navbar-vertical navbar-expand-xl">
            <div className="d-flex align-items-center">
                <div className="toggle-icon-wrapper">
                    <button className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                            data-bs-toggle="tooltip" data-bs-placement="left" title="Toggle Navigation"><span
                        className="navbar-toggle-icon"><span className="toggle-line"></span></span></button>
                </div>
                <a className="navbar-brand" href="#">
                    <div className="d-flex align-items-center py-3">
                        <img src="./img/logos/logo_corporate-colored2x.png" alt="" width={'80%'}/>
                    </div>
                </a>
            </div>
            <div className="collapse navbar-collapse" id="navbarVerticalCollapse">
                <div className="navbar-vertical-content scrollbar">
                    <ul className="navbar-nav flex-column mb-3" id="navbarVerticalNav">
                        <li className="nav-item">
                            <Link className={'nav-link' + (url === '/editor/panel' ? ' active' : '')} to="/editor/panel" role="button" data-bs-toggle="" aria-expanded="false">
                                <div className="d-flex align-items-center">
                                    <span className="nav-link-icon">
                                        <span className="fas fa-home"></span>
                                    </span>
                                    <span className="nav-link-text ps-1">Anasayfa</span>
                                </div>
                            </Link>
                        </li>
                        <div className="row navbar-vertical-label-wrapper mt-3 mb-2">
                            <div className="col-auto navbar-vertical-label">Personel</div>
                            <div className="col ps-0">
                                <hr className="mb-0 navbar-vertical-divider"/>
                            </div>
                        </div>
                        <li className="nav-item">
                            <Link className={'nav-link' + (url === '/editor/yazar' ? ' active' : '')} to="/editor/yazar" role="button" data-bs-toggle="" aria-expanded="false">
                                <div className="d-flex align-items-center">
                                    <span className="nav-link-icon">
                                        <span className="fas fa-user-edit"></span>
                                    </span>
                                    <span className="nav-link-text ps-1">Yazarlar</span>
                                </div>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <div className="row navbar-vertical-label-wrapper mt-3 mb-2">
                                <div className="col-auto navbar-vertical-label">Gruplandırma</div>
                                <div className="col ps-0">
                                    <hr className="mb-0 navbar-vertical-divider"/>
                                </div>
                            </div>
                            <a className="nav-link dropdown-indicator" href="#dashboard" role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="dashboard">
                                <div className="d-flex align-items-center">
                                    <span className="nav-link-icon">
                                        <span className="fas fa-layer-group"></span>
                                    </span>
                                    <span className="nav-link-text ps-1">Gruplar</span>
                                </div>
                            </a>
                            <ul className={'nav' + (url === '/editor/bolum' || url === '/editor/ders' || url === '/editor/unite' || url === '/editor/konu' ? ' collapsed' : ' collapse')} id="dashboard">
                                <li className="nav-item">
                                    <Link className={'nav-link' + (url === '/editor/bolum' ? ' active' : '')} to="/editor/bolum" role="button" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-text ps-1">Bölüm</span>
                                        </div>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={'nav-link' + (url === '/editor/ders' ? ' active' : '')} to="/editor/ders" role="button" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-text ps-1">Ders</span>
                                        </div>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={'nav-link' + (url === '/editor/unite' ? ' active' : '')} to="/editor/unite" role="button" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-text ps-1">Ünite</span>
                                        </div>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={'nav-link' + (url === '/editor/konu' ? ' active' : '')} to="/editor/konu" role="button" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-text ps-1">Konu</span>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <div className="row navbar-vertical-label-wrapper mt-3 mb-2">
                                <div className="col-auto navbar-vertical-label">Soru Grupları</div>
                                <div className="col ps-0">
                                    <hr className="mb-0 navbar-vertical-divider"/>
                                </div>
                            </div>
                            <a className="nav-link dropdown-indicator" href="#question" role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="question">
                                <div className="d-flex align-items-center">
                                    <span className="nav-link-icon">
                                        <span className="fas fa-question-circle"></span>
                                    </span>
                                    <span className="nav-link-text ps-1">Sorular</span>
                                </div>
                            </a>
                            <ul className={'nav' + (url === '/editor/soru/bekleyen' || url === '/editor/soru/onaylanan' || url === '/editor/soru/reddedilen' ? ' collapsed' : ' collapse')} id="question">
                                <li className="nav-item">
                                    <Link className={'nav-link' + (url === '/editor/soru/bekleyen' ? ' active' : '')} to="/editor/soru/bekleyen" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-text ps-1">Bekleyen</span>
                                        </div>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={'nav-link' + (url === '/editor/soru/onaylanan' ? ' active' : '')} to="/editor/soru/onaylanan" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-text ps-1">Onaylanan</span>
                                        </div>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={'nav-link' + (url === '/editor/soru/reddedilen' ? ' active' : '')} to="/editor/soru/reddedilen" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-text ps-1">Reddedilen</span>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default NavBar