import React from 'react'

const NavBar = () => {
    return (
        <nav className="navbar navbar-light navbar-vertical navbar-expand-xl">
            <div className="d-flex align-items-center">
                <div className="toggle-icon-wrapper">
                    <button className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                            data-bs-toggle="tooltip" data-bs-placement="left" title="Toggle Navigation"><span
                        className="navbar-toggle-icon"><span className="toggle-line"></span></span></button>
                </div>
                <a className="navbar-brand" href="#">
                    <div className="d-flex align-items-center py-3">
                        <img src="./img/logos/logo_corporate-colored2x.png" alt="" width={'80%'}/>
                    </div>
                </a>
            </div>
            <div className="collapse navbar-collapse" id="navbarVerticalCollapse">
                <div className="navbar-vertical-content scrollbar">
                    <ul className="navbar-nav flex-column mb-3" id="navbarVerticalNav">
                        <li className="nav-item">
                            <a className="nav-link" href="../app/calendar.html" role="button" data-bs-toggle="" aria-expanded="false">
                                <div className="d-flex align-items-center">
                                    <span className="nav-link-icon">
                                        <span className="fas fa-home"></span>
                                    </span>
                                    <span className="nav-link-text ps-1">Anasayfa</span>
                                </div>
                            </a>
                        </li>
                        <div className="row navbar-vertical-label-wrapper mt-3 mb-2">
                            <div className="col-auto navbar-vertical-label">Personel</div>
                            <div className="col ps-0">
                                <hr className="mb-0 navbar-vertical-divider"/>
                            </div>
                        </div>
                        <li className="nav-item">
                            <a className="nav-link" href="../app/calendar.html" role="button" data-bs-toggle="" aria-expanded="false">
                                <div className="d-flex align-items-center">
                                    <span className="nav-link-icon">
                                        <span className="fas fa-eraser"></span>
                                    </span>
                                    <span className="nav-link-text ps-1">Editörler</span>
                                </div>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="../app/calendar.html" role="button" data-bs-toggle="" aria-expanded="false">
                                <div className="d-flex align-items-center">
                                    <span className="nav-link-icon">
                                        <span className="fas fa-user-edit"></span>
                                    </span>
                                    <span className="nav-link-text ps-1">Yazarlar</span>
                                </div>
                            </a>
                        </li>
                        <li className="nav-item">
                            <div className="row navbar-vertical-label-wrapper mt-3 mb-2">
                                <div className="col-auto navbar-vertical-label">Gruplandırma</div>
                                <div className="col ps-0">
                                    <hr className="mb-0 navbar-vertical-divider"/>
                                </div>
                            </div>
                            <a className="nav-link dropdown-indicator" href="#dashboard" role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="dashboard">
                                <div className="d-flex align-items-center">
                                    <span className="nav-link-icon">
                                        <span className="fas fa-layer-group"></span>
                                    </span>
                                    <span className="nav-link-text ps-1">Gruplar</span>
                                </div>
                            </a>
                            <ul className="nav collapse" id="dashboard">
                                <li className="nav-item">
                                    <a className="nav-link" href="../index-2.html" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-text ps-1">Bölüm</span>
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="../dashboard/analytics.html" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-text ps-1">Ders</span>
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="../dashboard/crm.html" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-text ps-1">Ünite</span>
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="../dashboard/e-commerce.html" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-text ps-1">Konu</span>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <div className="row navbar-vertical-label-wrapper mt-3 mb-2">
                                <div className="col-auto navbar-vertical-label">Soru Araçları</div>
                                <div className="col ps-0">
                                    <hr className="mb-0 navbar-vertical-divider"/>
                                </div>
                            </div>
                            <a className="nav-link dropdown-indicator" href="#email" role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="email">
                                <div className="d-flex align-items-center">
                                    <span className="nav-link-icon">
                                        <span className="fas fa-toolbox"></span>
                                    </span>
                                    <span className="nav-link-text ps-1">Araçlar</span>
                                </div>
                            </a>
                            <ul className="nav collapse" id="email">
                                <li className="nav-item">
                                    <a className="nav-link" href="../app/email/inbox.html" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-text ps-1">Soru Oluşturma</span>
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="../app/email/email-detail.html" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-text ps-1">Kitap Kaynak Seçimi</span>
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="../app/email/compose.html" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-text ps-1">Kaynak Videolar</span>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                            <div className="row navbar-vertical-label-wrapper mt-3 mb-2">
                                <div className="col-auto navbar-vertical-label">Soru Grupları</div>
                                <div className="col ps-0">
                                    <hr className="mb-0 navbar-vertical-divider"/>
                                </div>
                            </div>
                            <a className="nav-link dropdown-indicator" href="#question" role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="question">
                                <div className="d-flex align-items-center">
                                    <span className="nav-link-icon">
                                        <span className="fas fa-question-circle"></span>
                                    </span>
                                    <span className="nav-link-text ps-1">Sorular</span>
                                </div>
                            </a>
                            <ul className="nav collapse" id="question">
                                <li className="nav-item">
                                    <a className="nav-link" href="../app/email/inbox.html" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-text ps-1">Onay Bekleyen</span>
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="../app/email/email-detail.html" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-text ps-1">Onaylanan</span>
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="../app/email/compose.html" data-bs-toggle="" aria-expanded="false">
                                        <div className="d-flex align-items-center">
                                            <span className="nav-link-text ps-1">Reddedilmiş</span>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default NavBar