import {createSlice} from '@reduxjs/toolkit'
import {getTypes} from './typeActions'

const initialState = {
    loading: false,
    types: [],
    type: null,
    error: null,
    errorCode: null,
    success: false
}

export const typeSlicer = createSlice({
    name: 'type',
    initialState,
    reducers: {

    },
    extraReducers: {
        [getTypes.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getTypes.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.types = payload
        },
        [getTypes.rejected]: (state, {payload}) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
    }
})

export default typeSlicer.reducer