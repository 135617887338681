import React from 'react'
import AuthLayout from "../../components/layouts/AuthLayout"
import {Link} from "react-router-dom";

const Register = () => {
    return (
        <AuthLayout>
            <div className="row min-vh-100 flex-center g-0">
                <div className="col-lg-8 col-xxl-5 py-3 position-relative">
                    <img className="bg-auth-circle-shape" src="./img/icons/spot-illustrations/bg-shape.png" alt="" width="250"/>
                    <img className="bg-auth-circle-shape-2" src="./img/icons/spot-illustrations/shape-1.png" alt="" width="150"/>
                    <div className="card overflow-hidden z-index-1">
                        <div className="card-body p-0">
                            <div className="row g-0 h-100">
                                <div className="col-md-5 text-center bg-card-gradient">
                                    <div className="position-relative p-4 pt-md-5 pb-md-7 light">
                                        <div className="bg-holder bg-auth-card-shape" style={{ backgroundImage: "url(./img/icons/spot-illustrations/half-circle.png)" }}></div>
                                        <div className="z-index-1 position-relative">
                                            <a className="link-light mb-4 font-sans-serif fs-4 d-inline-block fw-bolder" href="#">
                                                <img src="./img/logos/logo_corporate-colored2x.png" alt="" width={'80%'}/>
                                            </a>
                                            <p className="opacity-75 text-white">
                                                Merhaba, VIP Kurum'a hoşgeldiniz.
                                                Yandaki alanlara bilgilerinizi girerek kayıt olabilirsiniz.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-3 mb-4 mt-md-4 mb-md-5 light">
                                        <p className="pt-3 text-white">Kayıtlı mısınız?<br/>
                                            <Link to='/' className="btn btn-outline-light mt-2 px-4">
                                                Giriş Yap
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-7 d-flex flex-center">
                                    <div className="p-4 p-md-5 flex-grow-1">
                                        <h3>Kaydol</h3>
                                        <form>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="card-name">Ad-Soyad</label>
                                                <input className="form-control" type="text" autoComplete="on" id="card-name"/>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="card-email">E-Posta Adresi</label>
                                                <input className="form-control" type="email" autoComplete="on" id="card-email"/>
                                            </div>
                                            <div className="row gx-2">
                                                <div className="mb-3 col-sm-6">
                                                    <label className="form-label" htmlFor="card-password">Şifre</label>
                                                    <input className="form-control" type="password" autoComplete="on" id="card-password"/>
                                                </div>
                                                <div className="mb-3 col-sm-6">
                                                    <label className="form-label" htmlFor="card-confirm-password">Şifreyi Tekrarla</label>
                                                    <input className="form-control" type="password" autoComplete="on" id="card-confirm-password"/>
                                                </div>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="card-register-checkbox"/>
                                                <label className="form-label" htmlFor="card-register-checkbox"><a href="#!">Şartları</a> ve
                                                    <a href="#!"> gizlilik politikasını</a> kabul ediyorum.
                                                </label>
                                            </div>
                                            <div className="mb-3">
                                                <button className="btn btn-primary d-block w-100 mt-3" type="submit" name="submit">Kaydol</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AuthLayout>
    )
}

export default Register