import React, {useEffect, useMemo, useState} from 'react'
import {Button, Form, InputGroup, Spinner} from 'react-bootstrap'
import DashboardLayout from '../../components/layouts/DashboardLayout'
import {AiFillEdit} from 'react-icons/ai'
import {BsTrashFill} from 'react-icons/bs'
import {IoMdRefresh} from 'react-icons/io'
import DataTable from 'react-data-table-component'
import {useSelector, useDispatch} from 'react-redux'
import {getSubjects, deleteSubject} from '../../slices/subjects/subjectActions'
import {toast, ToastContainer} from 'react-toastify'
import {resetState} from '../../slices/subjects/subjectSlice'
import CreateSubjectModal from '../../components/editor/CreateSubjectModal'
import ConfirmationModal from '../../components/common/ConfirmationModal'
import EditSubjectModal from '../../components/editor/EditSubjectModal'
import {getLessons} from '../../slices/lessons/lessonActions'
import {logout} from '../../slices/auth/authActions'

const FilterComponent = ({ searchText, onSearch, onClear, lessonList, onFilter }) => (
    <>
        <div className='d-flex justify-content-center align-content-center w-100'>
            <div className='w-50 p-3'>
                <Form.Select
                    className='form-control'
                    onChange={onFilter}>
                    <option value="0">Filtrele...</option>
                    {lessonList?.map(lesson => (
                        <option key={lesson.id} value={lesson.id}>{lesson.title}</option>
                    ))
                    }
                </Form.Select>
            </div>
            <div className='w-50 p-3'>
                <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="Ara..."
                        aria-label="Ara..."
                        aria-describedby="basic-addon2"
                        value={searchText}
                        onChange={onSearch}/>
                    <Button variant="outline-secondary" id="button-addon2" onClick={onClear}>
                        X
                    </Button>
                </InputGroup>
            </div>
        </div>
    </>
)

const paginationComponentOptions = {
    rowsPerPageText: 'Sayfa Başına Satır',
    rangeSeparatorText: '-',
}

const Subjects = () => {
    const {lessons} = useSelector((state) => state.lesson)
    const { units } = useSelector((state) => state.unit)
    const { loading, subjects, error, success, errorCode } = useSelector((state) => state.subject)
    const { token, user } = useSelector((state) => state.auth)
    const dispatch = useDispatch()

    const [showCreate, setCreateShow] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [activeSubject, setActiveSubject] = useState(null)
    const [filterText, setFilterText] = useState(0)
    const [searchText, setSearchText] = useState('')
    const [filteredData, setFilteredData] = useState([])
    const [orgData, setOrgData] = useState([])


    useEffect(() => {
        dispatch(getSubjects({token}))
        dispatch(getLessons({token}))
    }, [])

    useEffect(() => {
        if(success) {
            toast('İşlem başarılı!', { type: 'success' })
        }
    }, [success])

    useEffect(() => {
        if(searchText) {
            const filteredSubjects = subjects.filter(item => item.title && (item.title.toLowerCase().includes(searchText.toLowerCase()) || item.parent.title.toLowerCase().includes(searchText.toLowerCase())))
            setFilteredData(filteredSubjects)
        }
    }, [searchText])

    useEffect(() => {
        if(filterText > 0) {
            const filteredSubjects = subjects.filter(subject => subject.lesson.id == filterText)
            setFilteredData(filteredSubjects)
        } else {
            setFilterText(0)
            setFilteredData(orgData)
        }

    }, [filterText])

    useEffect(() => {
        if(subjects) {
            setFilteredData(subjects)
            setOrgData(subjects)
        }
    }, [subjects])

    useEffect(() => {
        if(error) {
            if(Array.isArray(error.error) || typeof error.error === 'object') {
                Object.keys(error.error).map((err, index) => (
                    console.log(error.error[err][0])
                    // toast(error.error[err][0], { type: 'error' })
                ))
            } else {
                console.log('Error: ', error.error)
                toast(error.error, { type: 'error' })
            }
        }
    }, [error])

    useEffect(() => {
        if(errorCode) {
            console.log('Code', errorCode)
            if(errorCode === 401) {
                dispatch(logout({token}))
            }
        }
    }, [errorCode])

    const handleConfirmModal = (data) => {
        setActiveSubject(data)
        setShowConfirm(true)
    }

    const handleDelete = async () => {
        await dispatch(deleteSubject({ token, id: activeSubject.id }))
    }

    const handleEditModal = (data) => {
        setActiveSubject(data)
        setShowEdit(true)
    }

    const handleCreateModal = () => {
        setCreateShow(true)
    }

    const handleCloseModal = () => {
        setCreateShow(false)
        setShowConfirm(false)
        setShowEdit(false)
        setActiveSubject(null)
        dispatch(resetState())
    }

    const handleRefresh = async () => {
        dispatch(getSubjects(token))
    }


    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if(searchText) {
                setSearchText('')
                setFilteredData(orgData)
            }
        }
        return (
            <FilterComponent
                onFilter={e => {
                    console.log('e', e.target.value)
                    setFilterText(e.target.value)
                    // setFilteredData(orgData)
                }}
                onSearch={e => setSearchText(e.target.value)} onClear={handleClear} searchText={searchText} lessonList={lessons} />
        )
    }, [searchText, lessons])

    const columns = [
        {
            name: 'Konu',
            selector: row =>row.title,
            sortable: true
        },
        {
            name: 'Ünite',
            selector: row => row.parent.title,
            sortable: true
        },
        {
            name: 'Ders',
            selector: row => row.lesson.title,
            sortable: true
        },
        {
            name: 'İşlemler',
            cell: row => (
                <>
                    <button className="btn btn-falcon-danger me-1 mb-1" type="button" onClick={() => handleConfirmModal(row)}>
                        <BsTrashFill size={20} color='red' />
                    </button>
                    <button className="btn btn-falcon-warning me-1 mb-1" type="button" onClick={() => handleEditModal(row)}>
                        <AiFillEdit size={20} color='blue' />
                    </button>
                </>
            )
        }
    ]

    return (
        <DashboardLayout>
            <div className="card mb-3">
                <div className="card-header">
                    <div className="row flex-between-end">
                        <div className="col-auto align-self-center">
                            <h5 className="mb-0" data-anchor="data-anchor">Konu Listesi</h5>
                        </div>
                        <div className="col-auto ms-auto">
                            <button className="btn btn-falcon-default btn-sm me-2" type="button" role="button" onClick={handleCreateModal}>
                                <span className="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
                                <span className="d-none d-sm-inline-block ms-1">Yeni</span>
                            </button>
                            <button className="btn btn-falcon-default btn-sm" type="button" role="button" onClick={handleRefresh}>
                                <IoMdRefresh size={20} color='blue' />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <div id="tableExample2">
                        <div className="table-responsive scrollbar">
                            <DataTable
                                columns={columns}
                                data={filteredData}
                                pagination
                                striped
                                subHeader
                                subHeaderComponent={subHeaderComponentMemo}
                                progressPending={loading}
                                paginationComponentOptions={paginationComponentOptions}
                                progressComponent={<Spinner animation="grow" variant="primary" />}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <CreateSubjectModal show={showCreate} handleClose={handleCloseModal} />
            {activeSubject ? <EditSubjectModal show={showEdit} handleClose={handleCloseModal} activeSubject={activeSubject} /> : '' }
            <ConfirmationModal show={showConfirm} handleClose={handleCloseModal} handleConfirm={handleDelete} header='Konu Sil' />

            <ToastContainer/>
        </DashboardLayout>
    )
}

export default Subjects