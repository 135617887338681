import { createSlice } from '@reduxjs/toolkit'
import { getSubjects, saveSubject, deleteSubject, updateSubject } from './subjectActions'


const initialState = {
    loading: false,
    subjects: [],
    subject: null,
    error: null,
    errorCode: null,
    success: false,
}

export const subjectSlice = createSlice({
    name: 'subject',
    initialState,
    reducers: {
        resetState(state) {
            state.loading = false
            state.error = null
            state.errorCode = null
            state.success = false
        }
    },
    extraReducers: {
        [getSubjects.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getSubjects.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.subjects = payload
        },
        [getSubjects.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [saveSubject.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [saveSubject.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            state.subjects.unshift(payload)
            state.subject = payload
        },
        [saveSubject.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload.error
            state.errorCode = payload.code
        },
        [updateSubject.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [updateSubject.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            const index = state.subjects.findIndex(item => item.id === payload.id)
            state.subjects[index] = payload
            state.subject = payload
        },
        [updateSubject.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload.error
            state.errorCode = payload.code
        },
        [deleteSubject.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [deleteSubject.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            state.subjects = state.subjects.filter(subject => subject.id !== payload.id)
        },
        [deleteSubject.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload.error
            state.errorCode = payload.code
        },
    }
})

export const { resetState } = subjectSlice.actions

export default subjectSlice.reducer