import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import {Form, Button, Spinner} from 'react-bootstrap'
import {useForm, Controller} from 'react-hook-form'
import Select from 'react-select'
import {getLessons} from '../../slices/lessons/lessonActions'
import {useNavigate} from 'react-router-dom'
import {saveUnit} from '../../slices/units/unitActions'
import {getSections} from '../../slices/sections/sectionActions'

const CreateUnitModal = ({ show, handleClose }) => {

    const unitSchema = yup.object().shape({
        title: yup
            .string()
            .matches(/^[a-zA-ZışçğüöİŞÇĞÜÖ\s\.\-()0-9',:]{2,}$/, 'Geçerli bir ünite adı giriniz!')
            .required('Ünite adı gerekli!'),
        lesson: yup
            .string()
            .required('Ders bilgisi gerekli!'),
        unitSections: yup
            .array()
            .min(1, 'En az 1 bölüm seçmelisiniz!'),
    })
    const {clearErrors, reset, register, handleSubmit, control, setValue, watch, formState: {isValid, isSubmitted, errors}} = useForm({
        resolver: yupResolver(unitSchema),
        defaultValues: {
            title: '',
            lesson: {
                label: '', value: ''
            },
            unitSections: [{
                label: '', value: ''
            }]
        }
    })
    const {token} = useSelector((state) => state.auth)
    const {lessons} = useSelector((state) => state.lesson)
    const {sections} = useSelector((state) => state.section)
    const {loading, error, success, units, unit, errorCode} = useSelector((state) => state.unit)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        const clearForm = () => {
            clearErrors()
            reset({
                title: '',
                lesson: {
                  value: '', label: ''
                },
                unitSections: {
                    value: '', label: ''
                }
            })
            /*setValue('title', '')
            setValue('lesson', null)
            setValue('unitSections', [])*/
            console.log('cleared', show)
        }
        if(!show) {
            console.log('show', show)
            clearForm()
        } else {
            clearForm()
            if(lessons.length < 1) {
                dispatch(getLessons(token))
            }
            if(sections.length < 1) {
                dispatch(getSections(token))
            }
        }
    }, [show])

    useEffect(() => {
        if(error) {
            if(Array.isArray(error) || typeof error === 'object') {
                Object.keys(error).map((item, index) => (
                    toast(error[item][0], { type: 'error' })
                ))
            } else {
                toast(error, { type: 'error' })
            }

        }
    }, [error])

    useEffect(() => {
        if(success) {
            handleClose()
        }
    }, [success])

    useEffect(() => {
        if(errorCode === 401) {
            navigate('/')
        }
    }, [errorCode])

    const submitForm = async (data) => {
        console.log(data)
        await dispatch(saveUnit({
            token,
            unit: {
                'lesson_id': data.lesson,
                'section_ids': data.unitSections,
                'title': data.title
            }
        }))
    }

    const handleLessonChange = (selected) => {
        setValue('lesson', selected.value)
    }

    const lessonList = lessons?.map(lesson => (
        {value: lesson.id, label: lesson.title}
    ))

    const handleSectionChange = (selected) => {
        setValue('unitSections', Array.isArray(selected) ? selected.map(x => x.value) : [])
    }

    const sectionList = sections?.map(section => (
        {value: section.id, label: section.fullSectionTitle}
    ))

    return (
        <>
            <div className={ 'modal fade' + (show ? 'show' : '')} id="error-modal" tabIndex="-1" role="dialog" aria-hidden="true" style={{ display: show ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content position-relative">
                        <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                            <button
                                onClick={handleClose}
                                className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-0">
                            <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                                <h4 className="mb-1" id="modalExampleDemoLabel">Ünite Ekle </h4>
                            </div>
                            <div className="p-4 pb-0">
                                <Form noValidate onSubmit={handleSubmit(submitForm)} className="row g-3">
                                    <div className="col-md-12 mb-1">
                                        <label className="col-form-label" htmlFor="lesson-section">Bölüm:</label>
                                        <Controller
                                            name='unitSections'
                                            control={control}
                                            render={({ onChange, value, ref }) => (
                                                <>
                                                    <Select
                                                        id='unit-sections'
                                                        className={'form-control' + (errors.unitSections ? ' is-invalid' : isSubmitted ? ' is-valid' : '')}
                                                        isMulti
                                                        isSearchable
                                                        placeholder='Üniteyle İlişkili Bölüm veya Bölümleri Seçin...'
                                                        options={sectionList}
                                                        onChange={handleSectionChange}
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.unitSections ? errors.unitSections.message : ''}
                                                    </Form.Control.Feedback>
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="col-md-12 mb-1">
                                        <label className="col-form-label" htmlFor="unit-lesson">Ders:</label>
                                        <Controller
                                            name='lesson'
                                            control={control}
                                            render={({ onChange, value, ref }) => (
                                                <>
                                                    <Select
                                                        id='unit-lesson'
                                                        className={'form-control' + (errors.lesson ? ' is-invalid' : isSubmitted ? ' is-valid' : '')}
                                                        isSearchable
                                                        placeholder='Üniteyle İlişkili Dersi Seçin...'
                                                        options={lessonList}
                                                        onChange={handleLessonChange}
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.lesson ? errors.lesson.message : ''}
                                                    </Form.Control.Feedback>
                                                </>
                                            )}
                                        />
                                    </div>

                                    <div className="col-md-12 mb-1">
                                        <label className="col-form-label" htmlFor="unit-title">Ünite Adı:</label>
                                        <Form.Control
                                            className="form-control"
                                            id="unit-title"
                                            type="text"
                                            placeholder='Ünitenin Adını Yazın...'
                                            isValid={isSubmitted && !errors.title}
                                            isInvalid={isSubmitted && !!errors.title}
                                            {...register('title')}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.title ? errors.title.message : ''}
                                        </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                        <Button
                                            className="btn btn-success"
                                            type="submit"
                                            name='submit'
                                            disabled={loading}>
                                            {loading ?
                                                <>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    /> Bekleyin...
                                                </>
                                                : 'Kaydet'
                                            }
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button
                                onClick={handleClose}
                                className="btn btn-secondary" type="button" data-bs-dismiss="modal">Kapat</Button>
                        </div>
                    </div>
                </div>
            </div>
            {
                show && (
                    <div className="modal-backdrop fade show"></div>
                )
            }
            <ToastContainer/>
        </>
    )
}

export default CreateUnitModal