import React, {useEffect, useMemo, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import {Form, Button, Spinner} from 'react-bootstrap'
import {useForm, Controller} from 'react-hook-form'
import Select from 'react-select'
import { getSections } from '../../slices/sections/sectionActions'
import { updateLesson } from '../../slices/lessons/lessonActions'
import {useNavigate} from 'react-router-dom'

const EditLessonModal = ({ show, handleClose, activeLesson }) => {
    const lessonSchema = yup.object().shape({
        title: yup
            .string()
            .matches(/^[a-zA-ZışçğüöİŞÇĞÜÖ\s\.\-()0-9',:]{2,}$/, 'Geçerli bir ders adı giriniz!')
            .required('Ders adı gerekli!'),
        lessonSections: yup
            .array()
            .min(1, 'En az 1 bölüm seçmelisiniz!'),
    })

    const { clearErrors, reset, register, handleSubmit, control, setValue, watch, formState: { isValid, isSubmitted, errors }} = useForm({
        resolver: yupResolver(lessonSchema),
        defaultValues: {
            title: activeLesson?.title,
            lessonSections: activeLesson?.sections,
        }
    })
    const { token } = useSelector((state) => state.auth)
    const { sections } = useSelector((state) => state.section)
    const { loading, error, success, lessons, lesson, errorCode } = useSelector((state) => state.lesson)
    const [selectedSections, setSelectedSections] = useState([])

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        const clearForm = () => {
            clearErrors()
            reset()
        }
        if(!show) {
            clearForm()
        } else {
            if(sections.length < 1) {
                dispatch(getSections(token))
            }
        }
    }, [show])

    useEffect(() => {
        if(error) {
            if(Array.isArray(error.error) || typeof error.error === 'object') {
                Object.keys(error.error).map((err, index) => (
                    console.log(error.error[err][0])
                    // toast(error.error[err][0], { type: 'error' })
                ))
            } else {
                console.log('Error: ', error.error)
                toast(error.error, { type: 'error' })
            }

        }
    }, [error])

    useEffect(() => {
        if(success) {
            handleClose()
        }
    }, [success])

    useEffect(() => {
        if(errorCode === 401) {
            navigate('/')
        }
    }, [errorCode])

    useEffect(() => {
        console.log('sections', sections)
        setSelectedSections(sectionList.filter(parent => activeLesson?.sections.some(section => section.id === parent.value)).map(item => ({ label: item.label, value: item.value })))
    }, [sections])


    const submitForm = async (data) => {
        await dispatch(updateLesson({
            token,
            lesson: {
                'section_ids': data.lessonSections,
                'title': data.title
            },
            id: activeLesson?.id
        }))
    }

    const handleSectionChange = (selected) => {
        setValue('lessonSections', Array.isArray(selected) ? selected.map(x => x.value) : [])
        setSelectedSections(selected.map(item => ({ label: item.label, value: item.value })))
    }

    const sectionList = sections?.map(section => (
        {value: section.id, label: section.fullSectionTitle}
    ))

    return (
        <>
            <div className={ 'modal fade' + (show ? 'show' : '')} id="error-modal" tabIndex="-1" role="dialog" aria-hidden="true" style={{ display: show ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content position-relative">
                        <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                            <button
                                onClick={handleClose}
                                className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-0">
                            <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                                <h4 className="mb-1" id="modalExampleDemoLabel">Ders Düzenle </h4>
                            </div>
                            <div className="p-4 pb-0">
                                <Form noValidate onSubmit={handleSubmit(submitForm)} className="row g-3">
                                    <div className="col-md-12 mb-1">
                                        <label className="col-form-label" htmlFor="parent-section">Bölüm:</label>
                                        <Controller
                                            name='lessonSections'
                                            control={control}
                                            render={({ onChange, value, ref }) => (
                                                <>
                                                    <Select
                                                        id='lesson-section'
                                                        className={'form-control' + (errors.lessonSections ? ' is-invalid' : isSubmitted ? ' is-valid' : '')}
                                                        isSearchable
                                                        isMulti
                                                        options={sectionList}
                                                        value={selectedSections}
                                                        onChange={handleSectionChange}
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.lessonSections ? errors.lessonSections.message : ''}
                                                    </Form.Control.Feedback>
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="col-md-12 mb-1">
                                        <label className="col-form-label" htmlFor="section-title">Ders Adı:</label>
                                        <Form.Control
                                            className="form-control"
                                            id="lesson-title"
                                            type="text"
                                            isValid={isSubmitted && !errors.title}
                                            isInvalid={isSubmitted && !!errors.title}
                                            {...register('title')}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.title ? errors.title.message : ''}
                                        </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                        <Button
                                            className="btn btn-warning"
                                            type="submit"
                                            name='submit'
                                            disabled={loading}>
                                            {loading ?
                                                <>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    /> Bekleyin...
                                                </>
                                                : 'Güncelle'
                                            }
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button
                                onClick={handleClose}
                                className="btn btn-secondary" type="button" data-bs-dismiss="modal">Kapat</Button>
                        </div>
                    </div>
                </div>
            </div>
            {
                show && (
                    <div className="modal-backdrop fade show"></div>
                )
            }
            <ToastContainer/>
        </>
    )
}

export default EditLessonModal