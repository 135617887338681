import http from '../../utils/http'
import { createAsyncThunk } from '@reduxjs/toolkit'
import secureLocalStorage from 'react-secure-storage'
import {clearLocalStorage} from '../../utils/misc'

export const getWriters = createAsyncThunk(
'writer/list',
async ({ token }, { rejectWithValue}) => {
    try {
        await http.get('/sanctum/csrf-cookie')
        if(token) {
            http.defaults.headers.common = {
                'Authorization': `Bearer ${token}`
            }
        }
        const { data } = await http.get('/api/editor/writers/')
        return data.response
    } catch (error) {
        if(error?.response?.status === 401) {
            clearLocalStorage()
            return rejectWithValue('Tekrar giriş yapınız!')
        }
        return rejectWithValue({error: error.response.data.errors, code: error?.response?.status })
    }
})

export const saveWriter = createAsyncThunk(
    'writer/save',
    async ({ token, writer }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.post('/api/editor/writers', writer)
            return data.response
        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
})

export const updateWriter = createAsyncThunk(
    'writer/update',
    async ({ token, writer, id }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.put('/api/editor/writers/' + id, writer)
            return data.response
        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
})

export const deleteWriter = createAsyncThunk(
    'writer/delete',
    async ({ token, id }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.delete('/api/editor/writers/' + id)
            return data.response
        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
    })

export const getSubjectsWithEarnings = createAsyncThunk(
    'writer/subjects',
    async ({ token, lessons, writer }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }

            const { data } = await http.post('/api/writer/questions/get-subjects-earnings', {
                lessons,
                writer
            })
            return data.response

        } catch (error) {
            if(error?.response?.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error?.response?.status })
        }
    })

export const activeWritersCount = createAsyncThunk(
    'get-active-writers-by-count',
    async ({ token, id }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.get('/api/editor/get-active-writers-by-count')
            return data.response
        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
})

export const allWritersCount = createAsyncThunk(
    'get-writers-by-count',
    async ({ token, id }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.get('/api/editor/get-writers-by-count')
            return data.response
        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
    })

export const pendingQuestionsCount = createAsyncThunk(
    'get-pending-questions-by-count',
    async ({ token, id }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.get('/api/editor/get-pending-questions-by-count')
            return data.response
        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
    })

export const approvedQuestionsCount = createAsyncThunk(
    'get-approved-questions-by-count',
    async ({ token, id }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.get('/api/editor/get-approved-questions-by-count')
            return data.response
        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
    })

export const rejectedQuestionsCount = createAsyncThunk(
    'get-rejected-questions-by-count',
    async ({ token, id }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.get('/api/editor/get-rejected-questions-by-count')
            return data.response
        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
    })

export const changeWriterStatus = createAsyncThunk(
    'change-writer-status',
    async ({ token, writer, status }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.post('/api/editor/change-writer-status', {
                writer,
                status
            })
            return data.response
        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
    })

export const getQuestionStatsByWriter = createAsyncThunk(
    'get-question-stats-by-writer',
    async ({ token }, { rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.get('/api/editor/get-question-stats-by-writer')
            return data.response
        } catch (error) {
            if(error.response.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error.response.status })
        }
    })


