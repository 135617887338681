import http from '../../utils/http'
import { createAsyncThunk } from '@reduxjs/toolkit'
import  secureLocalStorage  from  'react-secure-storage'
import {clearLocalStorage} from '../../utils/misc'

export const login = createAsyncThunk(
'auth/login',
async ({ email, password }, { rejectWithValue}) => {
    try {
        await http.get('/sanctum/csrf-cookie')
        const { data } = await http.post('/api/login', { email, password})
        secureLocalStorage.setItem('token', JSON.stringify(data.response.jwt))
        return data.response.jwt
    } catch (error) {
        return rejectWithValue(error.response.data.errors)
    }
})

export const logout = createAsyncThunk(
    'auth/logout',
    async ({token}, { rejectWithValue}) => {
        try {
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } =  await http.get('/api/logout')
            clearLocalStorage()
            return data
        } catch (error) {
            return rejectWithValue(error.response.data.errors)
        }
})

export const getUserDetails = createAsyncThunk(
    'auth/getUserDetails',
    async({token}, { rejectWithValue }) => {
        try {
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const { data } = await http.get('/api/get-current-user')
            secureLocalStorage.setItem('user', JSON.stringify(data.response))
            return data.response
        } catch(error) {
            return rejectWithValue(error.response.data.errors)
        }
})