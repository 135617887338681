import {createSlice} from '@reduxjs/toolkit'
import {getPools} from './poolActions'

const initialState = {
    loading: false,
    pools: [],
    pool: null,
    error: null,
    errorCode: null,
    success: false
}

export const poolSlice = createSlice({
    name: 'pool',
    initialState,
    reducers: {
        resetState(state) {
            state.loading = false
            state.error = null
            state.errorCode = null
            state.success = false
        }
    },
    extraReducers: {
        [getPools.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getPools.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.pools = payload
        },
        [getPools.rejected]: (state, {payload}) => {
            state.loading = false
            state.success = false
            state.error = payload
        }
    }
})

export const {resetState} = poolSlice.actions

export default poolSlice.reducer