import React, {useEffect, useMemo, useState, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import DashboardLayout from '../../../components/layouts/DashboardLayout'
import {IoMdRefresh} from 'react-icons/io'
import Dropzone, {useDropzone} from 'react-dropzone'
import SourceViewerModal from './SourceViewerModal'
import {removeFromCroppeds, addToCroppeds, resetState} from '../../../slices/sources/sourceSlice'
import {toast, ToastContainer} from 'react-toastify'
import ConfirmationModal from '../../../components/common/ConfirmationModal'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import EditCroppedSourceModal from '../../../components/writer/EditCroppedSourceModal'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'


const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'end',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    height: '100%'
}

const focusedStyle = {
    borderColor: '#2196f3'
}

const acceptStyle = {
    borderColor: '#00e676'
}

const rejectStyle = {
    borderColor: '#ff1744'
}

const handleRefresh = () => {
    console.log('Refresh Clicked!')
}
const CreateSource = () => {
    const dispatch = useDispatch()
    const dropzoneRef = useRef()
    const [pdfFile, setPdfFile] = useState(null)
    const [pdfName, setPdfName] = useState(null)
    const [pdfError, setPdfError] = useState('')
    const [showViewer, setShowViewer] = useState(false)
    const [pdfJson, setPdfJson] = useState([])

    const [showConfirm, setShowConfirm] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [activeItem, setActiveItem] = useState(null)

    const {croppeds} = useSelector((state) => state.source)

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        open
    } = useDropzone({
        accept: {
            'application/pdf': [],
            'image/*': ['.png', '.jpg', '.jpeg']
        }
    })

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ])

    useEffect(() => {
        console.log('croppeds', croppeds)
    }, [croppeds])

    useEffect(() => {
        if(pdfFile && !pdfError) {
            setShowViewer(true)
        }
    }, [pdfFile])

    useEffect(() => {
        if(pdfError) {
            toast(pdfError, { type: 'error' })
        }
    }, [pdfError])

    const handleRefresh = () => {
        console.log('Refresh Clicked!')
    }

    const handleCloseModal = () => {
        setShowViewer(false)
        setPdfFile(null)
        setPdfError(null)
        // setPdfJson([])
    }

    const handleDrop = (acceptedFiles) => {
        let index = null
        let selectedFile = acceptedFiles[0]
        if(selectedFile){
            let reader = new FileReader()
            reader.readAsDataURL(selectedFile)
            reader.onloadend = (e) => {
                if(selectedFile.type === 'application/pdf') {
                    setPdfError('')
                    setPdfFile(e.target.result)
                    setPdfName(selectedFile.name)
                    if(pdfJson?.length > 0) {
                        index = pdfJson?.filter(pdf => pdf.name === selectedFile.name).length > 0
                    }
                    if(!index) {
                        setPdfJson([...pdfJson, { file: e.target.result, name: selectedFile.name }])
                    }
                } else {
                    dispatch(addToCroppeds({
                        id: croppeds?.length === 0 ? 1 : croppeds?.length + 1,
                        answer: null,
                        blobImage: e.target.result,
                        file: selectedFile,
                        blob: selectedFile,
                        roots: [],
                        tags: [],
                        row_type: 'multiple',
                        croppedPng: e.target.result,
                        earnings: []
                    }))
                }
            }
        }
    }

    const handleFileUpload = (e) => {
        e.preventDefault()
        if (dropzoneRef.current) {
            dropzoneRef.current.open()
        }
    }

    const handleConfirmModal = (data) => {
        setActiveItem(data)
        setShowConfirm(true)
    }

    const handleDelete = async (data) => {
        await dispatch(removeFromCroppeds(activeItem))
        setShowConfirm(false)
    }

    const handleEditModal = (cropped) => {
        setActiveItem(cropped)
        setShowEdit(true)
    }

    const handleCloseEditModal = () => {
        setShowEdit(false)
        setActiveItem(null)
        dispatch(resetState())
    }

    const handleCloseConfirm = () => {
        setShowConfirm(false)
        setActiveItem(null)
    }

    return (
        <DashboardLayout>
            <div className="card mb-3">
                <div className="card-header">
                    <div className="row flex-between-end">
                        <div className="col-auto align-self-center">
                            <h5 className="mb-0" data-anchor="data-anchor">Kaynak Oluştur</h5>
                        </div>
                        <div className="col-auto ms-auto">
                            <button className="btn btn-falcon-default btn-sm" type="button" role="button" onClick={handleRefresh}>
                                <IoMdRefresh size={20} color='blue' />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <div className="kanban-items-container border bg-white dark__bg-1000 rounded-2 py-3 mb-3 d-flex flex-center flex-wrap position-relative" style={{ maxHeight: 'none', height: '80vh' }}>
                        <div className='d-flex flex-wrap position-absolute w-100' style={{ left: '2rem', top: '2rem' }}>
                            {
                                croppeds?.map((cropped, index) => (
                                    <div key={index} className="card kanban-item shadow-sm dark__bg-1100 me-2" style={{ width: '300px', height: '200px' }}>
                                        <div className="card-body">
                                            <div className="hoverbox rounded-3 text-center">
                                                <img className="img-fluid" style={{ objectFit: 'contain' }} src={ cropped?.croppedPng ? cropped?.croppedPng : '.img/placeholders/question-placeholder.jpeg' } alt=""/>
                                                <div className="light hoverbox-content text-bg-light p-2 flex-center">
                                                    <p className="lead text-white"></p>
                                                    <div className='d-flex justify-content-evenly'>
                                                        <OverlayTrigger
                                                            placement='top'
                                                            overlay={
                                                                <Tooltip id={`tooltip-top`}>
                                                                    İçerik Sil
                                                                </Tooltip>
                                                            }>
                                                            <a  onClick={() => handleConfirmModal(cropped)}
                                                                className="btn btn-sm btn-falcon-default rounded me-1" role='button'>
                                                                <span className="fas fa-trash text-danger fs-1"></span>
                                                            </a>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement='top'
                                                            overlay={
                                                                <Tooltip id='tooltip-top'>
                                                                    İçeriği Düzenle
                                                                </Tooltip>
                                                            }>
                                                            <a onClick={() => handleEditModal(cropped)} className="btn btn-sm btn-falcon-default rounded" role="button">
                                                                <span className="fas fa-edit text-warning fs-1"></span>
                                                            </a>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <Dropzone ref={dropzoneRef} onDrop={handleDrop} style={{ height: '100vh' }}>
                            {({getRootProps, getInputProps}) => (
                                <div {...getRootProps({ style })}>
                                    <input {...getInputProps()} />
                                    <a className='d-inline' onClick={handleFileUpload} href="#">İçerik seçin</a> veya sürükleyin...
                                </div>
                            )}
                        </Dropzone>
                    </div>
                    {pdfFile&&(
                        <SourceViewerModal show={showViewer} handleClose={handleCloseModal} pdfJson={pdfJson}  activePdf={pdfFile} fileName={pdfName} handleFileUpload={handleFileUpload} />
                    )}
                </div>
            </div>
            <ToastContainer/>
            <ConfirmationModal show={showConfirm} handleClose={handleCloseConfirm} handleConfirm={handleDelete} header='Soru Sil' />
            {activeItem ? <EditCroppedSourceModal show={showEdit} handleClose={handleCloseEditModal} activeItem={activeItem} /> : '' }
        </DashboardLayout>
    )
}

export default CreateSource