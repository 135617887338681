import { createSlice } from '@reduxjs/toolkit'
import { getLessons, saveLesson, updateLesson, deleteLesson } from './lessonActions'

const initialState = {
    loading: false,
    lessons: [],
    lesson: null,
    sections: [],
    error: null,
    success: false,
}

export const lessonSlice = createSlice({
    name: 'section',
    initialState,
    reducers: {
        resetState(state) {
            state.loading = false
            state.error = null
            state.errorCode = null
            state.success = false
        }
    },
    extraReducers: {
        [getLessons.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getLessons.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.lessons = payload
        },
        [getLessons.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [saveLesson.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [saveLesson.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            state.lessons.unshift(payload)
            state.lesson = payload
        },
        [saveLesson.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload.error
            state.errorCode = payload.code
        },
        [updateLesson.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [updateLesson.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            const index = state.lessons.findIndex(item => item.id === payload.id)
            state.lessons[index] = payload
            state.lesson = payload
        },
        [updateLesson.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload.error
            state.errorCode = payload.code
        },
        [deleteLesson.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [deleteLesson.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            state.lessons = state.lessons.filter(lesson => lesson.id !== payload.id)
        },
        [deleteLesson.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload.error
            state.errorCode = payload.code
        },
    }
})

export const { resetState } = lessonSlice.actions

export default lessonSlice.reducer