import {createSlice} from '@reduxjs/toolkit'
import {getFields} from './fieldActions'

const initialState = {
    loading: false,
    fields: [],
    field: null,
    error: null,
    errorCode: null,
    success: false
}

export const fieldSlice = createSlice({
    name: 'field',
    initialState,
    reducers: {
        resetState(state) {
            state.loading = false
            state.error = null
            state.errorCode = null
            state.success = false
        }
    },
    extraReducers: {
        [getFields.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getFields.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.fields = payload
        },
        [getFields.rejected]: (state, {payload}) => {
            state.loading = false
            state.success = false
            state.error = payload
        }
    }
})

export const {resetState} = fieldSlice.actions

export default fieldSlice.reducer