import React, {useEffect, useMemo, useState} from 'react'
import {Button} from 'react-bootstrap'

const ConfirmationModal = ({ show, handleClose, handleConfirm, header }) => {

    useEffect(() => {

    }, [show])


    return (
        <>
            <div className={ 'modal fade' + (show ? 'show' : '')} id="error-modal" tabIndex="-1" role="dialog" aria-hidden="true" style={{ display: show ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content position-relative">
                        <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                            <button
                                onClick={handleClose}
                                className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-0">
                            <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                                <h4 className="mb-1" id="modalExampleDemoLabel">{ header }</h4>
                            </div>
                            <div className="p-4 pb-0 mb-3">
                                <div className="card h-xxl-100">
                                    <div className="card-body bg-light">
                                        <h3 className="fs-0"></h3>
                                        <p className="fs-1 text-danger">Seçtiğiniz kayıt silinecek. Emin misiniz?</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button
                                className="btn btn-danger"
                                type="submit"
                                onClick={handleConfirm}>
                                Sil
                            </Button>
                            <Button
                                onClick={handleClose}
                                className="btn btn-secondary" type="button" data-bs-dismiss="modal">İptal</Button>
                        </div>
                    </div>
                </div>
            </div>
            {
                show && (
                    <div className="modal-backdrop fade show"></div>
                )
            }
        </>
    )
}

export default ConfirmationModal