import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import {Provider} from 'react-redux'
import {store} from './store/store'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <Routes>
                <Route path='/*' element={<App />} />
            </Routes>
        </Provider>
    </BrowserRouter>
);
