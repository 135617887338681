import React from 'react'
import EditorNavBar from '../common/editor/NavBar'
import AdminNavBar from '../common/admin/NavBar'
import SysAdminNavBar from '../common/sys-admin/NavBar'
import StaffNavBar from '../common/staff/NavBar'
import TeacherNavBar from '../common/teacher/NavBar'
import StudentNavBar from '../common/student/NavBar'
import WriterNavBar from '../common/writer/NavBar'
import TopBar from '../common/TopBar'
import Footer from '../common/Footer'
import {useSelector} from 'react-redux'

const DashboardLayout = ({ children }) => {
    const { user } = useSelector((state) => state.auth)

    return (
        <main className="main" id='top'>
            <div className="container-fluid">
                {
                    user.roles[0] === 'editor' && <EditorNavBar />
                }
                {
                    user.roles[0] === 'writer' && <WriterNavBar />
                }
                {
                    user.roles[0] === 'sys-admin' && <SysAdminNavBar />
                }
                {
                    user.roles[0] === 'admin-stuff' && <AdminNavBar />
                }
                {
                    user.roles[0] === 'staff' && <StaffNavBar />
                }
                {
                    user.roles[0] === 'teacher' && <TeacherNavBar />
                }
                {
                    user.roles[0] === 'student' && <StudentNavBar />
                }
                <div className="content">
                    <TopBar />
                    { children }
                    <Footer />
                </div>
            </div>
        </main>
    )
}

export default DashboardLayout