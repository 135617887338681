import {createSlice} from '@reduxjs/toolkit'
import {getLevels} from './levelActions'

const initialState = {
    loading: false,
    levels: [],
    level: null,
    error: null,
    errorCode: null,
    success: false
}

export const levelSlice = createSlice({
    name: 'level',
    initialState,
    reducers: {
        resetState(state) {
            state.loading = false
            state.error = null
            state.errorCode = null
            state.success = false
        }
    },
    extraReducers: {
        [getLevels.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getLevels.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.levels = payload
        },
        [getLevels.rejected]: (state, {payload}) => {
            state.loading = false
            state.success = false
            state.error = payload
        }
    }
})

export const {resetState} = levelSlice.actions

export default levelSlice.reducer