import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {ToastContainer, toast} from 'react-toastify'
import {Form, Button, Spinner, Badge, InputGroup} from 'react-bootstrap'
import {useForm, Controller} from 'react-hook-form'
import Select from 'react-select'
import {getSections, getSubSections} from '../../slices/sections/sectionActions'
import {getSubjectsWithEarnings} from '../../slices/writer/writerActions'
import {getDifficultyLevels} from '../../slices/difficultyLevels/difficultyLevelActions'
import {getTypes} from '../../slices/types/typeActions'
import {getLevels} from '../../slices/levels/levelActions'
import {uploadSolutionVideo} from '../../slices/uploader/uploaderActions'
import {resetUploader} from '../../slices/uploader/uploaderSlice'
import {saveQuestion} from '../../slices/questions/questionActions'
import {resetSections} from '../../slices/sections/sectionSlice'
import {removeSelectedEarning, resetEarnings} from '../../slices/questions/questionSlice'
import CollapsibleItem from '../common/CollapsibleItem'
import {BiCloudUpload, BiCloudDownload, BiX} from 'react-icons/bi'
import Tree from '../common/Tree'
import 'react-toastify/dist/ReactToastify.min.css'
import {flattenDeep} from 'lodash'

const EditQuestionModal = ({ show, handleClose, activeQuestion }) => {

    const questionSchema = yup.object({
        difficulty: yup
            .string()
            .nullable()
            .required('Zorluk seviyesi seçilmelidir!'),
        type: yup
            .string()
            .nullable()
            .required('Soru tipi seçilmelidir!'),
        root: yup
            .string()
            .nullable()
            .notRequired()
            .min('5', 'Kök bilgisi çok kısa!'),
        tags: yup
            .array()
            .min(1, 'En az 1 etiket girilmelidir!'),
        solution_video_link: yup
            .string()
            .required('Soru çözüm bağlantısı girilmelidir!')
            .url('Geçerli bir bağlantı girilmelidir!'),
        topic_explanation_link: yup
            .string()
            .nullable()
            .notRequired()
            .url('Geçerli link girilmelidir!'),
        vimeo_link: yup
            .string()
            .nullable()
            .notRequired()
            .url('Geçerli link girilmelidir!'),
        zoom_link: yup
            .string()
            .nullable()
            .notRequired()
            .url('Geçerli link girilmelidir!'),
        answer: yup
            .string()
            .nullable()
            .required('Cevap bilgisi seçilmelidir!'),
        earnings: yup
            .array()
            .min(1, 'En az 1 kazanım seçilmelidir!'),
        questionPools: yup
            .array()
            .min(1, 'En az 1 seçenek seçmelisiniz!'),
        parentSections: yup
            .string()
            .nullable()
            .required('Ana bölüm seçmelisiniz!'),
        sections: yup
            .string()
            .nullable()
            .required('Bölüm seçmelisiniz!'),
        lessons: yup
            .string()
            .nullable()
            .required('Ders seçmelisiniz!'),
        questionLevels: yup
            .array()
            .min(1, 'En az 1 seçenek seçmelisiniz!'),
    })

    const { token, user } = useSelector((state) => state.auth)
    const { loading, error, question, success, errorCode, earnings } = useSelector((state) => state.question)
    const { loading: sectionLoading, sections, subSections } = useSelector((state) => state.section)
    const { loading: writerLoading, subjects } = useSelector((state) => state.writer)
    const { loading: difficultyLevelLoading, difficultyLevels } = useSelector((state) => state.difficultyLevel)
    const { loading: typesLoading, types } = useSelector((state) => state.type)
    const { loading: levelLoading, levels } = useSelector((state) => state.level)
    const { loading: videoLoading, video } = useSelector((state) => state.uploader)
    const [modalLoading, setModalLoading] = useState(false)
    const [sectionsArr, setSectionsArr] = useState([])
    const [subSectionsArr, setSubSectionsArr] = useState([])
    const [tags, setTags] = useState([])
    const [answer, setAnswer] = useState('')

    const [selectedLessons, setSelectedLessons] = useState(null)
    const [selectedLevels, setSelectedLevels] = useState([])
    const [selectedPools, setSelectedPools] = useState([])
    const [selectedParentSection, setSelectedParentSection] = useState(null)
    const [selectedSection, setSelectedSection] = useState(null)
    const [selectedSubSection, setSelectedSubSection] = useState([])
    const [showLoader, setShowLoader] = useState(false)
    const [videoLoaded, setVideoLoaded] = useState(false)
    const [selectedVideo, setSelectedVideo] = useState({
        file: null,
        preview: null,
        duration: 0,
        size: 0,
    })
    const [solutionVideoLink, setSolutionVideoLink] = useState(null)

    const sectionRef = useRef(null)
    const subSectionRef = useRef(null)
    const lessonsRef = useRef(null)
    const solutionVideoRef = useRef(null)

    const { clearErrors, reset, register, handleSubmit, control, setValue, getValues, formState: { isValid, isSubmitted, errors }} = useForm({
        resolver: yupResolver(questionSchema),
        defaultValues: {
            // questionPool: activeQuestion?.questionPool,
            difficulty: null,
            type: null,
            questionLevels: [],
            root: null,
            solution_video_link: '',
            topic_explanation_link: null,
            vimeo_link: null,
            answer: activeQuestion?.answer,
            tags: [],
            earnings: [],
            lessons: null,
            questionPools: [],
            parentSections: null,
            sections: null,
            subSections: [],
        }
    })

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const styles = {
        'invalidAnswer': {
            display: errors.answer ? 'block' : 'none'
        },
        'invalidEarnings': {
            display: errors.earnings ? 'block' : 'none'
        },
    }

    useEffect(() => {
        setModalLoading(false)
        const clearForm = () => {
            clearErrors()
            reset()
            setVideoLoaded(false)
            setSelectedVideo({
                file: null,
                preview: null,
                duration: 0,
                size: 0,
            })
            dispatch(resetEarnings())
            dispatch(resetUploader())
        }

        if(!show) {
            clearForm()
        } else {
            // setParentsArr(user?.sections.filter(section => section.parent_id === 0).map(item => ({ label: item.full_section_title,  value: item.id })))
            // dispatch(getSubjectsWithEarnings({token, writer: user?.id, lessons: user?.lessons}))
            dispatch(getDifficultyLevels({token}))
            dispatch(getTypes({token}))
            dispatch(getLevels({token}))
            setAnswer(activeQuestion?.answer)
            console.log('user', user)
        }
    }, [show])


    useEffect(() => {
        if(error) {
            if(Array.isArray(error.error) || typeof error.error === 'object') {
                Object.keys(error.error).map((err, index) => (
                    // console.log(error.error[err][0])
                    toast(error.error[err][0], { type: 'error' })
                ))
            } else {
                console.log('Error: ', error.error)
                toast(error.error, { type: 'error' })
            }
        }
    }, [error])

    useEffect(() => {
        if(errorCode === 401) {
            navigate('/')
        }
    }, [errorCode])

    useEffect(() => {
        setValue('earnings', earnings)
    }, [earnings])

    useEffect(() => {
        console.log('solutionVideoLink', solutionVideoLink)
    }, [solutionVideoLink])

    useEffect(() => {
        if(answer) {
            setValue('answer', answer)
        }
    }, [answer])

    useEffect(() => {
        console.log('errors', errors)
        if(Object.keys(errors).length > 0) {
            toast('Formda hata var!', { type: 'error' })
            /*if(errors) {
                if(Array.isArray(error.error) || typeof error.error === 'object') {
                    Object.keys(error.error).map((err, index) => (
                        // console.log(error.error[err][0])
                        toast(error.error[err][0], { type: 'error' })
                    ))
                } else {
                    console.log('Error: ', error.error)
                    toast(error.error, { type: 'error' })
                }
            }*/
        }
    }, [errors])

    useEffect(() => {
        if(success) {
            toast('İşlem başarılı!', { type: 'success' })
            handleClose()
            dispatch(resetEarnings())
            dispatch(resetUploader())
        }
    }, [success])

    useEffect(() => {
        setSectionsArr([])
        setSubSectionsArr([])
        setValue('sections', null)
        setValue('subSections', [])
        sectionRef.current.clearValue()
        subSectionRef.current.clearValue()
        if(selectedParentSection) {
            const filtered = user?.sections.filter(section => section.parent_id == selectedParentSection.value)
            const flattened = flattenDeep(filtered)
            const result = flattened.map(item => ({label: item.title, value: item.id}))
            console.log(result)
            setSectionsArr(result)
        }
    }, [selectedParentSection])

    useEffect(() => {
        console.log('SectionsArr', sectionsArr)
    }, [sectionsArr])


    useEffect(() => {
        setSubSectionsArr([])
        setValue('subSections', [])
        subSectionRef.current.clearValue()
        if(selectedSection) {
            const result = user?.subSections.filter(({parent_id}) => selectedSection.value == parent_id).map(item => ({label: item.title, value: item.id}))
            setSubSectionsArr(result)
        }
    }, [selectedSection])

    const handleAnswer = (answer) => {
        console.log('answer', answer)
        setAnswer(answer)

    }
    const submitForm = async (data) => {
        const formData = new FormData()
        data = { ...data, 'writer_id' : user?.id, 'row_type' : activeQuestion?.row_type }
        formData.append('data', JSON.stringify(data))
        formData.append('image', activeQuestion?.blob)
        await dispatch(saveQuestion({
            token,
            question: formData,
            id: activeQuestion.id
        }))
    }

    const handleCloseModal = (event) => {
        event.preventDefault()
        clearErrors()
        reset()
        setVideoLoaded(false)
        setSelectedVideo({
            file: null,
            preview: null,
            duration: 0,
            size: 0,
        })
        dispatch(resetEarnings())
        dispatch(resetUploader())
        handleClose()
    }

    const handlePoolChange = (selected) => {
        setValue('questionPools', Array.isArray(selected) ? selected.map(x => x.value) : [])
        setSelectedPools(selected.map(item => ({ label: item.label, value: item.value })))
    }

    const poolList = user?.pools.map(pool => (
        { value: pool.id, label: pool.title  }
    ))

    const parentSectionList = user?.parentSections.map(section => (
        { value: section.id, label: section.title }
    ))

    const handleParentSectionChange = (selected) => {
        /*setValue('parentSections', Array.isArray(selected) ? selected.map(x => x.value) : [])
        setSelectedParentSection(selected.map(item => ({label: item.label, value: item.value})))*/

        setValue('parentSections', selected.value)
        setSelectedParentSection({ label: selected.label, value: selected.value })
    }

    const handleSectionChange = (selected) => {
        /*setValue('sections', Array.isArray(item) ? item.map(x => x.value) : [])
        const selectedItem = item.map(x => ({label: x.label, value: x.value}))
        setSelectedSection(selectedItem)*/

        console.log('selected', selected)
        if(selected) {
            setValue('sections', selected.value)
            setSelectedSection({ label: selected.label, value: selected.value })
        }
    }

    const handleSubSectionChange = (selected) => {
        setValue('subSections', Array.isArray(selected) ? selected.map(x => x.value) : [])
        setSelectedSubSection(selected.map(item => ({ label: item.label, value: item.value })))
    }

    const difficultyLevelList = difficultyLevels?.map(level => (
        {value: level.id, label: level.title}
    ))

    const handleDifficultyChange = (selected) => {
        setValue('difficulty', selected.value)
    }

    const typeList = types?.map(type => (
        {value: type.id, label: type.title}
    ))

    const handleTypeChange = (selected) => {
        setValue('type', selected.value)
    }

    const levelList = levels?.map(level => (
        {value: level.id, label: level.title}
    ))

    const handleLevelChange = (selected) => {
        setValue('questionLevels', Array.isArray(selected) ? selected.map(x => x.value) : [])
        setSelectedLevels(selected.map(item => ({ label: item.label, value: item.value })))
    }

    useEffect(() => {
        setValue('tags', tags)
    }, [tags])

    const removeTag = (index) => {
        setTags(tags.filter((el, i) => i !== index))
    }

    const handleKeyDown = (e) => {
        if(e.key !== 'Enter') return
        const value = e.target.value
        if(!value.trim()) return
        setTags([...tags, value])
        e.target.value = ''
        e.preventDefault()
    }

    const handleSolutionVideo = (e) => {
        e.preventDefault()
        console.log(e)

    }
    const handleLessonVideo = (e) => {
        e.preventDefault()
        console.log(e)
    }

    const convertHMS = (value) => {
        const sec = parseInt(value, 10); // convert value to number if it's string
        let hours = Math.floor(sec / 3600); // get hours
        let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
        let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
        // add 0 if value < 10; Example: 2 => 02
        if (hours < 10) {
            hours = '0' + hours;
        }
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        if (seconds < 10) {
            seconds = '0' + seconds;
        }
        return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
    }

    const previewVideo = (e) => {
        setShowLoader(true)
        let preview = null
        const video = e.target.files[0]
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = (readerEvent) => {
                let audio = new Audio(readerEvent.target.result)
                console.log('audio', audio)
                if(navigator.userAgent.match(/chrome|chromium|crios/i)) {
                    console.log('chrome')
                    resolve(convertHMS(audio.duration))
                    audio.addEventListener('loadedmetadata', function (e) {
                        console.log('eventListener', audio)
                        resolve(convertHMS(audio.duration))
                    })
                } else {
                    console.log('not chrome')
                    audio.onloadedmetadata = () => {
                        console.log('onloadedmetadata', audio)
                        resolve(convertHMS(audio.duration))
                    }
                }
                preview = readerEvent.target.result
            }
            reader.readAsDataURL(video)
        })
        .then((duration) => {
            console.log('duration', duration)
            setSelectedVideo({ file: video, preview, duration, size: video?.size })
            setShowLoader(false)
        })
        .catch((err) => {
            console.log(err)
        })
    }

    const fileClickHandler = (event) => {
        event.preventDefault()
        solutionVideoRef.current.click()
    }

    const clearVideo = (event) => {
        event.preventDefault()
        setSelectedVideo({
            file: null,
            preview: null,
            duration: 0,
            size: 0,
        })
    }

    const handleUpload = async (event) => {
        setShowLoader(true)
        event.preventDefault()
        const data = new FormData()
        data.append('video', selectedVideo.file)
        await dispatch(uploadSolutionVideo({ token, file: data }))
        setShowLoader(false)
    }

    const handleDelete = (event) => {
        event.preventDefault()
        setVideoLoaded(false)
        setSolutionVideoLink(null)
        setValue('solution_video_link', null)
        setSelectedVideo({
            file: null,
            preview: null,
            duration: 0,
            size: 0,
        })
    }

    useEffect(() => {
        let data = JSON.parse(JSON.parse(video))
        console.log('data', data)
        if(data?.status) {
            setVideoLoaded(true)
            setValue('solution_video_link', data.data?.direct_link)
            setSolutionVideoLink(data.data?.direct_link)
        }
    }, [video])

    let lessonList = user?.lessons.map(lesson => (
        {value: lesson.id, label: lesson.title}
    ))

    const handleLessonChange = (selected) => {
        console.log('Lesson', selected)
        setValue('lessons', selected.value)
        setSelectedLessons({ label: selected.label, value: selected.value })
    }

    useEffect(() => {
        // const lessonArr = Array.isArray(selectedLessons) ? selectedLessons.map(x => x.value) : []
        console.log('lessons', selectedLessons)
        console.log('section', selectedParentSection)
        console.log('subSection', selectedSection)
        if(selectedLessons && selectedParentSection && selectedSection) {
            dispatch(getSubjectsWithEarnings({token, writer: user?.id, lesson: selectedLessons.value, section: selectedParentSection.value, subSection: selectedSection.value}))
        }
    }, [selectedSection])

    const removeEarning = (data, index) => {
        console.log('data', data)
        console.log('index', index)
        dispatch(removeSelectedEarning(index))
    }

    return (
        <>
            <div className={ 'modal fade' + (show ? 'show' : '')} id="error-modal" tabIndex="-1" role="dialog" aria-hidden="true" style={{ display: show ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered modal-fullscreen" role="document">
                    <div className="modal-content position-relative">
                        <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                            <button onClick={handleClose} className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-0">
                            <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                                <h4 className="mb-1" id="modalExampleDemoLabel">Soru Düzenle </h4>
                            </div>
                            <div className="p-4 pb-0">
                                { !modalLoading
                                    ? <Form noValidate onSubmit={handleSubmit(submitForm)} className='row g-3'>
                                        <div className="col-md-6">
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="question-image">Soru Görseli:</label>
                                                <div className='d-flex flex-column justify-content-center align-content-center align-items-center'>
                                                    <figure className="figure d-block" style={{ width: '300px', objectFit: 'contain' }}>
                                                        <img className="figure-img img-fluid rounded" src={ activeQuestion.blobImage } alt="..."/>
                                                    </figure>
                                                    <a className='btn btn-sm btn-info btn-block mb-2' href={URL.createObjectURL(activeQuestion.blob)} download>
                                                        <BiCloudDownload size={25} color='white' /> İndir
                                                    </a>
                                                    <div className="container d-flex justify-content-center align-content-center align-items-center">
                                                        <div className="btn-toolbar">
                                                            <button
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    setAnswer('A')
                                                                }}
                                                                className={'btn btn-sm me-1 ' + (answer === 'A' ? 'btn-success' : 'btn-danger')}>A</button>
                                                            <button
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    setAnswer('B')
                                                                }}
                                                                className={'btn btn-sm me-1 ' + (answer === 'B' ? 'btn-success' : 'btn-danger')}>B</button>
                                                            <button
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    setAnswer('C')
                                                                }}
                                                                className={'btn btn-sm me-1 ' + (answer === 'C' ? 'btn-success' : 'btn-danger')}>C</button>
                                                            <button
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    setAnswer('D')
                                                                }}
                                                                className={'btn btn-sm me-1 ' + (answer === 'D' ? 'btn-success' : 'btn-danger')}>D</button>
                                                            <button
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    setAnswer('E')
                                                                }}
                                                                className={'btn btn-sm me-1 ' + (answer === 'E' ? 'btn-success' : 'btn-danger')}>E</button>
                                                        </div>
                                                    </div>
                                                    <div className='invalid-feedback d-flex justify-content-center align-items-center' style={styles.invalidAnswer}>
                                                        {errors.answer ? errors.answer.message : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="question-solution">Çözüm Videosu:</label>
                                                <InputGroup className="mb-1">
                                                    <Form.Control
                                                        className="form-control"
                                                        id="question-solution"
                                                        type="text"
                                                        value={solutionVideoLink}
                                                        isValid={isSubmitted && !errors.solution_video_link}
                                                        isInvalid={isSubmitted && !!errors.solution_video_link}
                                                        {...register('solution_video_link')}
                                                    />
                                                    {!selectedVideo.preview &&
                                                    <button disabled={showLoader} onClick={fileClickHandler} className="btn btn-info" id="button-addon2">
                                                        <BiCloudUpload size={25} color='white' />
                                                    </button>
                                                    }
                                                    {selectedVideo.preview && !videoLoaded &&
                                                    <button disabled={showLoader} onClick={(event) => clearVideo(event)} className="btn btn-danger" id="button-addon2">
                                                        <BiX size={25} color='white' />
                                                    </button>
                                                    }
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.solution_video_link ? errors.solution_video_link.message : ''}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                                <input ref={solutionVideoRef} accept="video/*" onChange={previewVideo} onClick={(event) => { event.target.value = null }} type="file" hidden />
                                                <div className="preview w-100 d-flex flex-column justify-content-center align-items-center">
                                                    {selectedVideo.preview && !showLoader &&
                                                        <>
                                                            <video width='100%' controls src={selectedVideo.preview}></video>
                                                            <div className="d-grid gap-2">
                                                                {videoLoaded ?
                                                                    <button disabled={showLoader} onClick={handleDelete} className="btn btn-danger mt-2">Sil</button>
                                                                    :
                                                                    <button disabled={showLoader} onClick={handleUpload} className="btn btn-success mt-2">Yükle</button>
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                    {showLoader &&
                                                        <div className="spinner-grow text-primary" role="status">
                                                            <span className="visually-hidden">Yükleniyor...</span>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="question-explanation">Konu Açıklaması:</label>
                                                <InputGroup className='mb-1'>
                                                    <Form.Control
                                                        className="form-control"
                                                        id="question-explanation"
                                                        type="text"
                                                        isValid={isSubmitted && !errors.topic_explanation_link}
                                                        isInvalid={isSubmitted && !!errors.topic_explanation_link}
                                                        {...register('topic_explanation_link')}
                                                    />
                                                    <button onClick={(e) => handleLessonVideo(e)} className="btn btn-info" id="button-addon2">
                                                        <BiCloudUpload size={25} color='white' />
                                                    </button>
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.topic_explanation_link ? errors.topic_explanation_link.message : ''}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </div>
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="question-vimeo">Vimeo Bağlantısı:</label>
                                                <Form.Control
                                                    className="form-control"
                                                    id="question-vimeo"
                                                    type="text"
                                                    isValid={isSubmitted && !errors.vimeo_link}
                                                    isInvalid={isSubmitted && !!errors.vimeo_link}
                                                    {...register('vimeo_link')}
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.vimeo_link ? errors.vimeo_link.message : ''}
                                                </Form.Control.Feedback>
                                            </div>
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="question-difficulty">Zorluk Seviyesi:</label>
                                                <Controller
                                                    name='difficulty'

                                                    control={control}
                                                    render={({ onChange, value, ref }) => (
                                                        <>
                                                            <Select
                                                                id='question-difficulty'
                                                                className={'form-control' + (errors.difficulty ? ' is-invalid' : isSubmitted ? ' is-valid' : '')}
                                                                isSearchable
                                                                placeholder='Zorluk Seviyesi Seçin...'
                                                                options={difficultyLevelList}
                                                                onChange={handleDifficultyChange}
                                                            />
                                                            <Form.Control.Feedback type='invalid'>
                                                                {errors.difficulty ? errors.difficulty.message : ''}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="question-type">Soru Tipi:</label>
                                                <Controller
                                                    name='type'
                                                    control={control}
                                                    render={({ onChange, value, ref }) => (
                                                        <>
                                                            <Select
                                                                id='question-type'
                                                                className={'form-control' + (errors.type ? ' is-invalid' : isSubmitted ? ' is-valid' : '')}
                                                                isSearchable
                                                                placeholder='Soru Tipi Seçin...'
                                                                options={typeList}
                                                                onChange={handleTypeChange}
                                                            />
                                                            <Form.Control.Feedback type='invalid'>
                                                                {errors.type ? errors.type.message : ''}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="question-root">Soru Kökü:</label>
                                                <Form.Control
                                                    className="form-control"
                                                    id="question-root"
                                                    type="text"
                                                    isValid={isSubmitted && !errors.root}
                                                    isInvalid={isSubmitted && !!errors.root}
                                                    {...register('root')}
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.root ? errors.root.message : ''}
                                                </Form.Control.Feedback>
                                            </div>
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="question-tags">Etiketler:</label>
                                                <Controller
                                                    name='tags'
                                                    control={control}
                                                    render={({ onChange, value, ref }) => (
                                                        <div className="tags-input-container">
                                                            { tags.map((tag, index) => (
                                                                    <div className="tag-item" key={index}>
                                                                        <span className="text">{tag}</span>
                                                                        <span
                                                                            className="close"
                                                                            onClick={() => removeTag(index)}>&times;</span>
                                                                    </div>
                                                                ))
                                                            }
                                                            <Form.Control
                                                                className="form-control"
                                                                id="question-tags"
                                                                type="text"
                                                                isValid={isSubmitted && !errors.tags}
                                                                isInvalid={isSubmitted && !!errors.tags}
                                                                onKeyDown={handleKeyDown}
                                                            />
                                                            <Form.Control.Feedback type='invalid'>
                                                                {errors.tags ? errors.tags.message : ''}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="question-style">İlgili Ders:</label>
                                                <Controller
                                                    name='lessons'
                                                    control={control}
                                                    render={({ onChange, value, ref }) => (
                                                        <>
                                                            <Select
                                                                ref={lessonsRef}
                                                                id='lessons'
                                                                className={'form-control' + (errors.lessons ? ' is-invalid' : isSubmitted ? ' is-valid' : '')}
                                                                isSearchable
                                                                placeholder='Ders Listesi...'
                                                                options={lessonList}
                                                                value={selectedLessons}
                                                                onChange={handleLessonChange}
                                                            />
                                                            <Form.Control.Feedback type='invalid'>
                                                                {errors.lessons ? errors.lessons.message : ''}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="question-levels">İlgili Düzey:</label>
                                                <Controller
                                                    name='questionLevels'
                                                    control={control}
                                                    render={({ onChange, value, ref }) => (
                                                        <>
                                                            <Select
                                                                id='levels'
                                                                className={'form-control' + (errors.questionLevels ? ' is-invalid' : isSubmitted ? ' is-valid' : '')}
                                                                isMulti
                                                                isSearchable
                                                                placeholder='Düzey Listesi...'
                                                                options={levelList}
                                                                value={selectedLevels}
                                                                onChange={handleLevelChange}
                                                            />
                                                            <Form.Control.Feedback type='invalid'>
                                                                {errors.questionLevels ? errors.questionLevels.message : ''}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="question-style">Soru Havuzu:</label>
                                                <Controller
                                                    name='questionPools'
                                                    control={control}
                                                    render={({ onChange, value, ref }) => (
                                                        <>
                                                            <Select
                                                                id='question-pools'
                                                                className={'form-control' + (errors.questionPools ? ' is-invalid' : isSubmitted ? ' is-valid' : '')}
                                                                isMulti
                                                                isSearchable
                                                                placeholder='Soru Havuzu Listesi....'
                                                                options={poolList}
                                                                value={selectedPools}
                                                                onChange={handlePoolChange}
                                                            />
                                                            <Form.Control.Feedback type='invalid'>
                                                                {errors.questionPools ? errors.questionPools.message : ''}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="parent-section">Ana Bölüm:</label>
                                                <Controller
                                                    name='parentSections'
                                                    control={control}
                                                    render={({ onChange, value, ref }) => (
                                                        <>
                                                            <Select
                                                                id='question-parent-sections'
                                                                className={'form-control' + (errors.parentSections ? ' is-invalid' : isSubmitted ? ' is-valid' : '')}
                                                                isSearchable
                                                                placeholder='Ana Bölüm Listesi...'
                                                                options={parentSectionList}
                                                                value={selectedParentSection}
                                                                onChange={handleParentSectionChange}
                                                            />
                                                            <Form.Control.Feedback type='invalid'>
                                                                {errors.parentSections ? errors.parentSections.message : ''}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="section">Bölüm:</label>
                                                <Controller
                                                    name='sections'
                                                    control={control}
                                                    render={({ onChange, value, ref }) => (
                                                        <>
                                                            <Select
                                                                ref={sectionRef}
                                                                id='question-sections'
                                                                className={'form-control' + (errors.sections ? ' is-invalid' : isSubmitted ? ' is-valid' : '')}
                                                                isSearchable
                                                                noOptionsMessage={() => 'Bölüm Seçin...'}
                                                                placeholder='Bölüm Listesi...'
                                                                options={sectionsArr}
                                                                value={selectedSection}
                                                                onChange={handleSectionChange}
                                                            />
                                                            <Form.Control.Feedback type='invalid'>
                                                                {errors.sections ? errors.sections.message : ''}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="sub-section">Alt Bölüm:</label>
                                                <Controller
                                                    name='subSections'
                                                    control={control}
                                                    render={({ onChange, value, ref }) => (
                                                        <>
                                                            <Select
                                                                ref={subSectionRef}
                                                                id='sub-sections'
                                                                className={'form-control' + (errors.subSections ? ' is-invalid' : isSubmitted ? ' is-valid' : '')}
                                                                isMulti
                                                                isSearchable
                                                                noOptionsMessage={() => 'Bölüm Seçin...'}
                                                                placeholder='Alt Bölüm Listesi...'
                                                                options={subSectionsArr}
                                                                value={selectedSubSection}
                                                                onChange={handleSubSectionChange}
                                                            />
                                                            <Form.Control.Feedback type='invalid'>
                                                                {errors.subSections ? errors.subSections.message : ''}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="lessons">Üniteler/Kazanımlar:</label>
                                                <div className="row">
                                                    {(subjects === undefined && selectedSection) &&(
                                                        <div className="d-flex flex-center">
                                                            <Spinner animation="grow" variant="primary" />
                                                        </div>
                                                    )}
                                                    {subjects?.length > 0 &&
                                                        <>
                                                            <CollapsibleItem key={selectedLessons?.label} lesson={selectedLessons} subjects={subjects} />
                                                            <div className='col-xs-12 col-md-6 col-lg-6 col-xl-6'>
                                                                {earnings.map((earning, index) => (
                                                            <div className="alert alert-success" role="alert">
                                                                {earning.title}
                                                            </div>
                                                            ))
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                    <div className='invalid-feedback ' style={styles.invalidEarnings}>
                                                        {errors.earnings ? errors.earnings.message : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 mb-2 d-flex justify-content-end">
                                            <Button
                                                className="btn btn-warning"
                                                type="submit"
                                                name='submit'
                                                disabled={loading}>
                                                {loading ?
                                                    <>
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        /> Bekleyin...
                                                    </>
                                                    : 'Güncelle'
                                                }
                                            </Button>
                                        </div>
                                    </Form>
                                    : <div><h3>Yükleniyor...</h3></div>
                                }

                            </div>
                        </div>
                        <div className="modal-footer d-flex justify-content-start">
                            <Button
                                onClick={handleCloseModal}
                                className="btn btn-secondary" type="button" data-bs-dismiss="modal">Kapat</Button>
                        </div>
                    </div>
                </div>
            </div>
            {
                show && (
                    <div className="modal-backdrop fade show"></div>
                )
            }
            <ToastContainer/>
        </>
    )
}

export default EditQuestionModal