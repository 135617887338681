import http from '../../utils/http'
import {createAsyncThunk} from '@reduxjs/toolkit'
import {clearLocalStorage} from '../../utils/misc'

export const getDifficultyLevels = createAsyncThunk(
    'difficultyLevels/list',
    async({token}, {rejectWithValue}) => {
        try {
            await http.get('/sanctum/csrf-cookie')
            if(token) {
                http.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                }
            }
            const {data} = await http.get('/api/difficulty-levels/')
            return data.response
        } catch (error) {
            if(error?.response?.status === 401) {
                clearLocalStorage()
                return rejectWithValue('Tekrar giriş yapınız!')
            }
            return rejectWithValue({error: error.response.data.errors, code: error?.response?.status })
        }
    }
)