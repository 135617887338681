import { createSlice } from '@reduxjs/toolkit'
import { getByStatus, deleteQuestion, updateQuestion, saveQuestion, updateAnswer, updateStatus } from './questionActions'


const initialState = {
    loading: false,
    questions: [],
    pendings: [],
    approveds: [],
    lastApproveds: [],
    rejecteds: [],
    earnings: [],
    croppeds: [],
    question: null,
    error: null,
    errorCode: null,
    success: false,
}

export const questionSlice = createSlice({
    name: 'question',
    initialState,
    reducers: {
        resetState(state) {
            state.loading = false
            state.error = null
            state.errorCode = null
            state.success = false
        },
        setSelectedEarning(state, { payload }) {
            state.earnings.push(payload)
        },
        removeSelectedEarning(state, { payload }) {
            state.earnings.splice(payload, 1)
        },
        resetEarnings(state) {
            console.log('resetEarnings')
            state.earnings = []
        },
        addToCroppeds(state, { payload }) {
            state.croppeds.push(payload)
        },
        removeFromCroppeds(state, {payload}) {
            console.log('payload', payload)
            const index = state.croppeds.findIndex(cropped => cropped.id === payload.id)
            console.log('index', index)
            state.croppeds.splice(index, 1)
        },
        changeAnswer(state, { payload }) {
            console.log(payload)
            const index = state.croppeds.findIndex(cropped => cropped.id === payload.cropped.id)
            state.croppeds[index].answer = payload.answer
        },
        setAnswer(state, { payload }) {

            const index = state.croppeds.findIndex(cropped => cropped.id === payload.id)
            state.croppeds[index].answer = payload.answer
        },
        getLastApproveds(state) {
            state.lastApproveds = state.approveds.slice(5)
            console.log(state.lastApproveds)
        }
    },
    extraReducers: {
        [getByStatus.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getByStatus.fulfilled]: (state, { payload }) => {
            state.loading = false
            // state.success = true
            switch (payload.status) {
                case 'waiting_approval':
                    state.pendings = payload.response
                    break
                case 'approval':
                    state.approveds = payload.response
                    break
                case 'rejected':
                    state.rejecteds = payload.response
                    break
            }
        },
        [getByStatus.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [saveQuestion.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [saveQuestion.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            state.croppeds = state.croppeds.filter(item => item.id !== payload.id)
        },
        [saveQuestion.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [updateQuestion.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [updateQuestion.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            console.log('payload', payload)
            if(payload.status !== 'waiting_approval') {
                state.pendings = state.pendings.filter(pending => pending.id != payload.id)
            }
        },
        [updateQuestion.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [updateStatus.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [updateStatus.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            console.log('payload', payload)
            if(payload.status !== 'waiting_approval') {
                state.pendings = state.pendings.filter(pending => pending.id != payload.id)
            }
        },
        [updateStatus.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [deleteQuestion.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [deleteQuestion.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            console.log('payload', payload)
            state.pendings = state.pendings.filter(item => item.id !== payload.id)
            state.approveds = state.approveds.filter(item => item.id !== payload.id)
            state.rejecteds = state.rejecteds.filter(item => item.id !== payload.id)
        },
        [deleteQuestion.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [updateAnswer.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [updateAnswer.fulfilled]: (state, { payload }) => {
            let index
            state.loading = false
            state.success = true
            index = state.pendings.findIndex(item => item.id === payload)
            if(index > -1) {
                state.pendings[index] = payload
            }
            index = state.rejecteds.findIndex(item => item.id === payload.id)
            if(index > -1) {
                state.rejecteds[index] = payload
            }
            index = state.questions.findIndex(item => item.id === payload.id)
            if(index > -1) {
                state.questions[index] = payload
            }
        },
        [updateAnswer.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        }
    }
})

export const { resetState, setSelectedEarning, removeSelectedEarning, addToCroppeds, removeFromCroppeds, setAnswer, changeAnswer, resetEarnings, getLastApproveds} = questionSlice.actions

export default questionSlice.reducer