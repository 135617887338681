import React, {useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {ToastContainer, toast} from 'react-toastify'
import {Form, Button, Spinner, Badge, InputGroup} from 'react-bootstrap'
import {useForm, Controller} from 'react-hook-form'
import Select from 'react-select'
import {setSelectedEarning} from '../../slices/questions/questionSlice'
import {getParentSections, getSections, getSubSections} from '../../slices/sections/sectionActions'
import {getSubjectsWithEarnings} from '../../slices/writer/writerActions'
import {getDifficultyLevels} from '../../slices/difficultyLevels/difficultyLevelActions'
import {getTypes} from '../../slices/types/typeActions'
import {getLevels} from '../../slices/levels/levelActions'
import {getLessons} from '../../slices/lessons/lessonActions'
import {getPools} from '../../slices/pools/poolActions'
import {uploadSolutionVideo} from '../../slices/uploader/uploaderActions'
import {updateQuestion, updateStatus} from '../../slices/questions/questionActions'
import {resetSections} from '../../slices/sections/sectionSlice'
import {resetEarnings, removeSelectedEarning} from '../../slices/questions/questionSlice'
import CollapsibleItem from '../common/CollapsibleItem'
import {BiCloudUpload, BiCloudDownload, BiX} from 'react-icons/bi'
import {TfiArrowCircleDown, TfiArrowCircleUp} from 'react-icons/tfi'
import 'react-toastify/dist/ReactToastify.min.css'
import moment from 'moment/min/moment-with-locales'
import {logout} from '../../slices/auth/authActions'
import {resetUploader} from '../../slices/uploader/uploaderSlice'
import CreatableSelect from 'react-select/creatable'
import {flattenDeep} from 'lodash'

const EditQuestionModal = ({ show, handleClose, handleSave, activeQuestion, activeIndex }) => {
    const questionSchema = yup.object({
        status: yup
            .string()
            .nullable()
            .required('Sorunun onay/red durumu seçilmelidir!'),
        difficulty: yup
            .string()
            .nullable()
            .required('Zorluk seviyesi seçilmelidir!'),
        type: yup
            .string()
            .nullable()
            .required('Soru tipi seçilmelidir!'),
        row_type: yup
            .string()
            .required('Soru sütun tipi seçilmelidir!'),
        root: yup
            .string()
            .nullable()
            .notRequired()
            .min('5', 'Kök bilgisi çok kısa!'),
        tags: yup
            .array()
            .min(1, 'En az 1 etiket girilmelidir!'),
        solution_video_link: yup
            .string()
            .required('Soru çözüm bağlantısı girilmelidir!')
            .url('Geçerli bir bağlantı girilmelidir!'),
        topic_explanation_link: yup
            .string()
            .nullable()
            .notRequired()
            .url('Geçerli link girilmelidir!'),
        vimeo_link: yup
            .string()
            .nullable()
            .notRequired()
            .url('Geçerli link girilmelidir!'),
        zoom_link: yup
            .string()
            .nullable()
            .notRequired()
            .url('Geçerli link girilmelidir!'),
        answer: yup
            .string()
            .required('Cevap bilgisi seçilmelidir!'),
        earnings: yup
            .array()
            .min(1, 'En az 1 kazanım seçilmelidir!'),
        questionPools: yup
            .array()
            .min(1, 'En az 1 seçenek seçmelisiniz!'),
        parentSections: yup
            .array()
            .min(1, 'En az 1 ana bölüm seçmelisiniz!'),
        sections: yup
            .array()
            .min(1, 'En az 1 bölüm seçmelisiniz!'),
        lessons: yup
            .array()
            .min(1, 'En az 1 ders seçmelisiniz!'),
        questionLevels: yup
            .array()
            .min(1, 'En az 1 seçenek seçmelisiniz!'),
        rejectComment: yup
            .string()
            .nullable()
            .when('status', {
                is: 'rejected' && !!activeQuestion?.rejectReasons,
                then: yup
                    .string()
                    .required('Red açıklamasını girmelisiniz!')
            })
    })

    const {token, user} = useSelector((state) => state.auth)
    const {loading, error, question, success, errorCode, earnings: earningArr, pendings} = useSelector((state) => state.question)
    const {loading: sectionLoading, parents, sections, subSections} = useSelector((state) => state.section)
    const {loading: writerLoading, subjects} = useSelector((state) => state.writer)
    const {loading: difficultyLevelLoading, difficultyLevels} = useSelector((state) => state.difficultyLevel)
    const {loading: typesLoading, types} = useSelector((state) => state.type)
    const {loading: levelLoading, levels} = useSelector((state) => state.level)
    const {loading: videoLoading, video} = useSelector((state) => state.uploader)
    const {lessons} = useSelector((state) => state.lesson)
    const {pools} = useSelector((state) => state.pool)

    const [modalLoading, setModalLoading] = useState(false)
    const [parentsArr, setParentsArr] = useState([])
    const [sectionsArr, setSectionsArr] = useState([])
    const [subSectionsArr, setSubSectionsArr] = useState([])
    const [categoriesArr, setCategoriesArr] = useState([])
    const [tags, setTags] = useState([])
    const [answer, setAnswer] = useState(null)

    const [selectedLessons, setSelectedLessons] = useState(null)
    const [selectedLevels, setSelectedLevels] = useState([])
    const [selectedPools, setSelectedPools] = useState([])
    const [selectedParentSection, setSelectedParentSection] = useState(null)
    const [selectedSection, setSelectedSection] = useState(null)
    const [selectedSubSection, setSelectedSubSection] = useState([])
    const [showLoader, setShowLoader] = useState(false)
    const [videoLoaded, setVideoLoaded] = useState(true)
    const [selectedVideo, setSelectedVideo] = useState({
        file: null,
        preview: null,
        duration: 0,
        size: 0,
    })
    const [solutionVideoLink, setSolutionVideoLink] = useState(null)
    const [selectedDifficulty, setSelectedDifficulty] = useState(null)
    const [selectedType, setSelectedType] = useState(null)
    const [showVideo, setShowVideo] = useState(false)
    const [selectedStatus, setSelectedStatus] = useState({})
    const [rejectReason,  setRejectReason] = useState([])
    const [selectedEarnings, setSelectedEarnings] = useState([])
    const [nextQuestion, setNextQuestion] = useState(null)

    const sectionRef = useRef(null)
    const subSectionRef = useRef(null)
    const lessonsRef = useRef(null)
    const solutionVideoRef = useRef(null)

    const { clearErrors, reset, register, handleSubmit, control, setValue, getValues, formState: { isValid, isSubmitted, errors }} = useForm({
        resolver: yupResolver(questionSchema),
        defaultValues: {
            status: activeQuestion?.status,
            difficulty: activeQuestion?.difficulty,
            type: activeQuestion?.type,
            questionLevels: activeQuestion?.levels.map(item => (item.id)),
            row_type: activeQuestion?.rowType,
            root: activeQuestion?.root,
            solution_video_link: activeQuestion?.solutionVideoLink,
            topic_explanation_link: activeQuestion?.topicExplanationLink,
            vimeo_link: activeQuestion?.vimeoLink,
            answer: activeQuestion?.answer,
            // tags: activeQuestion?.tags.map(item => (item.title)),
            earnings: activeQuestion?.earnings,
            lessons: activeQuestion?.lessons,
            questionPools: activeQuestion?.pools.map(item => (item.id)),
            parentSections: activeQuestion?.parent_sections,
            sections: activeQuestion?.sections,
            subSections: activeQuestion?.sub_sections.map(item => (item.id)),
            rejectComment: '',
        }
    })

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const styles = {
        invalid: {
            display: errors.earnings ? 'block' : 'none'
        }
    }

    useEffect(() => {
        setModalLoading(false)
        const clearForm = () => {
            clearErrors()
            reset()
            setVideoLoaded(false)
            setSolutionVideoLink(null)
            setValue('solution_video_link', null)
            setSelectedVideo({
                file: null,
                preview: null,
                duration: 0,
                size: 0,
            })
            setTags([])
            dispatch(resetEarnings())
        }
        const showTags = async () => {
            setTags([])
            if(activeQuestion?.tags.length > 0) {
                activeQuestion?.tags.map(tag => {
                    console.log('Tag', tag)
                    setTags(prevState => [...prevState, tag.title])
                    console.log('Tags', tags)
                })
            }
        }
        const getData = async () => {
            await dispatch(getDifficultyLevels({token}))
            await dispatch(getTypes({token}))
            await dispatch(getLevels({token}))
            await dispatch(getParentSections({token}))
            await dispatch(getLessons({token}))
            await dispatch(getPools({token}))
            await showTags()
        }

        if(!show) {
            clearForm()
        } else {
            // setParentsArr(user?.sections.filter(section => section.parent_id === 0).map(item => ({ label: item.full_section_title,  value: item.id })))
            // dispatch(getSubjectsWithEarnings({token, writer: user?.id, lessons: user?.lessons}))
            getData().catch(console.error)
            setAnswer(activeQuestion.answer)
            setSelectedStatus(statusList.filter(status => status.value === activeQuestion?.status))
            if(activeQuestion.earnings.length > 0) {
                activeQuestion.earnings.map(earning => dispatch(setSelectedEarning(earning)))
            } else {
                activeQuestion.subjects.map(subject => dispatch(setSelectedEarning(subject)))
            }
            setSelectedVideo({
                file: null,
                preview: activeQuestion?.solutionVideoLink,
                duration: 0,
                size: 0,
            })
            console.log('user', user)
        }
        // dispatch(resetEarnings())

    }, [show])

    useEffect(() => {
        console.log('error', error)
        if(error) {
            if(Array.isArray(error.error) || typeof error.error === 'object') {
                Object.keys(error.error).map((err, index) => (
                    // console.log(error.error[err][0])
                    toast(error.error[err][0], { type: 'error' })
                ))
            } else {
                console.log('Error: ', error.error)
                toast(error.error, { type: 'error' })
            }
        }
    }, [error])

    useEffect(() => {
        if(success) {
            toast('İşlem başarılı!', { type: 'success' })
            setNextQuestion(pendings[activeIndex])
            // handleClose()
            resetForm()
            handleSave()
        }
    }, [success])

    useEffect(() => {
        console.log('nextQuestion', nextQuestion)
    }, [nextQuestion])

    useEffect(() => {
        if(errorCode) {
            console.log('Code', errorCode)
            if(errorCode === 401) {
                dispatch(logout({token}))
            }
        }
    }, [errorCode])

    useEffect(() => {
        if(show) {
            setSelectedDifficulty(difficultyLevels.filter(item => item.id == activeQuestion?.difficulty).map(item => ({value: item.id, label: item.title})))
        }
    }, [difficultyLevels, show])

    useEffect(() => {
        if(show) {
            setSelectedType(types.filter(item => item.id == activeQuestion?.type).map(item => ({value: item.id, label: item.title})))
        }
    }, [types, show])

    useEffect(() => {
        if(show) {
            setSelectedLessons(activeQuestion?.lessons.map(item => ({value: item.id, label: item.title})))
        }
    }, [lessons, show])

    useEffect(() => {
        if(show) {
            setSelectedLevels(activeQuestion?.levels.map(item => ({value: item.id, label: item.title})))
        }
    }, [levels, show])

    useEffect(() => {
        if(show) {
            setSelectedPools(activeQuestion?.pools.map(item => ({value: item.id, label: item.title})))
        }
    }, [levels, show])

    useEffect(() => {
        if(show) {
            setSelectedParentSection(activeQuestion?.parent_sections.map(item => ({value: item.id, label: item.title})))
        }
    }, [parents, show])

    useEffect(() => {
        if(show) {
            setSelectedSection(activeQuestion?.sections.map(item => ({value: item.id, label: item.title})))
        }
    }, [sections, show])

    useEffect(() => {
        if(show) {
            setSelectedSubSection(activeQuestion?.sub_sections.map(item => ({value: item.id, label: item.title})))
        }
    }, [subSections, show])

    useEffect(() => {
        if(earningArr.length > 0) {
            console.log('earningArr', earningArr)
            setValue('earnings', earningArr)
        } else {
            console.log('earningArr', earningArr)
        }
    }, [earningArr])

    useEffect(() => {
        if(subjects?.length > 0 && selectedLessons) {
            const elements = searchEarnings(activeQuestion?.earnings)
            setSelectedEarnings(elements)
        }
    }, [subjects])

    const searchEarnings = (data) => {
        const result = subjects.map((subject) => {
            return {
                ...subject,
                children: subject.children.map((child) => {
                    return {
                        ...child,
                        earnings: child.earnings.map((earning) => {
                            return {
                                ...earning,
                                selected: data.some(item => item.id == earning.id)
                            }
                        })
                    }
                })
            }
        })
        return result
    }


    useEffect(() => {
        if(answer) {
            setValue('answer', answer)
        }
    }, [answer])

    useEffect(() => {
        console.log('errors', errors)
        if(Object.keys(errors).length > 0) {
            toast('Formda hata var!', { type: 'error' })
            /*if(errors) {
                if(Array.isArray(error.error) || typeof error.error === 'object') {
                    Object.keys(error.error).map((err, index) => (
                        // console.log(error.error[err][0])
                        toast(error.error[err][0], { type: 'error' })
                    ))
                } else {
                    console.log('Error: ', error.error)
                    toast(error.error, { type: 'error' })
                }
            }*/
        }
    }, [errors])

    const submitForm = async (data) => {
        if(user.roles.includes('editor')) {
            data = { ...data, id: activeQuestion.id, 'writer_id' : activeQuestion.writer.id,
                'approving_user_id' : user?.id, 'reject_reason': data.status == 'rejected' ? {user_id: user.id, reason: data.rejectComment} : null}
            console.log('data', data)

            await dispatch(updateStatus({
                token,
                question: data,
                id: activeQuestion.id
            }))
        } else {
            data = { ...data, id: activeQuestion.id, 'writer_id' : activeQuestion.writer.id,
                    'reject_reason': data.status == 'rejected' ? {user_id: user.id, reason: data.rejectComment} : null}
            console.log('data', data)

            await dispatch(updateQuestion({
                token,
                question: data,
                id: activeQuestion.id
            }))
        }

    }
    const handleCloseModal = (event) => {
        event.preventDefault()
        resetForm()
        handleClose()
    }
    const resetForm = () => {
        clearErrors()
        reset()
        setVideoLoaded(false)
        setSolutionVideoLink(null)
        setValue('solution_video_link', null)
        setSelectedVideo({
            file: null,
            preview: null,
            duration: 0,
            size: 0,
        })
        dispatch(resetEarnings())
        console.log('arr', earningArr)
        dispatch(resetUploader())
    }
    const handlePoolChange = (selected) => {
        setValue('questionPools', Array.isArray(selected) ? selected.map(x => x.value) : [])
        setSelectedPools(selected.map(item => ({ label: item.label, value: item.value })))
    }

    const poolList = pools.map(pool => (
        { value: pool.id, label: pool.title  }
    ))

    const parentSectionList = parents.map(section => (
        { value: section.id, label: section.title }
    ))

    const handleParentSectionChange = async (selected) => {
        if(selected.length === 0) {
            dispatch(resetSections())
            sectionRef.current.clearValue()
            subSectionRef.current.clearValue()
        } else {
            const selectedParentSection = selected[selected.length - 1].value
            await dispatch(getSubSections({ token, id: selectedParentSection }))
            setValue('parentSection', Array.isArray(selected) ? selected.map(x => x.value) : [])
        }
    }

    let sectionList = sections?.map(section => (
        { value: section.id, label: section.fullSectionTitle }
    ))

    const handleSectionChange = (selected) => {
        setValue('sections', Array.isArray(selected) ? selected.map(x => x.value) : [])
        setSelectedSection(selected.map(item => ({ label: item.label, value: item.value })))
        setSubSectionsArr(user?.subSections.filter(({parent_id}) => selected.some(parent => parent.value = parent_id)).map(item => ({label: item.title, value: item.id})))
        dispatch(getSubSections({ token, parents: getValues().sections }))

        if(selected.length === 0) {
            setValue('subSections', [])
            subSectionRef.current.clearValue()
        }
    }

    let subSectionList = subSections?.map(sub => (
        { value: sub.id, label: sub.fullSectionTitle }
    ))
    const handleSubSectionChange = (selected) => {
        setValue('subSections', Array.isArray(selected) ? selected.map(x => x.value) : [])
        setSelectedSubSection(selected.map(item => ({ label: item.label, value: item.value })))
    }

    const difficultyLevelList = difficultyLevels?.map(level => (
        {value: level.id, label: level.title}
    ))

    let lessonList = lessons?.map(lesson => (
        { value: lesson.id, label: lesson.title  }
    ))

    const handleLessonChange = (selected) => {
        /*setValue('lessons', Array.isArray(selected) ? selected.map(x => x.value) : [])
        setSelectedLessons(selected.map(item => ({ label: item.label, value: item.value })))*/
        setValue('lessons', selected.value)
        setSelectedLessons({ label: selected.label, value: selected.value })
    }

    useEffect(() => {
        // const lessonArr = Array.isArray(selectedLessons) ? selectedLessons.map(x => x.value) : []
        console.log('lessons', selectedLessons)
        console.log('section', selectedParentSection)
        console.log('subSection', selectedSection)
        if(selectedLessons && selectedParentSection && selectedSection) {
            dispatch(getSubjectsWithEarnings({token, writer: user?.id, lesson: selectedLessons[0].value, section: selectedParentSection[0].value, subSection: selectedSection[0].value}))
        }
    }, [selectedSection])

    const handleDifficultyChange = (selected) => {
        setValue('difficulty', selected.value)
    }

    const typeList = types?.map(type => (
        {value: type.id, label: type.title}
    ))

    const handleTypeChange = (selected) => {ted
        setValue('type', selected.value)
    }

    const levelList = levels?.map(level => (
        {value: level.id, label: level.title}
    ))

    const handleLevelChange = (selected) => {
        setValue('questionLevels', Array.isArray(selected) ? selected.map(x => x.value) : [])
        setSelectedLevels(selected.map(item => ({ label: item.label, value: item.value })))
    }

    useEffect(() => {
        console.log('tags', tags)
        setValue('tags', tags)
    }, [tags])

    const removeTag = (index) => {
        setTags(tags.filter((el, i) => i !== index))
    }

    const handleKeyDown = (e) => {
        if(e.key !== 'Enter') return
        const value = e.target.value
        if(!value.trim()) return
        setTags([...tags, value])
        e.target.value = ''
        e.preventDefault()
    }

    useEffect(() => {
        console.log('selectedVideo', selectedVideo)
    }, [selectedVideo])

    const convertHMS = (value) => {
        const sec = parseInt(value, 10); // convert value to number if it's string
        let hours = Math.floor(sec / 3600); // get hours
        let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
        let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
        // add 0 if value < 10; Example: 2 => 02
        if (hours < 10) {
            hours = '0' + hours;
        }
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        if (seconds < 10) {
            seconds = '0' + seconds;
        }
        return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
    }

    const previewVideo = (e) => {
        setShowLoader(true)
        let preview = null
        const video = e.target.files[0]
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = (readerEvent) => {
                let audio = new Audio(readerEvent.target.result)
                audio.onloadedmetadata = () => {
                    resolve(convertHMS(audio.duration))
                }
                preview = readerEvent.target.result
            }
            reader.readAsDataURL(video)
        })
            .then((duration) => {
                setSelectedVideo({ file: video, preview, duration, size: video?.size })
                setShowLoader(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const fileClickHandler = (event) => {
        event.preventDefault()
        solutionVideoRef.current.click()
    }

    const showOrHide = () => {
        setShowVideo(!showVideo)
    }

    const clearVideo = (event) => {
        event.preventDefault()
        setSelectedVideo({
            file: null,
            preview: null,
            duration: 0,
            size: 0,
        })
    }

    const handleUpload = async (event) => {
        setShowLoader(true)
        event.preventDefault()
        const data = new FormData()
        data.append('video', selectedVideo.file)
        await dispatch(uploadSolutionVideo({ token, file: data }))
        setShowLoader(false)
    }

    const handleDelete = (event) => {
        event.preventDefault()
        setVideoLoaded(false)
        setSolutionVideoLink(null)
        setValue('solution_video_link', null)
        setSelectedVideo({
            file: null,
            preview: null,
            duration: 0,
            size: 0,
        })
    }

    useEffect(() => {
        let data = JSON.parse(JSON.parse(video))
        if(data?.status) {
            setVideoLoaded(true)
            setValue('solution_video_link', data.data?.direct_link)
            setSolutionVideoLink(data.data?.direct_link)
        }
    }, [video])

    const statusList = [
        {value: 'waiting_approval', label: 'Bekliyor'},
        {value: 'approval', label: 'Onaylandı'},
        {value: 'rejected', label: 'Reddedildi'}
    ]

    const handleStatusChange = (selected) => {
        setValue('status', selected.value)
        const status = {
            value: selected.value,
            label: selected.label
        }
        console.log('statusObj', status)
        setSelectedStatus(status)
    }

    useEffect(() => {
        if(selectedStatus && selectedStatus.value == 'rejected') {
            setRejectReason([...rejectReason, {
                id: null,
                reason: '',
                user
            }])
        } else {
            let copy = [...rejectReason]
            copy.pop()
            setRejectReason(copy)
        }
    }, [selectedStatus])

    useEffect(() => {
        console.log('Reject Reason', rejectReason)
    }, [rejectReason])

    const addComment = (e) => {
        e.preventDefault()
        setRejectReason([...rejectReason, {
            id: null,
            reason: '',
            user
        }])
    }

    const removeComment = (e) => {
        e.preventDefault()
        let copy = [...rejectReason]
        copy.pop()
        setRejectReason(copy)
    }

    const removeEarning = (data, index) => {
        console.log('data', data)
        console.log('index', index)
        dispatch(removeSelectedEarning(index))
    }


    return (
        <>
            <div className={ 'modal fade' + (show ? 'show' : '')} id="error-modal" tabIndex="-1" role="dialog" aria-hidden="true" style={{ display: show ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered modal-fullscreen" role="document">
                    <div className="modal-content position-relative">
                        <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                            <button
                                onClick={handleClose}
                                className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-0">
                            <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                                <h4 className="mb-1" id="modalExampleDemoLabel">Soru Düzenle </h4>
                            </div>
                            <div className="p-4 pb-0">
                                { !modalLoading
                                    ? <Form noValidate onSubmit={handleSubmit(submitForm)} className='row g-3'>
                                        <div className="col-md-6">
                                            {user?.roles.includes('editor') &&
                                                <div className="mb-1">
                                                <label className="col-form-label" htmlFor="question-image">Soruyu Ekleyen:</label>
                                                <div className='d-flex flex-column justify-content-center align-content-center align-items-center'>
                                                    <div className="card border w-100 border-primary">
                                                        <div className="card-body">
                                                            <ul className="list-group list-group-flush">
                                                                <li className="list-group-item"><span className='fw-bold'>Ad-Soyad: </span>{activeQuestion?.writer.name + ' ' + activeQuestion?.writer.lastName}</li>
                                                                <li className="list-group-item"><span className='fw-bold'>E-Posta: </span>{activeQuestion?.writer.email}</li>
                                                                <li className="list-group-item"><span className='fw-bold'>Telefon: </span>{activeQuestion?.writer.phone}</li>
                                                                <li className="list-group-item"><span className='fw-bold'>Eklendiği Tarih: </span>{moment(activeQuestion?.created_at).format('lll')}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="question-image">Soru Görseli:</label>
                                                <div className='d-flex flex-column justify-content-center align-content-center align-items-center'>
                                                    <figure className="figure" style={{ width: '300px', objectFit: 'contain' }}>
                                                        <img className="figure-img img-fluid rounded" src={process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PRODUCTION_STORAGE_URL + activeQuestion?.question : process.env.REACT_APP_API_DEVELOPMENT_URL + '/' + activeQuestion?.question} alt="..."/>
                                                    </figure>
                                                    <a className='btn btn-sm btn-info btn-block mb-2' href={process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PRODUCTION_STORAGE_URL + activeQuestion?.question : process.env.REACT_APP_API_DEVELOPMENT_URL + '/' + activeQuestion?.question} download>
                                                        <BiCloudDownload size={25} color='white' /> İndir
                                                    </a>
                                                    <div className="container d-flex justify-content-center align-content-center align-items-center">
                                                        <div className="btn-toolbar">
                                                            <button
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    setAnswer('A')
                                                                }}
                                                                className={'btn btn-sm me-1 ' + (answer === 'A' ? 'btn-success' : 'btn-danger')}>A</button>
                                                            <button
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    setAnswer('B')
                                                                }}
                                                                className={'btn btn-sm me-1 ' + (answer === 'B' ? 'btn-success' : 'btn-danger')}>B</button>
                                                            <button
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    setAnswer('C')
                                                                }}
                                                                className={'btn btn-sm me-1 ' + (answer === 'C' ? 'btn-success' : 'btn-danger')}>C</button>
                                                            <button
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    setAnswer('D')
                                                                }}
                                                                className={'btn btn-sm me-1 ' + (answer === 'D' ? 'btn-success' : 'btn-danger')}>D</button>
                                                            <button
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    setAnswer('E')
                                                                }}
                                                                className={'btn btn-sm me-1 ' + (answer === 'E' ? 'btn-success' : 'btn-danger')}>E</button>
                                                        </div>
                                                    </div>
                                                    <Form.Control
                                                        type="hidden"
                                                        {...register('answer')}
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.answer ? errors.answer.message : ''}
                                                    </Form.Control.Feedback>
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="question-solution">Çözüm Videosu:</label>
                                                <InputGroup className="mb-1">
                                                    <Form.Control
                                                        className="form-control"
                                                        id="question-solution"
                                                        type="text"
                                                        isValid={isSubmitted && !errors.solution_video_link}
                                                        isInvalid={isSubmitted && !!errors.solution_video_link}
                                                        {...register('solution_video_link')}
                                                    />
                                                    {!selectedVideo.preview &&
                                                        <button disabled={showLoader} onClick={fileClickHandler} className="btn btn-info" id="button-addon2">
                                                            <BiCloudUpload size={25} color='white' />
                                                        </button>
                                                    }
                                                    {selectedVideo.preview && !videoLoaded &&
                                                        <button disabled={showLoader} onClick={(event) => clearVideo(event)} className="btn btn-danger" id="button-addon2">
                                                            <BiX size={25} color='white' />
                                                        </button>
                                                    }
                                                    {!showVideo && selectedVideo.preview &&
                                                    <button onClick={showOrHide} className="btn btn-success" id="button-addon2">
                                                        <TfiArrowCircleUp size={25} color='white' />
                                                    </button>}
                                                    {showVideo && selectedVideo.preview &&
                                                        <button onClick={showOrHide} className="btn btn-success" id="button-addon2">
                                                            <TfiArrowCircleDown size={25} color='white' />
                                                        </button>}
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.solution_video_link ? errors.solution_video_link.message : ''}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                                <input ref={solutionVideoRef} accept="video/*" onChange={previewVideo} onClick={(event) => { event.target.value = null }} type="file" hidden />
                                                <div className="preview w-100 d-flex flex-column justify-content-center align-items-center">
                                                    {showVideo && selectedVideo.preview &&
                                                        <>
                                                            <video width='100%' controls src={selectedVideo.preview}></video>
                                                            {user?.roles.includes('writer') &&
                                                                <div className="d-grid gap-2">
                                                                    {videoLoaded ?
                                                                        <button disabled={showLoader} onClick={handleDelete} className="btn btn-danger mt-2">Sil</button>
                                                                        :
                                                                        <button disabled={showLoader} onClick={handleUpload} className="btn btn-success mt-2">Yükle</button>
                                                                    }
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                    {showLoader &&
                                                        <div className="spinner-grow text-primary" role="status">
                                                            <span className="visually-hidden">Yükleniyor...</span>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="question-difficulty">Zorluk Seviyesi:</label>
                                                <Controller
                                                    name='difficulty'
                                                    control={control}
                                                    render={({ onChange, value, ref }) => (
                                                        <>
                                                            <Select
                                                                id='question-difficulty'
                                                                className={'form-control' + (errors.difficulty ? ' is-invalid' : isSubmitted ? ' is-valid' : '')}
                                                                placeholder='Zorluk Seviyesi Seçin...'
                                                                noOptionsMessage='Veri Yok'
                                                                value={selectedDifficulty}
                                                                options={difficultyLevelList}
                                                                onChange={handleDifficultyChange}
                                                            />
                                                            <Form.Control.Feedback type='invalid'>
                                                                {errors.difficulty ? errors.difficulty.message : ''}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="question-type">Soru Tipi:</label>
                                                <Controller
                                                    name='type'
                                                    control={control}
                                                    render={({ onChange, value, ref }) => (
                                                        <>
                                                            <Select
                                                                id='question-type'
                                                                className={'form-control' + (errors.type ? ' is-invalid' : isSubmitted ? ' is-valid' : '')}
                                                                isSearchable
                                                                placeholder='Soru Tipi Seçin...'
                                                                noOptionsMessage='Veri Yok'
                                                                value={selectedType}
                                                                options={typeList}
                                                                onChange={handleTypeChange}
                                                            />
                                                            <Form.Control.Feedback type='invalid'>
                                                                {errors.type ? errors.type.message : ''}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="question-root">Soru Kökü:</label>
                                                <Form.Control
                                                    className="form-control"
                                                    id="question-root"
                                                    type="text"
                                                    isValid={isSubmitted && !errors.root}
                                                    isInvalid={isSubmitted && !!errors.root}
                                                    {...register('root')}
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.root ? errors.root.message : ''}
                                                </Form.Control.Feedback>
                                            </div>
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="question-row-type">Soru Sütun Yapısı:</label>
                                                <Form.Check
                                                    inline
                                                    className="form-control"
                                                    id="question-row-type-1"
                                                    type="radio"
                                                    value='single'
                                                    label='Tek Sütun'
                                                    {...register('row_type')}
                                                />
                                                <Form.Check
                                                    inline
                                                    className="form-control"
                                                    id="question-row-type-2"
                                                    type="radio"
                                                    value='multiple'
                                                    label='Çift Sütun'
                                                    {...register('row_type')}
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.root ? errors.row_type.message : ''}
                                                </Form.Control.Feedback>
                                            </div>
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="question-tags">Etiketler:</label>
                                                <Controller
                                                    name='tags'
                                                    control={control}
                                                    render={({ onChange, value, ref }) => (
                                                        <div className="tags-input-container">
                                                            { tags.map((tag, index) => (
                                                                <div className="tag-item" key={index}>
                                                                    <span className="text">{tag}</span>
                                                                    <span
                                                                        className="close"
                                                                        onClick={() => removeTag(index)}>&times;</span>
                                                                </div>
                                                            ))
                                                            }
                                                            <Form.Control
                                                                className="form-control"
                                                                id="question-tags"
                                                                type="text"
                                                                isValid={isSubmitted && !errors.tags}
                                                                isInvalid={isSubmitted && !!errors.tags}
                                                                onKeyDown={handleKeyDown}
                                                            />
                                                            <Form.Control.Feedback type='invalid'>
                                                                {errors.tags ? errors.tags.message : ''}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="question-style">İlgili Ders:</label>
                                                <Controller
                                                    name='lessons'
                                                    control={control}
                                                    render={({ onChange, value, ref }) => (
                                                        <>
                                                            <Select
                                                                ref={lessonsRef}
                                                                id='lessons'
                                                                className={'form-control' + (errors.lessons ? ' is-invalid' : isSubmitted ? ' is-valid' : '')}
                                                                isMulti
                                                                isSearchable
                                                                placeholder='Ders Listesi...'
                                                                options={lessonList}
                                                                value={selectedLessons}
                                                                onChange={handleLessonChange}
                                                            />
                                                            <Form.Control.Feedback type='invalid'>
                                                                {errors.lessons ? errors.lessons.message : ''}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="question-levels">İlgili Düzey:</label>
                                                <Controller
                                                    name='questionLevels'
                                                    control={control}
                                                    render={({ onChange, value, ref }) => (
                                                        <>
                                                            <Select
                                                                id='levels'
                                                                className={'form-control' + (errors.questionLevels ? ' is-invalid' : isSubmitted ? ' is-valid' : '')}
                                                                isMulti
                                                                isSearchable
                                                                placeholder='Düzey Listesi...'
                                                                options={levelList}
                                                                value={selectedLevels}
                                                                onChange={handleLevelChange}
                                                            />
                                                            <Form.Control.Feedback type='invalid'>
                                                                {errors.questionLevels ? errors.questionLevels.message : ''}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="question-style">Soru Havuzu:</label>
                                                <Controller
                                                    name='questionPools'
                                                    control={control}
                                                    render={({ onChange, value, ref }) => (
                                                        <>
                                                            <Select
                                                                id='question-pools'
                                                                className={'form-control' + (errors.questionPools ? ' is-invalid' : isSubmitted ? ' is-valid' : '')}
                                                                isMulti
                                                                isSearchable
                                                                placeholder='Soru Havuzu Listesi....'
                                                                options={poolList}
                                                                value={selectedPools}
                                                                onChange={handlePoolChange}
                                                            />
                                                            <Form.Control.Feedback type='invalid'>
                                                                {errors.questionPools ? errors.questionPools.message : ''}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="parent-section">Ana Bölüm:</label>
                                                <Controller
                                                    name='parentSections'
                                                    control={control}
                                                    render={({ onChange, value, ref }) => (
                                                        <>
                                                            <Select
                                                                id='question-parent-sections'
                                                                className={'form-control' + (errors.parentSections ? ' is-invalid' : isSubmitted ? ' is-valid' : '')}
                                                                isMulti
                                                                isSearchable
                                                                placeholder='Ana Bölüm Listesi...'
                                                                options={parentSectionList}
                                                                value={selectedParentSection}
                                                                onChange={handleParentSectionChange}
                                                            />
                                                            <Form.Control.Feedback type='invalid'>
                                                                {errors.parentSections ? errors.parentSections.message : ''}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="section">Bölüm:</label>
                                                <Controller
                                                    name='sections'
                                                    control={control}
                                                    render={({ onChange, value, ref }) => (
                                                        <>
                                                            <Select
                                                                ref={sectionRef}
                                                                id='writer-sections'
                                                                className={'form-control' + (errors.sections ? ' is-invalid' : isSubmitted ? ' is-valid' : '')}
                                                                isMulti
                                                                isSearchable
                                                                noOptionsMessage={() => 'Ana Bölüm Seçin...'}
                                                                placeholder='Bölüm Listesi...'
                                                                options={sectionList}
                                                                value={selectedSection}
                                                                onChange={handleSectionChange}
                                                            />
                                                            <Form.Control.Feedback type='invalid'>
                                                                {errors.sections ? errors.sections.message : ''}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="sub-section">Alt Bölüm:</label>
                                                <Controller
                                                    name='subSections'
                                                    control={control}
                                                    render={({ onChange, value, ref }) => (
                                                        <>
                                                            <Select
                                                                ref={subSectionRef}
                                                                id='sub-sections'
                                                                className={'form-control' + (errors.subSections ? ' is-invalid' : isSubmitted ? ' is-valid' : '')}
                                                                isMulti
                                                                isSearchable
                                                                noOptionsMessage={() => 'Bölüm Seçin...'}
                                                                placeholder='Alt Bölüm Listesi...'
                                                                options={subSectionList}
                                                                value={selectedSubSection}
                                                                onChange={handleSubSectionChange}
                                                            />
                                                            <Form.Control.Feedback type='invalid'>
                                                                {errors.subSections ? errors.subSections.message : ''}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="lessons">Üniteler/Kazanımlar:</label>
                                                <div className="row">
                                                    {(selectedEarnings?.length === 0 && selectedSection) &&(
                                                        <div className="d-flex flex-center">
                                                            <Spinner animation="grow" variant="primary" />
                                                        </div>
                                                    )}
                                                    {selectedEarnings?.length > 0 && subjects?.length > 0 && (
                                                        <>
                                                            <CollapsibleItem key={selectedLessons[0].label} lesson={selectedLessons[0]} subjects={selectedEarnings} />
                                                            <div className='col-xs-12 col-md-6 col-lg-6 col-xl-6'>
                                                                {earningArr.map((earning, index) => (
                                                                    <div className="alert alert-success alert-dismissible" role="alert">
                                                                        {earning.title}
                                                                        <button className="btn-close" type="button" onClick={() => removeEarning(earning, index)} aria-label="Close"></button>
                                                                    </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </>
                                                    )}
                                                    <div className='invalid-feedback ' style={styles.invalid}>
                                                        {errors.earnings ? errors.earnings.message : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <label className="col-form-label" htmlFor="question-status">Onay/Red Durumu:</label>
                                                <Controller
                                                    name='status'
                                                    control={control}
                                                    render={({ onChange, value, ref }) => (
                                                        <>
                                                            <Select
                                                                id='question-status'
                                                                className={'form-control' + (errors.status ? ' is-invalid' : isSubmitted ? ' is-valid' : '')}
                                                                placeholder='Onay/Red Durumunu Seçin...'
                                                                noOptionsMessage='Veri Yok'
                                                                value={selectedStatus}
                                                                options={statusList}
                                                                onChange={handleStatusChange}
                                                                isDisabled={user?.roles.includes('writer')}
                                                            />
                                                            <Form.Control.Feedback type='invalid'>
                                                                {errors.status ? errors.status.message : ''}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            {activeQuestion?.status == 'rejected' && activeQuestion?.rejectReasons.length > 0 &&
                                                activeQuestion.rejectReasons.map((reason, index) => (
                                                    <div key={'12' + index} className="flex-1 ms-2 fs--1  mt-3">
                                                        <p className="mb-1 bg-200 rounded-3 p-2">
                                                            <a href="#" className="fw-semi-bold">{reason.user.name + ' ' + reason.user.lastName}</a> {reason.reason}
                                                        </p>
                                                        {
                                                            index + 1 == activeQuestion?.rejectReasons.length &&
                                                            (
                                                                rejectReason.length == 0
                                                                    ?
                                                                    <div className="px-2">
                                                                        <a onClick={(e) => addComment(e)} href="#">Cevapla</a> • {moment(reason.created_at).format('lll')}
                                                                    </div>
                                                                    :
                                                                    <div className="px-2">
                                                                        <a onClick={(e) => removeComment(e)} href="#">İptal</a> • {moment(reason.created_at).format('lll')}
                                                                    </div>
                                                            )
                                                        }

                                                    </div>
                                                ))
                                            }
                                            {(getValues().status === 'rejected' && (rejectReason.length > 0 || activeQuestion.rejectReasons.length === 0)) && (
                                                    <div className="mb-1">
                                                        <label className="col-form-label" htmlFor="question-reject-reasons">Açıklama:</label>
                                                        <Controller
                                                            name='rejectReasons'
                                                            control={control}
                                                            render={({ onChange, value, ref }) => (
                                                                <>
                                                                    <Form.Control
                                                                        className="form-control"
                                                                        as='textarea'
                                                                        placeholder='Red açıklaması giriniz!'
                                                                        style={{height: '100px'}}
                                                                        /*isValid={isSubmitted && !errors.rejectReasons}
                                                                        isInvalid={isSubmitted && !!errors.rejectReasons}*/
                                                                        {...register('rejectComment')}
                                                                    />
                                                                    {<Form.Control.Feedback type='invalid'>
                                                                        {errors.rejectComment ? errors.rejectComment.message : ''}
                                                                    </Form.Control.Feedback>}
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                            )}
                                        </div>

                                        <div className="col-md-12 mb-2 d-flex justify-content-end">
                                            <Button
                                                className="btn btn-warning"
                                                type="submit"
                                                name='submit'
                                                disabled={loading}>
                                                {loading ?
                                                    <>
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        /> Bekleyin...
                                                    </>
                                                    : 'Güncelle'
                                                }
                                            </Button>
                                        </div>
                                    </Form>
                                    : <div><h3>Yükleniyor...</h3></div>
                                }

                            </div>
                        </div>
                        <div className="modal-footer d-flex justify-content-start">
                            <Button
                                onClick={handleCloseModal}
                                className="btn btn-secondary" type="button" data-bs-dismiss="modal">Kapat</Button>
                        </div>
                    </div>
                </div>
            </div>
            {
                show && (
                    <div className="modal-backdrop fade show"></div>
                )
            }
            <ToastContainer/>
        </>
    )
}

export default EditQuestionModal