import React from 'react'
import {useNavigate} from 'react-router-dom'
import DashboardLayout from '../components/layouts/DashboardLayout'

const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1)

    return (
        <DashboardLayout>
            <div className="row flex-center min-vh-100 py-6 text-center">
                <div className="col-sm-10 col-md-8 col-lg-6 col-xxl-5">
                    <a className="d-flex flex-center mb-4" href="#">
                        <img className="me-2" src="./img/logos/logo_corporate-colored2x.png" alt="" width=""/>
                    </a>
                    <div className="card">
                        <div className="card-body p-4 p-sm-5">
                            <div className="fw-black lh-1 text-300 fs-error">401</div>
                            <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">
                                Bu sayfaya erişme yetkiniz yok.</p>
                            <hr/>
                            <p>
                                Daha fazla destek için, <a href="mailto:info@vipkurum.com">adresinden bize ulaşın</a>.
                            </p>
                            <a
                                onClick={goBack}
                                className="btn btn-primary btn-sm mt-3"
                                href="#">
                                <span className="fas fa-home me-2"></span>Geri Dön
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default Unauthorized