import React, {useEffect, useMemo, useRef, useState} from 'react'
import DashboardLayout from '../../components/layouts/DashboardLayout'
import DataTable from 'react-data-table-component'
import {useSelector, useDispatch} from 'react-redux'
import {getWriters, deleteWriter} from '../../slices/writer/writerActions'
import {changeWriterStatus} from '../../slices/editor/editorActions'
import {AiFillEdit } from 'react-icons/ai'
import {BsPersonCheckFill, BsPersonXFill, BsTrashFill } from 'react-icons/bs'
import {IoMdRefresh} from 'react-icons/io'
import {Button, InputGroup, Form, Spinner, Badge} from 'react-bootstrap'
import moment from 'moment/min/moment-with-locales'
import CreateWriterModal from '../../components/editor/CreateWriterModal'
import EditWriterModal from '../../components/editor/EditWriterModal'
import ConfirmationModal from '../../components/common/ConfirmationModal'
import {toast} from 'react-toastify'
import {resetState} from '../../slices/writer/writerSlice'
import {getLessons} from '../../slices/lessons/lessonActions'
import {logout} from '../../slices/auth/authActions'

const FilterComponent = ({ searchText, onSearch, onClear, lessonList, onFilter }) => (
    <>
        <div className='d-flex justify-content-center align-content-center w-100'>
            <div className='w-50 p-3'>
                <Form.Select
                    className='form-control'
                    onChange={onFilter}>
                    <option value="0">Filtrele...</option>
                    {lessonList?.map(lesson => (
                        <option key={lesson.id} value={lesson.id}>{lesson.title}</option>
                    ))
                    }
                </Form.Select>
            </div>
            <div className='w-50 p-3'>
                <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="Ara..."
                        aria-label="Ara..."
                        aria-describedby="basic-addon2"
                        value={searchText}
                        onChange={onSearch}/>
                    <Button variant="outline-secondary" id="button-addon2" onClick={onClear}>
                        X
                    </Button>
                </InputGroup>
            </div>
        </div>
    </>
)

const paginationComponentOptions = {
    rowsPerPageText: 'Sayfa Başına Satır',
    rangeSeparatorText: '-',
}

const Writers = () => {
    const {lessons} = useSelector((state) => state.lesson)
    const {loading, writers, error, success, errorCode} = useSelector((state) => state.writer)
    const {loading: editorLoading, error: editorError, success: editorSuccess, errorCode: editorCode} = useSelector((state) => state.editor)
    const {token, user} = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const [showCreate, setCreateShow] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [activeWriter, setActiveWriter] = useState(null)
    const [filterText, setFilterText] = useState(0)
    const [searchText, setSearchText] = useState('')
    const [filteredData, setFilteredData] = useState([])
    const [orgData, setOrgData] = useState([])


    useEffect(() => {
        dispatch(getWriters({corporate: user.corporateId, token }))
        dispatch(getLessons({token}))
    }, [])

    useEffect(() => {
        if(success || editorSuccess) {
            toast('İşlem başarılı!', { type: 'success' })
        }
    }, [success, editorSuccess])


    useEffect(() => {
        if(error || editorError) {
            if(Array.isArray(error.error) || typeof error.error === 'object') {
                Object.keys(error.error).map((err, index) => (
                    console.log(error.error[err][0])
                    // toast(error.error[err][0], { type: 'error' })
                ))
            } else {
                console.log('Error: ', error.error)
                toast(error.error, { type: 'error' })
            }
        }
    }, [error, editorError])

    useEffect(() => {
        if(searchText) {
            const filteredWriters = writers.filter(
                item => (item.name && item.name.toLowerCase().includes(searchText.toLowerCase())) || item.lastname && item.lastname.toLowerCase().includes(searchText.toLowerCase()),
            )
            setFilteredData(filteredWriters)
        }
    }, [searchText])

    useEffect(() => {
        if(filterText > 0) {
            const filteredWriters = writers.filter(writer => writer.writer_lessons.some(lesson => lesson.id == filterText))
            setFilteredData(filteredWriters)
        } else {
            setFilterText(0)
            setFilteredData(orgData)
        }

    }, [filterText])

    useEffect(() => {
        if(writers) {
            setFilteredData(writers)
            setOrgData(writers)
        }
    }, [writers])

    useEffect(() => {
        if(errorCode) {
            console.log('Code', errorCode)
            if(errorCode === 401) {
                dispatch(logout({token}))
            }
        }
    }, [errorCode])

    const handleConfirmModal = (data) => {
        setActiveWriter(data)
        setShowConfirm(true)
    }

    const handleDelete = async () => {
        await dispatch(deleteWriter({ token, id: activeWriter.id }))
    }

    const handleEditModal = (data) => {
        console.log(data)
        setActiveWriter(data)
        setShowEdit(true)
    }

    const handleCreateModal = () => {
        setCreateShow(true)
    }

    const handleCloseModal = () => {
        setCreateShow(false)
        setShowConfirm(false)
        setShowEdit(false)
        setActiveWriter(null)
        dispatch(resetState())
    }

    const handleRefresh = async () => {
        dispatch(getWriters({corporate: user.corporateId, token }))
    }


    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if(searchText) {
                setSearchText('')
                setFilteredData(orgData)
            }
        }
        return (
            <FilterComponent
                onFilter={e => {
                    console.log('e', e.target.value)
                    setFilterText(e.target.value)
                    // setFilteredData(orgData)
                }}
                onSearch={e => setSearchText(e.target.value)} onClear={handleClear} searchText={searchText} lessonList={lessons} />
        )
    }, [searchText, lessons])

    const columns = [
        {
            name: 'Kayıt Tarihi',
            // selector: row =>moment(row.created_at).format('lll'),
            selector: row => moment(row.registeredAt).format('lll'),
            sortable: true
        },
        {
            name: 'Ad-Soyad',
            selector: row => row.name + ' ' + row.lastName,
            sortable: true
        },
        {
            name: 'E-Posta',
            selector: row => row.email,
            sortable: true
        },
        {
            name: 'Dersler',
            selector: row => row?.lessons.length > 0 ? (
                <div>
                    {row?.lessons.map((lesson) => (
                        <div>
                            <Badge key={lesson.id} bg='success'>{lesson.title}</Badge>
                        </div>
                    ))}
                </div>
            ) : '-',
            sortable: true
        },
        {
            name: 'Durum',
            center: true,
            cell: row => (
                row.status == 1 ? <BsPersonCheckFill onClick={() => changeStatus(row.id, row.status)} role='button' size={22} color='green' /> : <BsPersonXFill onClick={() => changeStatus(row.id, row.status)} role='button' size={22} color='red' />
            )
        },
        {
            name: 'İşlemler',
            cell: row => (
                <>
                    <button className="btn btn-falcon-danger me-1 mb-1" type="button" onClick={() => handleConfirmModal(row)}>
                        <BsTrashFill size={20} color='red' />
                    </button>
                    <button className="btn btn-falcon-warning me-1 mb-1" type="button" onClick={() => handleEditModal(row)}>
                        <AiFillEdit size={20} color='blue' />
                    </button>
                </>
            )
        }
    ]

    const changeStatus = async (writer, status) => {
        console.log(writer)
        console.log(status)
        await dispatch(changeWriterStatus({token, writer, status}))
        await handleRefresh()
    }

    return (
        <DashboardLayout>
            <div className="card mb-3">
                <div className="card-header">
                    <div className="row flex-between-end">
                        <div className="col-auto align-self-center">
                            <h5 className="mb-0" data-anchor="data-anchor">Yazar Listesi</h5>
                        </div>
                        <div className="col-auto ms-auto">
                            <button className="btn btn-falcon-default btn-sm me-2" type="button" role="button" onClick={handleCreateModal}>
                                <span className="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
                                <span className="d-none d-sm-inline-block ms-1">Yeni</span>
                            </button>
                            <button className="btn btn-falcon-default btn-sm" type="button" role="button" onClick={handleRefresh}>
                                <IoMdRefresh size={20} color='blue' />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <div id="tableExample2">
                        <div className="table-responsive scrollbar">
                            <DataTable
                                columns={columns}
                                data={filteredData}
                                pagination
                                striped
                                subHeader
                                subHeaderComponent={subHeaderComponentMemo}
                                progressPending={loading}
                                paginationComponentOptions={paginationComponentOptions}
                                progressComponent={<Spinner animation="grow" variant="primary" />}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <CreateWriterModal show={showCreate} handleClose={handleCloseModal}/>
            {activeWriter ? <EditWriterModal show={showEdit} handleClose={handleCloseModal} activeWriter={activeWriter} /> : ''}
            <ConfirmationModal show={showConfirm} handleClose={handleCloseModal} handleConfirm={handleDelete} header='Yazar Sil' />
        </DashboardLayout>
    )
}

export default Writers