import React from 'react'
import AuthLayout from '../../components/layouts/AuthLayout'
import {Link} from 'react-router-dom'

const ForgotPassword = () => {
    return (
        <AuthLayout>
            <div className="row min-vh-100 flex-center g-0">
                <div className="col-lg-8 col-xxl-5 py-3 position-relative">
                    <img className="bg-auth-circle-shape" src="./img/icons/spot-illustrations/bg-shape.png" alt=""
                         width="250"/>
                    <img className="bg-auth-circle-shape-2" src="./img/icons/spot-illustrations/shape-1.png" alt=""
                         width="150"/>
                    <div className="card overflow-hidden z-index-1">
                        <div className="card-body p-0">
                            <div className="row g-0 h-100">
                                <div className="col-md-5 text-center bg-card-gradient">
                                    <div className="position-relative p-4 pt-md-5 pb-md-7 light">
                                        <div className="bg-holder bg-auth-card-shape"
                                             style={{backgroundImage: 'url(./img/icons/spot-illustrations/half-circle.png)'}}></div>
                                        <div className="z-index-1 position-relative">
                                            <a className="link-light mb-4 font-sans-serif fs-4 d-inline-block fw-bolder" href="#">
                                                <img src="./img/logos/logo_corporate-colored2x.png" alt="" width={'80%'}/>
                                            </a>
                                            <p className="opacity-75 text-white">
                                                Merhaba, VIP Kurum'a hoşgeldiniz.
                                                Yandaki alana e-posta adresinizi girerek şifre sıfırlama e-postasını
                                                alabilirsiniz.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-3 mb-4 mt-md-4 mb-md-5 light">
                                        <p className="pt-3 text-white">Kayıtlı mısınız?<br/>
                                            <Link to="/" className="btn btn-outline-light mt-2 px-4">
                                                Giriş Yap
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-7 d-flex flex-center">
                                    <div className="p-4 p-md-5 flex-grow-1">
                                        <div className="text-center text-md-start">
                                            <h4 className="mb-0"> Şifremi Unuttum</h4>
                                            <p className="mb-4">E-posta adresinizi girin, <br/> size şifre sıfırlama
                                                e-postası gönderelim.</p>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-sm-8 col-md">
                                                <form className="mb-3">
                                                    <input className="form-control" type="email"
                                                           placeholder="E-Posta Adresi"/>
                                                    <div className="mb-3"></div>
                                                    <button className="btn btn-primary d-block w-100 mt-3" type="submit"
                                                            name="submit">Sıfırlama E-Postası Gönder
                                                    </button>
                                                </form>
                                                <a className="fs--1 text-600" href="#!">
                                                    Daha fazla yardıma ihtiyacım var
                                                    <span className="d-inline-block ms-1">&rarr;</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AuthLayout>
    )
}

export default ForgotPassword
