import { createSlice } from '@reduxjs/toolkit'
import { getParentSections, getSections, saveSection, updateSection, deleteSection, getSubSections, getCategories, getLessons } from './sectionActions'
import { flattenArr } from '../../utils/misc'


const initialState = {
    loading: false,
    sections: [],
    subSections: [],
    categories: [],
    lessons: [],
    section: null,
    parents: [],
    error: null,
    success: false,
}

export const sectionSlice = createSlice({
    name: 'section',
    initialState,
    reducers: {
        resetState(state) {
            state.loading = false
            state.error = null
            state.errorCode = null
            state.success = false
        },
        resetSections(state) {
            state.subSections = []
        }
    },
    extraReducers: {
        [getParentSections.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getParentSections.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.parents = payload
        },
        [getParentSections.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [getSections.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getSections.fulfilled]: (state, { payload }) => {
            state.sections = payload
            state.loading = false
        },
        [getSections.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
            state.errorCode = payload.code
        },
        [getSubSections.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getSubSections.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.subSections = payload
        },
        [getSubSections.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [getCategories.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getCategories.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.categories = payload
        },
        [getCategories.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [getLessons.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getLessons.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.lessons = payload
        },
        [getLessons.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload
        },
        [saveSection.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [saveSection.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            state.sections.unshift(payload)
            state.section = payload
        },
        [saveSection.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload.error
            state.errorCode = payload.code
        },
        [updateSection.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [updateSection.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            const index = state.sections.findIndex(item => item.id === payload.id)
            state.sections[index] = payload
            state.section = payload
        },
        [updateSection.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload.error
            state.errorCode = payload.code
        },
        [deleteSection.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [deleteSection.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            state.sections = payload
            // const index = state.sections.findIndex(item => item.id === payload.id)
            // state.sections = state.sections.filter(section => section.id !== payload.id)
        },
        [deleteSection.rejected]: (state, { payload }) => {
            state.loading = false
            state.success = false
            state.error = payload.error
            state.errorCode = payload.code
        },
    }
})

export const { resetState, resetSections } = sectionSlice.actions

export default sectionSlice.reducer