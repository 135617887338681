import React, {useEffect} from 'react'
import DashboardLayout from '../../components/layouts/DashboardLayout'
import {questionCountByStatus, questionByLimit} from '../../slices/writer/writerActions'
import {useSelector, useDispatch} from 'react-redux'
import {
    activeWritersCount,
    allWritersCount,
    approvedQuestionsCount,
    pendingQuestionsCount
} from '../../slices/editor/editorActions'
import {getLessons} from '../../slices/lessons/lessonActions'
import {toast} from 'react-toastify'
import {logout} from '../../slices/auth/authActions'
import {IoMdRefresh} from 'react-icons/io'
import {OverlayTrigger, Spinner, Tooltip} from 'react-bootstrap'

const Dashboard = () => {
    const dispatch = useDispatch()
    const {loading, error, success, errorCode, active, all, pendings, approveds, rejecteds, lastApproveds, lastRejecteds} = useSelector((state) => state.writer)
    const {token, user} = useSelector((state) => state.auth)

    useEffect(() => {
        dispatch(questionCountByStatus({token, status: 'waiting_approval'}))
        dispatch(questionCountByStatus({token, status: 'approval'}))
        dispatch(questionCountByStatus({token, status: 'rejected'}))
        dispatch(questionByLimit({token, status: 'approval', limit: 5}))
        dispatch(questionByLimit({token, status: 'rejected', limit: 5}))
    }, [])

    useEffect(() => {
        if(error) {
            if(Array.isArray(error.error) || typeof error.error === 'object') {
                Object.keys(error.error).map((err, index) => (
                    console.log(error.error[err][0])
                    // toast(error.error[err][0], { type: 'error' })
                ))
            } else {
                console.log('Error: ', error.error)
                toast(error.error, { type: 'error' })
            }
        }
    }, [error])

    useEffect(() => {
        if(errorCode) {
            console.log('Code', errorCode)
            if(errorCode === 401) {
                dispatch(logout({token}))
            }
        }
    }, [errorCode])

    return (
        <DashboardLayout>
            <div className="row g-3 mb-3">
                <div className="col-md-6 col-xxl-3">
                    <div className="card h-md-100">
                        <div className="card-header pb-0">
                            <h6 className="mb-0 mt-2">Onaylanan Sorular</h6>
                        </div>
                        <div className="card-body d-flex flex-column justify-content-end">
                            <div className="row justify-content-between">
                                <div className="col-auto align-self-end">
                                    <div className="fs-4 fw-normal font-sans-serif text-700 lh-1 mb-1">{approveds}</div>
                                </div>
                                <div className="col-auto ps-0 mt-n4">
                                    <div className="echart-default-total-order"
                                         data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                                         data-echart-responsive="true"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xxl-3">
                    <div className="card h-md-100">
                        <div className="card-header pb-0">
                            <h6 className="mb-0 mt-2">Bekleyen Sorular</h6>
                        </div>
                        <div className="card-body d-flex flex-column justify-content-end">
                            <div className="row justify-content-between">
                                <div className="col-auto align-self-end">
                                    <div className="fs-4 fw-normal font-sans-serif text-700 lh-1 mb-1">{pendings}</div>
                                </div>
                                <div className="col-auto ps-0 mt-n4">
                                    <div className="echart-default-total-order"
                                         data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                                         data-echart-responsive="true"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xxl-3">
                    <div className="card h-md-100">
                        <div className="card-header pb-0">
                            <h6 className="mb-0 mt-2">Reddedilen Sorular</h6>
                        </div>
                        <div className="card-body d-flex flex-column justify-content-end">
                            <div className="row justify-content-between">
                                <div className="col-auto align-self-end">
                                    <div className="fs-4 fw-normal font-sans-serif text-700 lh-1 mb-1">{rejecteds}</div>
                                </div>
                                <div className="col-auto ps-0 mt-n4">
                                    <div className="echart-default-total-order"
                                         data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                                         data-echart-responsive="true"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xxl-3">
                    <div className="card h-md-100">
                        <div className="card-header pb-0">
                            <h6 className="mb-0 mt-2">Toplam</h6>
                        </div>
                        <div className="card-body d-flex flex-column justify-content-end">
                            <div className="row justify-content-between">
                                <div className="col-auto align-self-end">
                                    <div className="fs-4 fw-normal font-sans-serif text-700 lh-1 mb-1">{+pendings + +approveds + +rejecteds}</div>
                                </div>
                                <div className="col-auto ps-0 mt-n4">
                                    <div className="echart-default-total-order"
                                         data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                                         data-echart-responsive="true"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row g-3 mb-3">
                <div className="card mb-3">
                    <div className="card-header">
                        <div className="row flex-between-end">
                            <div className="col-auto align-self-center">
                                <h5 className="mb-0" data-anchor="data-anchor">Son Onaylananlar</h5>
                            </div>
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        {
                            loading ?
                                <div className="d-flex flex-center">
                                    <Spinner animation="grow" variant="primary" />
                                </div>
                                :
                                <div className="kanban-items-container border bg-white dark__bg-1000 rounded-2 py-3 mb-3 d-flex flex-start flex-wrap" style={{ maxHeight: 'none', height: 'auto' }}>
                                    {
                                        lastApproveds.length > 0
                                            ? lastApproveds.map(approved => (
                                                <div key={approved?.id} className="card mb-3 kanban-item shadow-sm dark__bg-1100 me-2" style={{ width: '300px', height: '235px' }}>
                                                    <div className="card-body">
                                                        <div className="hoverbox rounded-3 text-center">
                                                            <img className="img-fluid" style={{ objectFit: 'contain' }} src={ approved?.question ? (process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PRODUCTION_STORAGE_URL + approved?.question : process.env.REACT_APP_API_DEVELOPMENT_URL + '/' + approved?.question) : '.img/placeholders/question-placeholder.jpeg' } alt=""/>
                                                        </div>
                                                    </div>
                                                    <div className="container d-flex justify-content-center align-content-center align-items-center flex-column">
                                                        <div className="btn-toolbar">
                                                            <button className={'btn me-1 ' + (approved.answer === 'A' ? 'btn-success' : 'btn-danger')}>A</button>
                                                            <button className={'btn me-1 ' + (approved.answer === 'B' ? 'btn-success' : 'btn-danger')}>B</button>
                                                            <button className={'btn me-1 ' + (approved.answer === 'C' ? 'btn-success' : 'btn-danger')}>C</button>
                                                            <button className={'btn me-1 ' + (approved.answer === 'D' ? 'btn-success' : 'btn-danger')}>D</button>
                                                            <button className={'btn me-1 ' + (approved.answer === 'E' ? 'btn-success' : 'btn-danger')}>E</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <div className='d-flex justify-content-center align-items-center w-100'><h4>Onaylanan Soru Bulunamadı!</h4></div>
                                    }
                                </div>
                        }
                    </div>
                </div>
            </div>
            <div className="row g-3 mb-3">
                <div className="card mb-3">
                    <div className="card-header">
                        <div className="row flex-between-end">
                            <div className="col-auto align-self-center">
                                <h5 className="mb-0" data-anchor="data-anchor">Son Reddedilenler</h5>
                            </div>
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        {
                            loading ?
                                <div className="d-flex flex-center">
                                    <Spinner animation="grow" variant="primary" />
                                </div>
                                :
                                <div className="kanban-items-container border bg-white dark__bg-1000 rounded-2 py-3 mb-3 d-flex flex-start flex-wrap" style={{ maxHeight: 'none', height: 'auto' }}>
                                    {
                                        lastRejecteds.length > 0
                                            ? lastRejecteds.map(approved => (
                                                <div key={approved?.id} className="card mb-3 kanban-item shadow-sm dark__bg-1100 me-2" style={{ width: '300px', height: '235px' }}>
                                                    <div className="card-body">
                                                        <div className="hoverbox rounded-3 text-center">
                                                            <img className="img-fluid" style={{ objectFit: 'contain' }} src={ approved?.question ? (process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PRODUCTION_STORAGE_URL + approved?.question : process.env.REACT_APP_API_DEVELOPMENT_URL + '/' + approved?.question) : '.img/placeholders/question-placeholder.jpeg' } alt=""/>
                                                        </div>
                                                    </div>
                                                    <div className="container d-flex justify-content-center align-content-center align-items-center flex-column">
                                                        <div className="btn-toolbar">
                                                            <button className={'btn me-1 ' + (approved.answer === 'A' ? 'btn-success' : 'btn-danger')}>A</button>
                                                            <button className={'btn me-1 ' + (approved.answer === 'B' ? 'btn-success' : 'btn-danger')}>B</button>
                                                            <button className={'btn me-1 ' + (approved.answer === 'C' ? 'btn-success' : 'btn-danger')}>C</button>
                                                            <button className={'btn me-1 ' + (approved.answer === 'D' ? 'btn-success' : 'btn-danger')}>D</button>
                                                            <button className={'btn me-1 ' + (approved.answer === 'E' ? 'btn-success' : 'btn-danger')}>E</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <div className='d-flex justify-content-center align-items-center w-100'><h4>Reddedilen Soru Bulunamadı!</h4></div>
                                    }
                                </div>
                        }
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default Dashboard