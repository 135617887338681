import {configureStore} from "@reduxjs/toolkit"
import authReducer from '../slices/auth/authSlice'
import writerReducer from '../slices/writer/writerSlice'
import sectionReducer from '../slices/sections/sectionSlice'
import lessonReducer from '../slices/lessons/lessonSlice'
import unitReducer from '../slices/units/unitSlice'
import subjectReducer from '../slices/subjects/subjectSlice'
import questionReducer from '../slices/questions/questionSlice'
import sourceReducer from '../slices/sources/sourceSlice'
import fieldReducer from '../slices/fields/fieldSlice'
import poolReducer from '../slices/pools/poolSlice'
import difficultyLevelReducer from '../slices/difficultyLevels/difficultyLevelSlice'
import typeReducer from '../slices/types/typeSlice'
import levelReducer from '../slices/levels/levelSlice'
import uploaderReducer from '../slices/uploader/uploaderSlice'
import editorReducer from '../slices/editor/editorSlice'

export const store = configureStore({
    reducer: {
        auth: authReducer,
        writer: writerReducer,
        section: sectionReducer,
        lesson: lessonReducer,
        unit: unitReducer,
        subject: subjectReducer,
        question: questionReducer,
        source: sourceReducer,
        field: fieldReducer,
        pool: poolReducer,
        difficultyLevel: difficultyLevelReducer,
        type: typeReducer,
        level: levelReducer,
        uploader: uploaderReducer,
        editor: editorReducer,
    }
})